import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

export default function Loader() {
	const classes = useStyles();
	return (
		<div className={classes.loader_root}>
			<CircularProgress style={{ color: '#dcdcdc' }} size={40} />
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	loader_root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
}));
