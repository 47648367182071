import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CallEndIcon from '@material-ui/icons/CallEnd';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import { IconButton } from '@material-ui/core';
import { Height } from '@material-ui/icons';

SBButton.propTypes = {
	type: PropTypes.oneOf('message', 'voice', 'video', 'endCall'),
	onPress: PropTypes.func,
	disabled: PropTypes.bool,
};

export default function SBButton(props) {
	const classes = useStyles();

	const renderIcon = () => {
		switch (props.type) {
			case 'message':
				return <ChatBubbleOutlineOutlinedIcon style={{ fill: 'gray' }} />;
			case 'voice':
				return props.disabled ? <MicOffIcon style={{ fill: 'white' }} /> : <MicIcon style={{ fill: 'gray' }} />;
			case 'video':
				return props.disabled ? (
					<VideocamOffIcon style={{ fill: 'white' }} />
				) : (
					<VideocamIcon style={{ fill: 'gray' }} />
				);
			case 'endCall':
				return <CallEndIcon style={{ fill: 'white' }} />;
		}
	};

	const buttonStyle = () => {
		switch (props.type) {
			case 'message':
				return { backgroundColor: 'white' };
			case 'voice':
				return { backgroundColor: props.disabled ? '#ffffff30' : 'white' };
			case 'video':
				return { backgroundColor: props.disabled ? '#ffffff30' : 'white' };
			case 'endCall':
				return { backgroundColor: 'red' };
		}
	};

	return (
		<IconButton className={classes.rootIcon} style={buttonStyle()} onClick={() => props.onPress()}>
			{renderIcon()}
		</IconButton>
	);
}

const useStyles = makeStyles((theme) => ({
	rootIcon: {
		width: 54,
		height: 54,
	},
}));
