import ApiManager from './ApiManager';
import Endpoints from './Endpoints';

let api = new ApiManager();

const getAppointment = (id) => {
	return api.getBpAxios().get(Endpoints.appointmentById(id));
};

const getReservation = (id) => {
	return api.getAxios().get(Endpoints.reservationById(id));
};

export default { getAppointment, getReservation };
