import { useEffect, Fragment, useState } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router';
import ReactGA from 'react-ga';

export const GAListener = (props) => {
	const { children, trackingId } = props;
	const location = useLocation();
	const [initialized, setInitialized] = useState(false);
	useEffect(() => {
		if (!window.location.href.includes('localhost')) {
			ReactGA.initialize(trackingId, { debug: true });
			setInitialized(true);
		}
	}, []);

	useEffect(() => {
		if (initialized) {
			ReactGA.pageview(location.pathname + location.search);
		}
	}, [initialized, location]);

	return <Fragment>{children}</Fragment>;
};

export default GAListener;
