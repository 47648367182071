import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useUiModalsContext, useAppContext } from '../../redux/context/ContextFactory';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VideocamIcon from '@material-ui/icons/Videocam';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { makeStyles } from '@material-ui/core/styles';
import { bpAcquistoLink, bpVideoLink } from '../../utils/helpers';

export default function BookingDialog(props) {
	const { 
		agentStore,
		referralQueryParam,
		nameQueryParam,
		surnameQueryParam,
		emailQueryParam,
		phoneQueryParam,
		crmIdQueryParam,
		clientTypeQueryParam,
	} = useAppContext();

	const classes = useStyles();
	const handleClose = () => {
		props.onClose(props.type);
	};

	const onPressStore = () => {
		props.onOpenBP(
			bpAcquistoLink(
				agentStore.slug, 
				referralQueryParam,
				nameQueryParam,
				surnameQueryParam,
				phoneQueryParam,
				emailQueryParam,
				crmIdQueryParam,
				clientTypeQueryParam
			)
		);
	};

	const onPressVideoCall = () => {
		props.onOpenBP(
			bpVideoLink(
				agentStore.slug,
				referralQueryParam,
				nameQueryParam,
				surnameQueryParam,
				phoneQueryParam,
				emailQueryParam,
				crmIdQueryParam,
				clientTypeQueryParam
			)
		);
	};

	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{/* <DialogTitle id="alert-dialog-title">{'IQOS'}</DialogTitle> */}
			<DialogContent>
				<IconButton style={{ position: 'absolute', right: 2, top: 0 }} onClick={() => props.onClose()}>
					<CloseIcon />
				</IconButton>
				<DialogContentText style={{ marginTop: 20 }} id="alert-dialog-description">
					Che tipo di appuntamento desideri prenotare?
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.buttonsContainer}>
				<Button className={classes.button} onClick={() => onPressStore()}>
					Negozio
				</Button>
				<Button className={classes.button} onClick={() => onPressVideoCall()}>
					Videochiamata
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		backgroundColor: '#34303D',
		color: '#ffffff',
		fontFamily: 'Iqos',
		borderRadius: 24,
		width: '46%',
		height: 42,
		fontSize: 12,
		margin: 10,
		padding: 10,
		'&:hover': {
			backgroundColor: '#34303D',
		},
	},
}));
