import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Label, LabelHeader } from "../components";
import Slide from "@material-ui/core/Slide";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Box, Button, Fade, TextField, IconButton } from "@material-ui/core";
import {
  useUiModalsContext,
  useAppContext,
} from "../redux/context/ContextFactory";
import CalendarIcon from "../assets/ic_calendar_ss.svg";
import CloseIcon from "@material-ui/icons/Close";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import PeopleIcon from "@material-ui/icons/People";
import Countdown from "react-countdown";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import BookingDialog from "../components/dialogs/BookingDialog";
import FeedbackAnimation from "../components/lottie/FeedbackAnimation";
import tnxIcon from "../assets/tnx_icon.svg";
import GAManager from "../analytics/GAManager";
let ga = new GAManager();

RecallClientModal.propTypes = {
  onClose: PropTypes.func,
  time: PropTypes.number,
  persons: PropTypes.number,
  withTimer: PropTypes.bool,
  noHeader: PropTypes.bool,
};
function NumberFormatPhone(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="#####################"
      // mask="_"
    />
  );
}

export default function RecallClientModal(props) {
  const classes = useStyles();
  const {
    agentStore,
    postRecall,
    recallSuccess,
    nameQueryParam,
    phoneQueryParam,
    crmIdQueryParam,
  } = useAppContext();
  const { setOpenBPView } = useUiModalsContext();
  const [name, setName] = useState(nameQueryParam ? nameQueryParam : "");
  const [phone, setPhone] = useState(phoneQueryParam ? phoneQueryParam : "");
  const [timerIsReachedZero, setTimerIsReachedZero] = useState(false);
  const [nameIsValid, setNameIsValid] = useState(null);
  const [phoneIsValid, setPhoneIsValid] = useState(null);
  const timerRef = useRef(null);
  const [openBookingDialog, setOpenBookingDialog] = useState(false);

  useEffect(() => {
    if (props.open) {
      ga.trackEvent(
        GAManager.CATEGORY.shop_streaming,
        GAManager.ACTION.impressionCTA,
        GAManager.IDs.bookRecall,
        true
      );
    }
  }, [props.open]);

  useEffect(() => {}, [recallSuccess]);

  const handleClose = () => {
    if (!timerIsReachedZero) {
      if (props.withTimer) {
        timerRef.current.pause();
      } else {
        props.onClose();
      }
    } else {
      props.onClose(1);
    }
  };

  const handleChangeName = (value) => {
    setNameIsValid(true);
    setName(value);
  };

  const handleChangePhone = (value) => {
    setPhoneIsValid(true);
    setPhone(value);
  };

  const sendRecallData = () => {
    if (phone === "") {
      setPhoneIsValid(false);
    }
    if (name === "") {
      setNameIsValid(false);
    }
    if (name != "" && phone != "") {
      console.log(
        "name => ",
        name,
        "phone => ",
        phone,
        "store_uuid => ",
        agentStore.uuid,
        "crm id => ",
        crmIdQueryParam
      );
      postRecall(phone, name, agentStore.uuid, crmIdQueryParam);
    }
  };

  const renderTimer = () => {
    if (props.withTimer) {
      return timerIsReachedZero ? (
        <div className={classes.waitingLabel}>
          <Label
            fontSize={14}
            text={"Ancora qualche momento di attesa…"}
            color="black"
          />
        </div>
      ) : (
        <Slide in={true} timeout={1400} mountOnEnter unmountOnExit>
          <Box
            boxShadow={2}
            className={classes.timerWrapper}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <PeopleIcon fontSize="medium" />
            <Label fontSize={20} text={props.persons} color="#000" isBold />
            <Label fontSize={20} text="|" color="#000" />
            {props.time && (
              <Countdown
                ref={timerRef}
                daysInHours={true}
                autoStart
                zeroPadTime={2}
                className={classes.timer}
                date={Date.now() + props.time * 1000}
                onComplete={() => setTimerIsReachedZero(true)}
                onPause={(time) => {
                  props.onClose(time.total / 1000);
                }}
              />
            )}
          </Box>
        </Slide>
      );
    } else {
      return <div style={{ width: "" }} />;
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Slide
        in={props.open}
        direction="up"
        timeout={500}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.wrapper}>
          {!props.noHeader && (
            <div className={classes.header}>
              <IconButton
                className={classes.backButton}
                onClick={() => handleClose()}
              >
                <KeyboardBackspaceIcon
                  style={{ fill: "#34303D", marginRight: 4 }}
                />
                <Label fontSize={18} text="Torna indietro" color="#34303D" />
              </IconButton>
              {renderTimer()}
            </div>
          )}

          <Box className={classes.formBox}>
            <div className={classes.titleWrapper}>
              <Label
                fontSize={18}
                color={"#34303D"}
                isBold
                text={"Stai chiedendo di essere richiamato appena possibile."}
              />
            </div>

            {!recallSuccess && (
              <TextField
                className={classes.field}
                fullWidth
                value={name}
                error={nameIsValid === false}
                // helperText={surname === '' ? 'Il cognome è obbligatorio' : ''}
                label="Nome"
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutlineForm,
                    input: classes.notchedOutlineForm,
                  },
                }}
                InputLabelProps={{
                  style: { color: "#34303D", fontFamily: "Iqos", fontSize: 14 },
                }}
                onChange={(e) => handleChangeName(e.target.value)}
              />
            )}

            {!recallSuccess && (
              <TextField
                className={classes.field}
                style={{ display: recallSuccess ? "none" : "" }}
                fullWidth
                value={phone}
                error={phoneIsValid === false}
                label="Telefono"
                variant="outlined"
                // margin="dense"
                InputProps={{
                  inputComponent: NumberFormatPhone,
                  classes: {
                    notchedOutline: classes.notchedOutlineForm,
                    input: classes.notchedOutlineForm,
                  },
                }}
                InputLabelProps={{
                  style: { color: "#34303D", fontFamily: "Iqos", fontSize: 14 },
                }}
                onChange={(e) => handleChangePhone(e.target.value)}
              />
            )}
            {!recallSuccess && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="80%"
              >
                <Button
                  disabled={props.disabled}
                  className={classes.sendButton}
                  variant="contained"
                  style={{ marginTop: 16 }}
                  onClick={() => sendRecallData()}
                >
                  Invia
                </Button>
                <div style={{ marginTop: 8 }}>
                  <Label
                    text={
                      "Procedendo con la videochiamata dichiari di essere un fumatore adulto"
                    }
                    fontSize={12}
                    color="#34303D"
                    isItalic
                  />
                </div>
              </Box>
            )}
            {recallSuccess === true && (
              <Fade in={recallSuccess} timeout={1200}>
                <div
                  style={{
                    width: "80%",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {/* <FeedbackAnimation /> */}
                    <img src={tnxIcon} />
                  </div>

                  <div style={{ marginBottom: 20, marginTop: 10 }}>
                    <Label
                      text={`Grazie, ${name}! Abbiamo ricevuto la tua richiesta. Sarà nostra cura richiamarti entro un'ora.`}
                      color="#34303D"
                      isBold
                      fontSize={18}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </Box>

          <BookingDialog
            open={openBookingDialog}
            onClose={() => setOpenBookingDialog(false)}
            onOpenBP={(link) => {
              //close dialog and open iframe
              setOpenBookingDialog(false);
              setOpenBPView(true, link);
            }}
          />
        </div>
      </Slide>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    // justifyContent: 'center',
    // overflow: 'scroll',
    display: "flex",
    // backgroundColor: '#202020',
    // overflowX: 'hidden',
  },
  header: {
    backgroundColor: "#FFFDFB",
    height: 60,
    flexDirection: "row",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    left: 0,
    top: 0,
  },
  headerView: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: 400,
    position: "relative",
  },
  closeIcon: {
    left: 0,
    top: 22,
    transform: "rotate(180deg)",
    position: "absolute",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    outline: "none",
    width: "100%",
    backgroundColor: "#FFFDFB",
    paddingBottom: 16,
    overflow: "auto",
    overflowX: "hidden",

    // paddingLeft: 12,
    // paddingRight: 12,
  },
  titleWrapper: {
    maxWidth: 400,
    padding: 20,
    marginTop: 40,
  },
  bookingBox: {
    backgroundColor: "rgb(0,0,0, 0.5)",
    // height: 160,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 12,
    paddingTop: 27,
    paddingBottom: 27,
    maxWidth: 400,
  },
  notchedOutlineForm: {
    borderWidth: "1px",
    fontFamily: "Iqos",
    borderColor: "#34303D",
    color: "#34303D",
    "&::placeholder": {
      color: "#34303D",
    },
  },
  field: {
    // color: 'white',
    // // marginTop: 10,
    // height: 52,
    // // paddingTop: 6,
    // backgroundColor: 'red',
    // alignSelf: 'center',
    marginTop: 16,
    width: 290,
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#34303D",
      borderWidth: 1,
    },
  },
  // iframeView: {
  // 	height: 'calc(100% - 60px)',
  // 	width: '100%',
  // 	display: 'flex',
  // 	alignItems: 'center',
  // 	justifyContent: 'center',
  // 	outline: 'none',
  // },
  button: {
    backgroundColor: "white",
    height: 48,
    marginLeft: 16,
  },
  formBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F6F4F0",
    width: "100%",
    paddingTop: 16,
    paddingBottom: 24,
    borderRadius: 4,
    maxWidth: 400,
    borderRadius: 4,
    // height: 220,
  },
  sendButton: {
    marginTop: 2,
    width: 160,
    fontFamily: "Iqos",
    height: 48,
    letterSpacing: 0.5,
    borderRadius: 24,
    backgroundColor: "#34303D",
    color: "#FFFDFB",
    "&:hover": {
      backgroundColor: "#34303D",
    },
  },
  timer: {
    color: "#00000",
    fontFamily: "Iqos",
    fontSize: 20,
  },
  waitingLabel: {
    marginRight: 6,
  },
  timerWrapper: {
    width: "40%",
    height: 48,
    backgroundColor: "#ffffff",
    borderRadius: 25,
    paddingLeft: 28,
    paddingRight: 28,
    marginRight: 16,
    maxWidth: 200,
  },
}));
