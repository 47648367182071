import { createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const black = grey['900'];
const font = "'Iqos'";
const white = '#ffffff';

function pickerWidth() {
	if (window.innerWidth > 600) {
		return (400 * 0.9) / 2 - 8;
	}
	return (window.innerWidth * 0.9) / 2 - 8;
}

export default {
	ocker: '#D6A76F',
	yellow: '#F8F4BC',
	lightBlue: '#BCE9F8',
	white: '#ffffff',
};

export const theme = createMuiTheme({
	palette: {
		primary: { main: white }, //iqos black
		secondary: { main: '#DCDCDC' },
		action: {
			disabled: '#ffffff',
		},
	},
	typography: {
		fontFamily: font,
		button: { textTransform: 'none' },
	},
	overrides: {
		MuiPickersYear: {
			root: {
				// '&:focus': {
				// 	color: '#c5c5c5',
				// },
			},
			yearSelected: {
				backgroundColor: '#00000011',
				color: '#404040',
				fontFamily: 'Iqos',
			},
		},
		MuiPickersBasePicker: {
			pickerView: {
				minWidth: pickerWidth(),
				maxWidth: pickerWidth(),
				width: pickerWidth(),
				border: 'solid 1px black',
				// backgroundColor: '#404040',
			},
		},
		MuiPickersMonthSelection: {
			container: {
				height: 300,
				width: null,
				display: 'flex',
				flexWrap: null,
				flexDirection: 'column',
				justifyContent: 'space-between',
				color: '#404040',
			},
		},
		MuiTypography: {
			subtitle1: {
				'text-transform': 'capitalize',
			},
		},
		MuiPickersMonth: {
			root: {
				// '&:focus': {
				// 	color: '#c5c5c5',
				// },
				justifyContent: 'flex-start',
				alignItems: 'center',
				width: '100%',
				paddingLeft: 10,
				paddingTop: 12,
				paddingBottom: 12,
				height: null,
				flex: null,
			},
			monthSelected: {
				backgroundColor: '#00000011',
				color: '#404040',
				'text-transform': 'capitalize',
				fontFamily: 'Iqos',
				'font-size': '1rem',
			},
		},

		MuiInput: {
			// input: {
			// 	'&::placeholder': {
			// 		color: 'white',
			// 	},
			// 	color: 'white', // if you also want to change the color of the input, this is the prop you'd use
			// },
		},
	},
	// overrides: {
	// 	MuiStepIcon: {
	// 		root: {
	// 			'&$completed': {
	// 				color: '#D6A76F',
	// 			},
	// 		},
	// 	},
	// 	MuiInput: {
	// 		underline: {
	// 			// '&:hover:not($disabled):before': {
	// 			// 	backgroundColor: '1px  #d6a76f',
	// 			// },
	// 			// '&:hover:not($disabled):after': {
	// 			// 	backgroundColor: '1px  #d6a76f',
	// 			// },
	// 			// '&:hover:not($enabled):before': {
	// 			// 	backgroundColor: '1px #000',
	// 			// },
	// 			// '&:hover:not($enabled):after': {
	// 			// 	backgroundColor: '1px  #d6a76f',
	// 			//},
	// 			// '&&&&:hover': {
	// 			// 	backgroundColor: '1px  #d6a76f',
	// 			// },
	// 			// '&&&&:hover:after': {
	// 			// 	backgroundColor: '1px  #d6a76f',
	// 			// },
	// 			'&:before': {
	// 				borderBottom: `1px solid  rgba(166,166,166,0.5)`,
	// 			},
	// 			'&:after': {
	// 				borderBottom: `2px solid  #d6a76f`,
	// 			},
	// 			'&&&&:hover:before': {
	// 				borderBottom: `1px solid  #d6a76f`,
	// 			},
	// 		},
	// 	},
	// },
});
