import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { Box } from '@material-ui/core';
import ReactPWAInstallProvider, { useReactPWAInstall } from 'react-pwa-install';
import logo from '../../assets/logo.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isMobile, isSafari, isDesktop } from 'react-device-detect';
import shareIcon from '../../assets/ios_share.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default function AddToHomeSnack() {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
	const [promptInstall, setPromptInstall] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);

	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			console.log('install triggered');
			setPromptInstall(e);
		};
		window.addEventListener('beforeinstallprompt', handler);

		return () => window.removeEventListener('transitionend', handler);
	}, []);

	useEffect(() => {
		console.log('navigator platform', navigator.userAgent);
		console.log('pwa is supported', supported());
		console.log('pwa is installed', isInstalled());
		if (isInstalled()) {
			setOpen(false);
		}
	}, [isInstalled]);

	const handlePWA = (evt) => {
		console.log(navigator.userAgent);
		if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
			try {
				navigator.share();
				console.log('closed shared');
				setOpenDialog(false);
			} catch {
				setOpenDialog(false);
				console.log('error try to share');
			}
			return;
		}
		evt.preventDefault();
		if (!promptInstall) {
			return;
		}
		setOpenDialog(false);
		promptInstall.prompt();
	};

	const handleClose = (e) => {
		e.stopPropagation();
		setOpen(false);
	};

	const dialogTitle = () => {
		return isSafari ? `Aggiungi l'App alla schermata Home` : `Aggiungi L’App alla homescreen del tuo smartphone`;
	};

	return (
		<Collapse in={open && isMobile && !navigator.standalone}>
			<Box className={classes.root} onClick={() => setOpenDialog(true)}>
				<AddToHomeScreenIcon style={{ fill: 'white', marginLeft: 20 }} />

				<Typography className={classes.label}>Aggiungi Shop Streaming in home</Typography>

				<IconButton className={classes.button} onClick={(e) => handleClose(e)}>
					<CloseIcon style={{ fill: 'white' }} fontSize="small" />
				</IconButton>
			</Box>
			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{dialogTitle()}</DialogTitle>
				<DialogContent dividers>
					{isSafari ? (
						<DialogContentText id="alert-dialog-description">
							Il servizio di videochiamata dello Shop Streaming di IQOS a portata di mano: premi
							sull’icona in basso <img src={shareIcon} style={{ height: 20, width: 20, marginTop: 4 }} />{' '}
							e seleziona la voce “Aggiungi alla schermata Home”.
						</DialogContentText>
					) : (
						<DialogContentText id="alert-dialog-description">
							Il servizio di videochiamata dello Shop Streaming di IQOS a portata di mano: premi
							sull’icona <MoreVertIcon fontSize="medium" /> e seleziona la voce “Aggiungi alla schermata
							Home”.
						</DialogContentText>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={(e) => setOpenDialog(false)}>OK</Button>
				</DialogActions>
			</Dialog>
		</Collapse>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 400,
		display: 'flex',
		//flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		backgroundColor: 'white',
		borderRadius: 4,
		position: 'relative',
		backgroundColor: '#34303D',
		height: 70,
		width: '94vw',
	},
	label: {
		color: 'white',
		fontSize: 14,
		marginLeft: 8,
		width: '70%',
		fontFamily: 'Iqos',
	},
	button: {
		position: 'absolute',
		top: 2,
		right: 2,
	},
}));
