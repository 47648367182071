import React, { useState, useMemo } from 'react';

import {
	Card,
	CardHeader,
	Avatar,
	CardContent,
	CardActions,
	Typography,
	Button,
	TextField,
	Box,
	IconButton,
	Popover,
	Fade,
	Paper,
	ClickAwayListener,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import 'moment/locale/it'; // without this line it didn't work
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Linkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';
import GAManager from '../../analytics/GAManager';

let ga = new GAManager();

export default function UserMessage(props) {
	// props
	const { message, userId, onDeleteMessage, onUpdateMessage, channel, sdk, sendUserMessage } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	// useState
	const [pressedUpdate, setPressedUpdate] = useState(false);
	const [messageText, changeMessageText] = useState(message.message);
	const [isRead, setIsRead] = useState(false);

	const sendMessage = (origMessage, destChannel) => {
		console.log('Sending message to channel', destChannel);
		const params = new sdk.UserMessageParams();
		params.message = origMessage.message;
		sendUserMessage(destChannel.url, params)
			.then((message) => {
				console.log('Message sent', message);

				// IF SENT
				// For a text message
				const params = new sdk.UserMessageParams();
				params.data = JSON.stringify({ status: 'forwarded' });

				// The MESSAGE_ID below indicates the unique message ID of a UserMessage object to update.
				console.log('Updating message status', origMessage.messageId);
				// destChannel.updateUserMessage(origMessage.messageId, params, function(message, error) {
				//     if (error) {
				//       console.error("Error", error);
				//       return;
				//     } else {
				//       console.log('Message updated', message);
				//     }
				// });
				const emojiKey = 'smile';
				destChannel.addReaction(origMessage, emojiKey, function (reactionEvent, error) {
					if (!error) {
						origMessage.applyReactionEvent(reactionEvent);
					}
				});
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	//actions popover
	const handleClickMore = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const pressEditMessage = () => {
		setAnchorEl(null);
		setPressedUpdate(true);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const renderDeleteDialog = () => {
		return (
			<Dialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{''}</DialogTitle>
				<DialogContent style={{ width: 300 }}>
					<DialogContentText id="alert-dialog-description" style={{ fontFamily: 'IqosBold' }}>
						Sei sicuro di voler eliminare questo messaggio?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDeleteDialog(false)}>
						<Typography style={{ fontFamily: 'Iqos' }}>No</Typography>
					</Button>
					<Button onClick={() => onDeleteMessage(message)}>
						<Typography style={{ fontFamily: 'Iqos' }}>SI</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const getMessageText = (message) => {
		if (message.message === 'direct_call:dial') {
			return 'Chiamata iniziata';
		}
		if (message.message === 'direct_call:end') {
			return 'Chiamata terminata';
		}
		return message.message;
	};

	const componentDecorator = (href, text, key) => (
		<SecureLink style={{ color: 'black' }} href={href} key={key}>
			{text}
		</SecureLink>
	);

	return (
		<div className={`user-message ${message.sender && message.sender.userId === userId ? 'mine' : ''}`}>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<div className={classes.actionsPopover}>
						{!pressedUpdate && (
							<Button
								size="small"
								variant="outlined"
								elevation={0}
								onClick={() => setOpenDeleteDialog(true)}
							>
								Elimina
							</Button>
						)}
						{!pressedUpdate && (
							<Button
								size="small"
								variant="outlined"
								elevation={0}
								onClick={() => {
									pressEditMessage();
								}}
							>
								Modifica
							</Button>
						)}
					</div>
				</ClickAwayListener>
			</Popover>
			{message.sender && (
				<Card className={classes.card} elevation={0}>
					<div
						className={
							message.sender && message.sender.userId === userId
								? classes.myMessageWrapper
								: classes.messageWrapper
						}
					>
						{/* <Typography
						className={message.sender && message.sender.userId === userId ? null : classes.senderText}
					>
						{message.sender && message.sender.userId === userId ? '' : message.sender.nickname}
					</Typography> */}
						{message.sender && message.sender.userId === userId && !pressedUpdate && (
							<MoreVertIcon
								style={{ fill: '#34303D', right: -4, top: 0, position: 'absolute' }}
								onClick={(e) => handleClickMore(e)}
							/>
						)}
						{!pressedUpdate && (
							<Typography
								elevation={1}
								variant="body2"
								component="div"
								display="inline"
								className={
									message.sender && message.sender.userId === userId
										? classes.messageMyText
										: classes.messageText
								}
							>
								<Linkify componentDecorator={componentDecorator}>{getMessageText(message)}</Linkify>
							</Typography>
						)}
						{pressedUpdate && (
							<div className={classes.editMessageWrapper}>
								<Button
									className={classes.button}
									style={{ marginRight: 10 }}
									size="small"
									variant="outlined"
									elevation={0}
									onClick={() => setPressedUpdate(false)}
								>
									Annulla
								</Button>

								<Button
									className={classes.button}
									style={{ marginRight: 10 }}
									size="small"
									variant="outlined"
									elevation={0}
									onClick={() => {
										setPressedUpdate(false);
										onUpdateMessage(message.messageId, messageText);
									}}
								>
									Salva
								</Button>
								<div className={classes.editMessageField}>
									<TextField
										style={{ maxWidth: '100%', backgroundColor: '#acacac' }}
										label="Modifica"
										multiline
										errorStyle={{ color: 'green' }}
										fullWidth
										variant="filled"
										rowsMax={4}
										value={messageText}
										InputProps={{ className: classes.input }}
										InputLabelProps={{ className: classes.inputLabel }}
										onChange={(event) => {
											changeMessageText(event.target.value);
										}}
									/>
								</div>
							</div>
						)}
						<div className={classes.timeWrapper}>
							{message.sender && message.sender.userId === userId && (
								<CardActions>
									{isRead && (
										<span>
											<Typography variant="caption">
												<i style={{ color: 'green' }}>✓</i> letto{' '}
												{/* {channel.memberCount - numberUnread}/{channel.memberCount} */}
											</Typography>
										</span>
									)}
								</CardActions>
							)}
							<Moment className={classes.timeLabel} fromNow>
								{message.createdAt}
							</Moment>
						</div>
					</div>
				</Card>
			)}

			{renderDeleteDialog()}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: 'transparent',
		width: '100%',
		marginBottom: 10,
	},
	timeLabel: {
		fontSize: 10,
		color: 'white',
	},
	mine: {},
	outbound: {
		// backgroundColor: '#acacac',
		textAlign: 'right',
		color: 'white',
		float: 'right',
		borderRadius: 20,
		elevation: 1,
		maxWdith: '60%',
	},
	senderText: {
		fontSize: 12,
		color: 'black',
	},
	messageText: {
		fontSize: 12,
		color: 'black',
		backgroundColor: '#ecedf1',
		borderRadius: 8,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 10,
		paddingBottom: 10,
		maxWidth: '60%',
		textAlign: 'left',
		wordWrap: 'break-word',
	},
	messageMyText: {
		fontSize: 12,
		color: 'white',
		backgroundColor: '#414042',
		borderRadius: 8,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 10,
		paddingBottom: 10,
		maxWidth: '60%',
		textAlign: 'right',
		wordWrap: 'break-word',
		marginRight: 20,
	},
	inbound: {
		backgroundColor: 'white',
		textAlign: 'left',
		float: 'left',
		maxWdith: '60%',
		borderRadius: 16,
	},
	myMessageWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		alignItems: 'flex-end',
		marginTop: 10,
		position: 'relative',
	},
	messageWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		marginTop: 10,
	},
	messageActionsBtn: {
		right: 2,
		top: 0,
		position: 'absolute',
	},
	actionsPopover: {
		padding: 10,
		height: 80,
		width: 100,
		justifyContent: 'space-between',
		backgroundColor: 'white',
		borderRadius: 4,
		display: 'flex',
		flexDirection: 'column',
	},
	button: {
		color: '#414042',
		borderColor: '#414042',
		fontFamily: 'Iqos',
	},
	timeWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginRight: 20,
	},
	editMessageWrapper: {
		display: 'flex',
		alignItems: 'flex-end',
	},
	editMessageField: {
		marginRight: 20,
	},
	input: {
		color: 'white',
		backgroundColor: '#acacac',
		'&:before': {
			borderBottom: `1px solid  rgba(166,166,166,0.5)`,
		},
		'&:after': {
			borderBottom: `2px solid  white`,
		},
		'&&&&:hover:before': {
			borderBottom: `1px solid  white`,
		},
	},
	inputLabel: {
		'&.Mui-focused': {
			color: 'white',
		},
		'&:before': {
			color: `white`,
		},
		'&:after': {
			color: `white`,
		},
		'&&&&:hover:before': {
			color: `white`,
		},
	},
}));
