import ApiManager from "./ApiManager";
import Endpoints from "./Endpoints";

let api = new ApiManager();

const register = (request) => {
  return api.getAxios().post(Endpoints.register, request);
};

const ping = (userId, location) => {
  return api.getAxios().post(Endpoints.ping, { client_uuid: userId, location });
};

export default { register, ping };
