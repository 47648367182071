import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useUiModalsContext, useAppContext } from '../../redux/context/ContextFactory';

export default function AlertDialog() {
	const { setOpenAlertDialog, openAlertDialog } = useUiModalsContext();
	const { agentStore } = useAppContext();

	const handleClose = () => {
		setOpenAlertDialog(false, {});
	};

	const renderContent = () => {
		if (openAlertDialog.type === 'no_agent') {
			return 'No available agent at the moment';
		}
		if (openAlertDialog.type === 'store_closed') {
			if (agentStore) {
				return `Il negozio è chiuso. ORARIO: ${agentStore.timePeriod[0].openTime} - ${agentStore.timePeriod[0].closeTime}`;
			}
			return 'Store slug not found in url!!!';
		} else {
			return `    ${openAlertDialog.type}     `;
		}
	};

	return (
		<Dialog
			open={openAlertDialog.open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{'IQOS'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{renderContent()}</DialogContentText>
			</DialogContent>
			<DialogActions>
				{/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
				<Button style={{ color: '#fffff' }} onClick={handleClose}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}
