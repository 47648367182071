import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	useUiModalsContext,
	useAppContext,
} from "../redux/context/ContextFactory";
import { makeStyles } from "@material-ui/core/styles";

import {
	HeadLabel,
	SubHeadlabel,
	BackgroundImage,
	ShadeBackground,
	FooterButton,
	CallButton,
	AppointmentDialog,
	Loader,
	LabelHeader,
	Label,
} from "../components";
import bg from "../assets/home-bg.png";
import StoreClosedView from "../components/dialogs/StoreClosedView";
import { useWindowUnloadEffect } from "../utils/helpers";
import { isFirefox } from "react-device-detect";
import { Button, Fade } from "@material-ui/core";
import NoStoreScreen from "./NoStoreScreen";
import FeedbackScreen from "./FeedbackScreen";
import CallErrorModal from "./CallErrorModal";

import AddToHomeScreen from "@ideasio/add-to-homescreen-react";
import AddToHomeSnack from "../components/snackbar/AddToHomeSnack";

import GAManager from "../analytics/GAManager";

let ga = new GAManager();

export default function HomeScreen() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const [openNoStore, setOpenNoStore] = useState(false);

	const { setOpenStoreList, setOpenAlertDialog } = useUiModalsContext();
	const {
		currentStore,
		getStoreLoading,
		appointment,
		agentStore,
		setHomeFlag,
		storeNotExist,
		getStoreError,
	} = useAppContext();

	const [mediaIsBlocked, setMediaIsBlocked] = useState(false);
	const [innerHeight, setInnerHeight] = useState(window.innerHeight);

	useEffect(() => {
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.callNowPress,
			true
		);
		document.getElementById("ot-sdk-btn").style.display = "block";
		document.getElementById("privacy-btn").style.display = "block";
		setHomeFlag(true);
	}, []);

	useEffect(() => {
		console.log("history");
		return () => {
			if (history.action === "POP") {
				history.go(1);
				console.log("back button pressed", history);
			}
		};
	}, [history]);

	useEffect(() => {
		if (storeNotExist) {
			setOpenNoStore(true);
		}
	}, [storeNotExist]);

	async function enumerateSources() {
		if (
			navigator &&
			navigator.mediaDevices &&
			typeof navigator.mediaDevices.enumerateDevices === "function"
		) {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: true,
					audio: true,
				});
				let devices = await navigator.mediaDevices.enumerateDevices();
				const cameras = devices.filter((device) => {
					return device.kind === "videoinput";
				});
				if (cameras.length >= 1) console.log("cameras avail");
				const mics = devices.filter((device) => {
					return device.kind === "audioinput";
				});
				if (mics.length >= 1) console.log("mics avail");
				const tracks = stream.getTracks();
				if (tracks) {
					for (let t = 0; t < tracks.length; t++) tracks[t].stop();
				}
				return { cameras, mics };
			} catch (error) {
				console.log(error.name);
				return error;
			}
		} else throw "media device stuff not available in this browser";
	}

	const handlePressCall = () => {
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.clickCTA,
			GAManager.IDs.callNowPress
		);
		if (!isFirefox) {
			enumerateSources()
				.then((response) => {
					if (response.name === "NotAllowedError") {
						setMediaIsBlocked(true);
					} else {
						proceedToCall();
					}
				})
				.catch((error) => {
					console.log("response from media:", error);
					setMediaIsBlocked(true);
				});
		} else {
			proceedToCall();
		}
	};

	const proceedToCall = () => {
		if (agentStore && agentStore.isOpened) {
			history.push(`/${location.pathname.substring(1)}/connect`);
		} else {
			setOpenAlertDialog(true, "store_closed");
		}
	};

	const renderCallButton = () => {
		return (
			<div className={classes.buttonWrapper}>
				{mediaIsBlocked ? (
					<div className={classes.mediaWarnView}>
						<Fade in={mediaIsBlocked} timeout={800}>
							<Label
								fontSize={14}
								color="#ffffff"
								isBold
								text="Controlla di aver autorizzato il microfono e il video all'interno delle impostazioni del browser e ricarica la pagina per procedere con la videochiamata"
							/>
						</Fade>
					</div>
				) : (
					<CallButton
						isLoading={getStoreLoading}
						status={agentStore && agentStore.isOpened ? "ready" : ""}
						onPress={() => handlePressCall()}
					/>
				)}
			</div>
		);
	};

	const renderStoreCloseStatus = () => {
		//test store closed view
		return agentStore ? (
			<StoreClosedView />
		) : (
			<div className={classes.loaderWrapper}>
				<Loader />
			</div>
		);
	};

	return (
		<div className={classes.wrapper}>
			{/* <BackgroundImage source={bg} /> */}
			<ShadeBackground>
				<LabelHeader isWhite />
				<div className={classes.content_wrapper}>
					<div className={classes.content}>
						{agentStore && agentStore.isOpened ? (
							<div className={classes.labelContainer}>
								<div className={classes.labelWrapper}>
									<HeadLabel
										color="#34303D"
										text={t("home.connect_to_store")}
									/>
								</div>
								<div className={classes.labelWrapper}>
									<SubHeadlabel
										color="#34303D"
										text={t("home.your_assistant_ready")}
									/>
								</div>
								{/* <Label
									text={
										"Il servizio di spedizione è attivo dal lunedì al venerdì"
									}
									fontSize={14}
									color="#34303D"
									isBold
									isItalic
								/> */}
								<Label
									text={t("home.cam_alert")}
									fontSize={16}
									color="#34303D"
									isBold
								/>
								<div style={{ marginTop: 12, paddingLeft: 6, paddingRight: 6 }}>
									<Label
										text={
											"Procedendo con la videochiamata dichiari di essere un fumatore adulto"
										}
										fontSize={12}
										color="#34303D"
										isItalic
									/>
								</div>
							</div>
						) : (
							<div></div>
						)}
						{appointment != null && agentStore && agentStore.isOpened ? (
							<AppointmentDialog onPress={() => handlePressCall()} />
						) : agentStore && agentStore.isOpened ? (
							((document.getElementById("privacy-btn").style.display = "block"),
								renderCallButton())
						) : (
							((document.getElementById("privacy-btn").style.display = "none"),
								renderStoreCloseStatus())
						)}
						<div
							className={classes.footer}
							style={{ opacity: agentStore && agentStore.isOpened ? 1 : 0 }}
						>
							<FooterButton
								disabled={getStoreLoading}
								label="Cambia negozio"
								onPress={() => setOpenStoreList(true)}
							/>
						</div>
					</div>
					{/* <AddToHomeSnack /> */}
				</div>
			</ShadeBackground>
			<NoStoreScreen open={openNoStore} onClose={() => setOpenNoStore(false)} />
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: "100%",
		height: "100%",
		position: "relative",
	},
	content_wrapper: {
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		width: "100%",
		flexDirection: "column",
		height: "calc(100% - 100px)",
	},
	loaderWrapper: {
		display: "flex",
		flexDirection: "column",
		width: 280,
		height: 181,
		justifyContent: "center",
		alignItems: "center",
	},
	content: {
		width: "100%",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		marginBottom: 16,
	},
	footer: {
		position: "absolute",
		left: 0,
		right: 20,
		bottom: 0,
		height: 50,
		paddingBottom: 0,
		justifyContent: "center",
		alignItems: "flex-end",
		display: "flex",
		flexDirection: "column",
	},
	labelContainer: {
		backgroundColor: "#F6F4F0",
		margin: 24,
		borderRadius: 4,
		paddingBottom: 16,
		paddingTop: 20,
		maxWidth: 400,
		paddingRight: 8,
		paddingLeft: 8,
	},
	labelWrapper: {
		marginBottom: 16,
	},
	buttonWrapper: {
		marginTop: 22,
	},
	mediaWarnView: {
		width: 280,
		height: 125,
		zIndex: 1000,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row",
	},
	cookieSettings: {
		position: "absolute",
		bottom: 6,
		right: 10,
	},
}));
