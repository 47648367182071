import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Label from '../labels/Label';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useUiModalsContext } from '../../redux/context/ContextFactory';
import ProgressTimer from '../timer/ProgressTimer';

export default function TimerSnack() {
	const classes = useStyles();
	const { setOpenTimerSnack, openTimerSnack } = useUiModalsContext();

	const handleClose = () => {
		setOpenTimerSnack(false);
	};

	return (
		<Snackbar
			open={openTimerSnack}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transitionDuration={{ exit: 600 }}
		>
			<div className={classes.root}>
				<Label text="Stai per essere connesso con il tuo assistente tra:" color="#34303D" fontSize={14} />
				<ProgressTimer onEndCount={() => handleClose()} />
				{/* <Button className={classes.button} onClick={() => handleClose()} startIcon={<KeyboardBackspaceIcon />}>
					<Label text="TORNA ALLA VIDEOCHIAMATA" color="#fffff" fontSize={12} isBold />
				</Button> */}
			</div>
		</Snackbar>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 600,
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white',
		padding: 20,
		borderRadius: 4,
	},
	button: {
		fontSize: 12,
		fontWeight: 'bold',
		color: '#fffff',
		fontFamily: 'Iqos',
		backgroundColor: 'transparent',
	},
}));
