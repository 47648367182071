import { createContext, useContext } from 'react';

export const CONTEXTS = {
	MAKE_CALL: 'MAKE_CALL',
	APP: 'APP',
	UI_MODALS: 'UI_MODALS',
	STORES: 'STORES',
};
const contextObject = {};

export const ContextFactory = (contextName) => {
	if (!contextObject[contextName]) {
		const NewContext = createContext();

		const ContextProvider = ({ children, data }) => (
			<NewContext.Provider value={data}>{children}</NewContext.Provider>
		);
		contextObject[contextName] = {
			useContext: () => useContext(NewContext),
			ContextProvider,
		};
	}

	return contextObject[contextName];
};

export const useMakeCallContext = () => contextObject[CONTEXTS.MAKE_CALL].useContext();
export const useAppContext = () => contextObject[CONTEXTS.APP].useContext();
export const useUiModalsContext = () => contextObject[CONTEXTS.UI_MODALS].useContext();
export const useStoreContext = () => contextObject[CONTEXTS.STORES].useContext();
