import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label, LabelHeader, MainButton } from '../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Box, Button } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CalendarIcon from '../assets/ic_calendar_ss.svg';
import { useAppContext, useUiModalsContext } from '../redux/context/ContextFactory';
import RecallClientModal from '../screens/RecallClientModal';
import BookingDialog from '../components/dialogs/BookingDialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import GAManager from '../analytics/GAManager';

let ga = new GAManager();

RejectCallScreen.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	message: PropTypes.object,
};

export default function RejectCallScreen(props) {
	const classes = useStyles();
	const { agentStore, removeFromQueue } = useAppContext();
	const { setOpenBPView } = useUiModalsContext();
	const [openBookingDialog, setOpenBookingDialog] = useState(false);
	const [openRecallModal, setOpenRecallModal] = useState(false);
	const matches = useMediaQuery('(min-height:670px)');

	useEffect(() => {
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.recallSaNotAvailable,
			true
		);
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.bookSaNotAvailable,
			true
		);
		if (props.open) {
			// setTimeout(() => removeFromQueue(), 1000);
		}
	}, [props.open]);

	const renderMessage = () => {
		if (props.message && props.message.onlineAgents === 0) {
			return 'Ci dispiace, al momento non ci sono assistenti disponibili.';
		}
		return 'Ci dispiace che tu non sia riuscito a parlare con il tuo Shop Assistant.';
	};

	return (
		<Modal
			className={classes.modal}
			open={props.open}
			onClose={() => props.onClose()}
			closeAfterTransition
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Slide in={props.open} timeout={500} direction="up" mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<LabelHeader />

					<div className={classes.content}>
						<Box className={classes.bookingBox}>
							<div className={classes.titleWrapper}>
								<Label isBold fontSize={18} color={'#34303D'} text={renderMessage()} />
							</div>
							<Label
								fontSize={16}
								text="Puoi inserire il tuo numero di telefono e ti richiameremo noi, oppure puoi prenotare un appuntamento."
								color="#34303D"
							/>
							<Button
								disabled={props.disabled}
								className={classes.button}
								style={{ fontSize: matches ? 16 : 14 }}
								variant="outlined"
								onClick={() => {
									ga.trackEvent(
										GAManager.CATEGORY.shop_streaming,
										GAManager.ACTION.clickCTA,
										GAManager.IDs.recallSaNotAvailable
									);
									setOpenRecallModal(true);
								}}
							>
								Vorrei essere richiamato
							</Button>
							<Button
								disabled={props.disabled}
								className={classes.bookingButton}
								style={{ fontSize: matches ? 16 : 14 }}
								variant="outlined"
								onClick={() => {
									ga.trackEvent(
										GAManager.CATEGORY.shop_streaming,
										GAManager.ACTION.clickCTA,
										GAManager.IDs.bookSaNotAvailable
									);
									setOpenBookingDialog(true);
								}}
							>
								Prenota un appuntamento
							</Button>
							<div style={{ marginTop: 12, paddingRight: 8, paddingLeft: 8 }}>
								<Label
									text={'Il servizio di spedizione è attivo dal lunedì al venerdì'}
									fontSize={14}
									color="#34303D"
									isBold
									isItalic
								/>
							</div>
						</Box>
					</div>
					<RecallClientModal open={openRecallModal} onClose={() => setOpenRecallModal(false)} />
					<BookingDialog
						open={openBookingDialog}
						onClose={() => setOpenBookingDialog(false)}
						onOpenBP={(link) => {
							//close dialog and open iframe
							setOpenBookingDialog(false);
							setOpenBPView(true, link);
						}}
					/>
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {
		width: '100%',
		height: '100%',
		outline: 'none',
	},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: 'white',
		color: 'white',
		'&::placeholder': {
			color: 'white',
		},
	},
	wrapper: {
		backgroundColor: '#FFFDFB',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		outline: 'none',
	},
	field: {
		marginTop: 16,
		color: 'white',
	},
	button: {
		fontFamily: 'Iqos',
		height: 42,
		letterSpacing: 0.5,
		width: 260,
		borderRadius: 24,
		borderColor: '#34303D',
		borderWidth: 2,
		marginTop: 20,
	},
	titleWrapper: {
		marginBottom: 20,
	},
	buttonWrapper: {
		marginTop: 16,
		width: '100%',
	},
	content: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		paddingTop: 16,
		maxWidth: 420,
	},
	bookingBox: {
		backgroundColor: '#00D1D2',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 26,
		paddingBottom: 26,
		paddingLeft: 16,
		paddingRight: 16,
	},
	bookingButton: {
		backgroundColor: '#34303D',
		color: '#ffffff',
		fontFamily: 'Iqos',
		height: 42,
		letterSpacing: 0.5,
		width: 260,
		borderRadius: 24,
		marginTop: 10,
		'&:hover': {
			backgroundColor: '#34303D',
		},
	},
}));
