import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label, LabelHeader, RatingView } from '../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { Box, Button, Fade, Link, TextField, InputBase } from '@material-ui/core';
import maleIcon from '../assets/icon-feedback-bad.svg';
import beneIcon from '../assets/icon-feedback-medium.svg';
import benissimoIcon from '../assets/icon-feedback-good.svg';
import { useAppContext, useUiModalsContext } from '../redux/context/ContextFactory';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CalendarIcon from '../assets/calendar.svg';
import AddToHomeSnack from '../components/snackbar/AddToHomeSnack';
import FeedbackAnimation from '../components/lottie/FeedbackAnimation';
import BookingDialog from '../components/dialogs/BookingDialog';
import FeedbackService from '../api/FeedbackService';
import smileImg from '../assets/opinion-smile.png';
import GAManager from '../analytics/GAManager';
import tnxIcon from '../assets/tnx_icon.svg';

let ga = new GAManager();

FeedbackScreen.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	type: PropTypes.oneOf(['end', 'interupt']),
};

export default function FeedbackScreen(props) {
	const classes = useStyles();
	const [feedback, setFeedback] = useState('');
	const [feedbackMsg, setFeedbackMsg] = useState('');
	const [fieldValue, setFieldValue] = useState('');
	const [feedbackValue, setFeedbackValue] = useState(0);
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [surname, setSurname] = useState();
	const [phone, setPhone] = useState('');
	const { postFeedback, removeFromQueue, agentStore, userId, clientUpdate, clientUpdateSuccess, clientUpdateError } =
		useAppContext();
	const { setOpenBPView } = useUiModalsContext();
	const [openBookingDialog, setOpenBookingDialog] = useState(false);

	useEffect(() => {
		if (props.open) {
			ga.trackEvent(
				GAManager.CATEGORY.shop_streaming,
				GAManager.ACTION.impressionCTA,
				GAManager.IDs.bookEndCall,
				true
			);
			// removeFromQueue();
		}
	}, [props.open]);

	useEffect(() => {
		console.log('client update success', clientUpdateSuccess);
	}, [clientUpdateSuccess]);

	const getMessageLabel = () => {
		switch (props.type) {
			case 'end':
				return 'Grazie per aver visitato il negozio IQOS.';
			case 'interupt':
				return 'Ci dispiace che tu abbia abbandonato la videochiamata';
			default:
				return '';
		}
	};

	const sendFeedback = () => {
		//send on api feedback message
		console.log('feedbackValue', feedbackValue);
		console.log('fieldValue', fieldValue);

		if (fieldValue === '') {
			ga.trackEvent(
				GAManager.CATEGORY.shop_streaming,
				GAManager.ACTION.submitStarsOnly,
				GAManager.IDs.submitStarsOnly
			);
		} else {
			ga.trackEvent(
				GAManager.CATEGORY.shop_streaming,
				GAManager.ACTION.submitStarsComment,
				GAManager.IDs.submitStarsComment
			);
		}

		postFeedback(feedbackValue, fieldValue)
			.then((response) => {
				console.log('==== FEEDBACK SUCCESS ===', response);
				console.log('feedbackValue =>', feedbackValue, 'fieldValue => ', fieldValue);
				if (feedbackValue === 0 && fieldValue === '') {
					setFeedback('Grazie!');
				} else {
					setFeedback(response.status + '');
				}
			})
			.catch((error) => {
				console.log('==== FEEDBACK ERROR ===', error);

				setFeedback(error.status);
			});
	};

	const cancelFeedbackMsg = () => {
		setFeedback('Grazie!');
	};

	const renderFeedbackInput = () => {
		{
			return feedbackMsg === '' ? (
				<Fade in={feedbackMsg === ''} timeout={1200}>
					<Box display="flex" flexDirection="column" className={classes.inputWrapper}>
						<TextField
							className={classes.fieldFeedback}
							fullWidth
							multiline
							rowsMax={5}
							rows={5}
							label="Lascia un commento"
							variant="outlined"
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutline,
									input: classes.notchedOutline,
								},
							}}
							InputLabelProps={{
								style: { color: '#34303D' },
							}}
							onChange={(e) => setFieldValue(e.target.value)}
						/>

						<div style={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
							<Label
								fontSize={12}
								text=" La tua valutazione sarà trattata in forma anonima. "
								color="#34303D"
								isHidden={feedback != ''}
							/>
						</div>

						<Box display="flex" justifyContent="space-between" style={{ marginTop: 10 }}>
							<Button className={classes.cancelBtn} onClick={() => cancelFeedbackMsg()}>
								Annulla
							</Button>
							<Button variant="contained" className={classes.sendButton} onClick={() => sendFeedback()}>
								Invia
							</Button>
						</Box>
					</Box>
				</Fade>
			) : (
				renderFeedbackLabel()
			);
		}
	};

	const renderFeedbackLabel = () => {
		return (
			<Fade in={feedback !== ''} timeout={1200}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					className={classes.feedbackLabelWrapper}
				>
					<img src={tnxIcon} style={{ marginBottom: 18 }} />
					<Label fontSize={18} text="Grazie!" color="#34303D" isBold />
					<Label
						fontSize={18}
						text={`Per noi la tua opinione è importante.`}
						color="#34303D"
						isBold
						isHidden={feedback === 'Grazie!'}
					/>
					{/* <Label fontSize={8} text={feedback} isHidden={feedback === 'Grazie!'} /> */}
				</Box>
			</Fade>
		);
	};

	const applyForm = () => {
		let request = {
			client_uuid: userId,
			surname,
			name,
			email,
			phone,
		};
		if (!name) {
			setName('');
		} else if (!surname) {
			setSurname('');
		} else if (surname && surname !== '' && name && name !== '') {
			clientUpdate(request);
		}
	};

	const offset = (56 - 40) / 2;

	return (
		<Modal
			className={classes.modal}
			open={props.open}
			onClose={() => props.onClose()}
			closeAfterTransition
			BackdropProps={{
				timeout: 300,
			}}
		>
			<Slide in={props.open} timeout={300} direction="down" mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<AddToHomeSnack />
					<LabelHeader top={0} />

					<div className={classes.content}>
						<Box className={classes.beigeBox}>
							<div className={classes.titleWrapper}>
								<Label
									fontSize={18}
									color={'#34303D'}
									text={'Grazie per aver visitato il negozio IQOS'}
									isBold
									isHidden={feedback !== ''}
								/>
							</div>
							{feedback === '' ? (
								<div
									style={{
										// width: '100%',
										height: '100%',
										display: 'flex',
										justifyContent: 'center',
										flexDirection: 'column',
										alignItems: 'center',
									}}
								>
									<div style={{ padding: 10 }}>
										<Label
											fontSize={16}
											text="Come valuti la tua esperienza?"
											color="#34303D"
											isHidden={feedback != ''}
										/>
									</div>
									<RatingView
										value={feedbackValue}
										onChangeValue={(newValue) => {
											ga.trackEvent(
												GAManager.CATEGORY.shop_streaming,
												GAManager.ACTION.clickStar,
												newValue
											);
											setFeedbackValue(newValue);
										}}
									/>
									{renderFeedbackInput()}
								</div>
							) : (
								renderFeedbackLabel()
							)}
						</Box>
						<Box className={classes.bookingBox}>
							<img src={CalendarIcon} />
							<div style={{ margin: 20 }}>
								<Label
									fontSize={22}
									text="Vuoi prenotare un nuovo appuntamento?"
									color="#34303D"
									isBold
								/>
							</div>

							<Button
								fullWidth
								disabled={props.disabled}
								className={classes.sendButton}
								variant="contained"
								onClick={() => {
									ga.trackEvent(
										GAManager.CATEGORY.shop_streaming,
										GAManager.ACTION.clickCTA,
										GAManager.IDs.bookEndCall
									);
									setOpenBookingDialog(true);
								}}
							>
								Prenota ora
							</Button>
						</Box>
						{/* {clientUpdateSuccess ? (
							<Box className={classes.formBox}>
								<Fade in={clientUpdateSuccess} timeout={1200}>
									<Label
										isBold
										fontSize={14}
										color={'#ffffff'}
										text={'Grazie per aver compilato il form, ti contatteremo presto.'}
									/>
								</Fade>
							</Box>
						) : (
							<Box className={classes.formBox}>
								<Label
									isBold
									fontSize={14}
									color={'#ffffff'}
									text={
										'Lasciaci i tuoi dati così potremo contattarti in caso di novità o aggiornamenti.'
									}
								/>
								<TextField
									className={classes.field}
									fullWidth
									value={name}
									error={name === ''}
									helperText={name === '' ? 'Il nome è obbligatorio' : ''}
									label="Nome"
									variant="outlined"
									color="primary"
									margin="dense"
									style={{ marginTop: 20 }}
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutlineForm,
											input: classes.notchedOutlineForm,
										},
									}}
									InputLabelProps={{
										style: { color: '#fff', fontFamily: 'Noto Sans' },
									}}
									onChange={(e) => setName(e.target.value)}
								/>
								<TextField
									className={classes.field}
									fullWidth
									value={surname}
									error={surname === ''}
									helperText={surname === '' ? 'Il cognome è obbligatorio' : ''}
									label="Cognome"
									variant="outlined"
									color="primary"
									margin="dense"
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutlineForm,
											input: classes.notchedOutlineForm,
										},
									}}
									InputLabelProps={{
										style: { color: '#fff', fontFamily: 'Noto Sans' },
									}}
									onChange={(e) => setSurname(e.target.value)}
								/>
								<TextField
									className={classes.field}
									fullWidth
									value={email}
									label="Email"
									variant="outlined"
									color="primary"
									margin="dense"
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutlineForm,
											input: classes.notchedOutlineForm,
										},
									}}
									InputLabelProps={{
										style: { color: '#fff', fontFamily: 'Noto Sans' },
									}}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<TextField
									className={classes.field}
									fullWidth
									value={phone}
									label="Telefono"
									variant="outlined"
									color="primary"
									margin="dense"
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutlineForm,
											input: classes.notchedOutlineForm,
										},
									}}
									InputLabelProps={{
										style: { color: '#fff', fontFamily: 'Noto Sans' },
									}}
									onChange={(e) => setPhone(e.target.value)}
								/>
								<div className={classes.buttonWrapper}>
									<Button
										fullWidth
										disabled={props.disabled}
										className={classes.bookingButton}
										variant="contained"
										onClick={() => {
											applyForm();
										}}
									>
										INVIA
									</Button>
								</div>
							</Box>
						)} */}
					</div>
					<BookingDialog
						open={openBookingDialog}
						onClose={() => setOpenBookingDialog(false)}
						onOpenBP={(link) => {
							//close dialog and open iframe
							setOpenBookingDialog(false);
							setOpenBPView(true, link);
						}}
					/>
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {
		outline: 'none',
		overflow: 'scroll',
		margin: 0,
		overflowX: 'hidden',
		backgroundColor: '#202020',
	},
	fieldFeedback: {
		color: '#34303D',
		height: 128,
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#34303D',
			borderWidth: 1,
		},
	},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: '#34303D',
		color: '#34303D',
		'&::placeholder': {
			color: '#34303D',
		},
		height: 128,
		'&$focused $notchedOutline': {
			borderColor: '#4A90E2',
			borderWidth: 1,
		},
	},
	notchedOutlineForm: {
		borderWidth: '1px',
		fontFamily: 'Iqos',
		borderColor: '#34303D',
		color: '#34303D',
		'&::placeholder': {
			color: '#34303D',
		},
		'&$focused $notchedOutline': {
			borderColor: '#4A90E2',
			borderWidth: 1,
		},
	},
	cancelBtn: {
		color: '#34303D',
		fontFamily: 'Iqos',
		width: '48%',
		letterSpacing: 0.5,
	},
	sendButton: {
		marginTop: 2,
		width: '48%',
		fontFamily: 'Iqos',
		height: 42,
		letterSpacing: 0.5,
		borderRadius: 24,
		backgroundColor: '#34303D',
		color: '#FFFDFB',
		'&:hover': {
			backgroundColor: '#34303D',
		},
	},

	wrapper: {
		backgroundColor: '#FFFDFB',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		minHeight: '100%',
		outline: 'none',
		paddingTop: 10,
	},
	button: {
		backgroundColor: 'white',
		height: 48,
		marginTop: 10,
	},
	titleWrapper: {
		padding: 10,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonWrapper: {
		marginTop: 16,
		width: '100%',
	},
	content: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		// paddingTop: 12,
		flexDirection: 'column',
		maxWidth: 420,
		width: '100%',
		// padding: 12,
	},
	beigeBox: {
		backgroundColor: '#F6F4F0',
		width: '100%',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 4,
		paddingTop: 20,
		paddingBottom: 26,
		height: 360,
	},
	formBox: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: '#414042',
		padding: 16,
		// marginLeft: 16,
		// marginRight: 16,
		marginTop: 16,
		borderRadius: 4,
		maxWidth: 400,
		height: 420,
	},
	field: {
		color: 'white',
		marginTop: 10,
		height: 60,
	},
	icon: {
		width: 60,
		height: 50,
	},
	iconBox: {
		width: '90%',
		marginTop: 0,
		padding: 4,
		resize: 'contain',
	},
	bookingBox: {
		backgroundColor: '#00D1D2',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 32,
		paddingTop: 32,
		paddingBottom: 32,
		// paddingLeft: 16,
		// paddingRight: 16,
	},
	bookingButton: {
		marginTop: 2,
		width: 290,
		fontFamily: 'Noto Sans',
		height: 42,
		fontWeight: 'bold',
		letterSpacing: 1.92,
		fontSize: 12,
	},
	feedbackLabelWrapper: {
		width: '80%',
		height: '100%',
		marginBottom: 16,
	},
	inputWrapper: {
		width: '90%',
		height: '94%',
		top: 20,
		paddingLeft: 16,
		paddingRight: 16,
	},
	imageButton: {
		'&:hover': {
			backgroundColor: '#A0A0A022',
		},
	},
}));
