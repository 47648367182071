import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BackgroundImage, Label, CallButton, FooterButton, LabelHeader, SubHeadlabel, HeadLabel } from '../components';
import { useSnackbar } from 'notistack';
import OnHoldScreeen from './OnHoldScreen';
import { useHistory, useParams, useLocation, Prompt } from 'react-router-dom';
import opacity from '../assets/opacity.png';
import bg from '../assets/home-bg.png';
import { Box, Fade, Slide, Modal } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useAppContext, useUiModalsContext } from '../redux/context/ContextFactory';
import { usePubNub } from 'pubnub-react';
import DialScreen from './DialScreen';
import { useWindowUnloadEffect } from '../utils/helpers';
import FeedbackScreen from './FeedbackScreen';
import RejectCallScreen from './RejectCallScreen';
import CallErrorModal from './CallErrorModal';
import NavigationPrompt from 'react-router-navigation-prompt';
import ActionDialog from '../components/dialogs/ActionDialog';
import { useTranslation } from "react-i18next";

import GAManager from '../analytics/GAManager';

let ga = new GAManager();

const PING_PERIOD = 30 * 1000;
export default function ConnectScreen() {
	const classes = useStyles();
	const history = useHistory();
	const pubnub = usePubNub();
	const { t, i18n } = useTranslation();

	const { slug } = useParams();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	//MODALS
	const [viewState, setViewState] = useState('connect');
	const [pingInterval, setPingInterval] = useState();
	// const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { setOpenAlertDialog, openTimerSnack } = useUiModalsContext();
	const [openRejectScreen, setRejectOpenScreen] = useState(false);
	const [openFeedback, setOpenFeedback] = useState({ open: false, reason: 'end' });

	//APP CONTEXT
	const {
		ping,
		userId,
		agentStore,
		addToQueue,
		queueError,
		queueID,
		removeFromQueue,
		currentStore,
		setAgentID,
		setChatUrl,
		checkIn,
		appointmentID,
		queueIdProgress,
		cancelQueue,
	} = useAppContext();

	const [channels] = useState([userId, slug]);
	const [message, setMessage] = useState({ type: '' });
	const [pubnubEvent, setPubnubEvent] = useState();
	const [callError, setCallError] = useState(false);
	const [call, setCall] = useState();
	var pingListener;

	useEffect((_) => {
		document.getElementById('ot-sdk-btn').style.display = 'none';
		document.getElementById('privacy-btn').style.display = 'none';
		ping(userId, 'calling');
		pingListener = setInterval(function () {
			ping(userId, 'calling');
		}, PING_PERIOD);
		setPingInterval(pingListener);
		//if queue id is not null  we have already queue id from get reservation
		if (queueID === null) {
			setTimeout(() => addToQueue(userId, agentStore ? agentStore.uuid : 0), 2000);
		}
		return () => {
			console.log('mare unload connect');
			clearInterval(pingInterval);
		};
	}, []);

	useEffect(() => {
		var pubnubListeners = { message: handleMessage };
		pubnub.addListener(pubnubListeners);
		pubnub.subscribe({ channels });
		if (appointmentID) {
			console.log('rid', appointmentID);
			checkIn(appointmentID);
		}
		return () => {
			pubnub.removeListener(pubnubListeners);
		};
	}, [pubnub, channels, queueID]);

	useEffect(() => {
		console.log(queueError);
		if (queueError != '') {
			console.log(queueError);
			if (queueError === 'QUEUE_FULL') {
				cancelCall();
			}
		}
	}, [queueError]);

	useEffect(() => {
		if (message.type === 'turnedoff') {
			console.log('PUBNUB TURNEDOFF =====>', message);
			cancelCall();
		}

		if (pubnubEvent && pubnubEvent.channel === userId && message.type === 'update' && message.onlineAgents === 0) {
			cancelCall();
		}

		if (pubnubEvent && pubnubEvent.channel === userId && message.type === 'update' && message.waitingTime > 0) {
			setViewState('queue');
		}
		if (
			pubnubEvent &&
			pubnubEvent.channel === userId &&
			message.type === 'update' &&
			message.queues === 0 &&
			message.availableAgents === 0
		) {
			setViewState('queue');
		}

		if (pubnubEvent && pubnubEvent.channel === userId && message.type === 'call') {
			setChatUrl(message.channel_url);
			setAgentID(message.agent_uuid);
			setViewState('call');
		}

		if (message.type === 'groupchannel') {
			console.log('receive channel url', message.channel_url);
			setChatUrl(message.channel_url);
		}
	}, [message]);

	const handleMessage = (event) => {
		const message = event.message;
		if (event && event.channel === userId) {
			setPubnubEvent(event);
			console.log('PUBNUB RECEIVE EVENT USER CHANNEL =====>', event);
			setMessage(message);
		}
	};

	const cancelCall = () => {
		ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.clickCTA, GAManager.IDs.endCall);
		clearInterval(pingInterval);
		setRejectOpenScreen(true);
	};

	const endCall = (reason) => {
		clearInterval(pingInterval);
		setOpenFeedback({ open: true, reason });
	};

	const onCloseFeedback = () => { };

	//RENDER
	const renderQueueView = () => {
		return <OnHoldScreeen message={message} onEndCall={() => cancelCall()} />;
	};

	const renderCallView = () => {
		return (
			<DialScreen
				onEndCall={() => {
					removeFromQueue();
					endCall('end');
				}}
				onInteruptCall={() => {
					removeFromQueue();
					cancelCall();
				}}
				onConnectionLost={() => {
					// removeFromQueue()
					// clearInterval(pingInterval);
					setCallError(true);
				}}
				onInitCall={(call) => setCall(call)}
			/>
		);
	};

	const renderConnectView = () => {
		return (
			<div className={classes.wrapper}>
				<LabelHeader isWhite />
				<div className={classes.content_wrapper}>
					<Fade in={true} timeout={1000}>
						<div className={classes.shade_wrapper}>
							<div className={classes.labelContainer}>
								<div className={classes.store_label}>
									<HeadLabel color="#34303D" text="Sto chiamando..." />
								</div>
								<div className={classes.messageWrapper}>
									<SubHeadlabel
										color="#34303D"
										text="Non mettere giù il browser o il telefono altrimenti cadrà la chiamata."
									/>
								</div>
								<div style={{ marginTop: 12, paddingLeft: 6, paddingRight: 6 }}>
									<Label
										text={t("home.cam_alert")}
										fontSize={16}
										color="#34303D"
										isBold
									/>
								</div>
							</div>

							<div style={{ height: 125 }} />
						</div>
					</Fade>
				</div>
				<div className={classes.footer}>
					<FooterButton disabled={true} label="CAMBIA NEGOZIO" onPress={() => { }} />
				</div>
			</div>
		);
	};

	const renderMainView = () => {
		switch (viewState) {
			case 'connect':
				return renderConnectView();
			case 'queue':
				return renderQueueView();
			case 'call':
				return renderCallView();
		}
	};

	// window.onbeforeunload = (event) => {
	// 	const e = event || window.event;
	// 	console.log('simone', e);
	// 	e.preventDefault();

	// 	// if (window.confirm('simone')) {
	// 	// 	console.log('simone asasas');
	// 	// 	window.location.reload();
	// 	// }
	// 	if (e) {
	// 		e.returnValue = '';
	// 	}
	// 	return '';
	// };

	return (
		<div>
			{renderMainView()}
			<FeedbackScreen open={openFeedback.open} onClose={() => onCloseFeedback()} type={openFeedback.reason} />
			<RejectCallScreen open={openRejectScreen} message={message} />
			<CallErrorModal
				open={callError}
				onClose={() => setCallError(false)}
				onPressCall={() => {
					setCallError(false);
					setViewState('connect');
					addToQueue(userId, agentStore ? agentStore.uuid : 0);
				}}
			/>
			<NavigationPrompt
				when={true}
				disableNative
				afterConfirm={() => {
					clearInterval(pingInterval);
					removeFromQueue();
					if (call) {
						call.end();
					}
					window.location.reload();
				}}
			>
				{({ onConfirm, onCancel }) => (
					<ActionDialog
						activeCall={!openFeedback.open && !openRejectScreen && !callError}
						open={true}
						onCancel={onCancel}
						onConfirm={onConfirm}
					/>
				)}
			</NavigationPrompt>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: '100vw',
		height: '100%',
		position: 'relative',
	},
	content_wrapper: {
		// justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	store_label: {
		zIndex: 101,
	},
	labelContainer: {
		backgroundColor: '#F6F4F0',
		margin: 16,
		borderRadius: 4,
		paddingBottom: 20,
		paddingTop: 20,
		maxWidth: 400,
	},
	shade_wrapper: {
		width: '94%',
		height: '60%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		flexDirection: 'column',
		paddingTop: 20,
		paddingBottom: 20,
		maxWidth: 400,
		backgroundColor: 'transparent',
		marginTop: 32,
	},
	opacity_img: {
		position: 'absolute',
		width: '100%',
		top: 0,
		height: '100%',
		objectFit: 'cover',
		borderRadius: 4,
	},
	footer: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		height: 50,
		paddingBottom: 20,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	messageWrapper: {
		paddingLeft: 20,
		paddingRight: 20,
	},
}));
