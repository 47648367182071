import ApiManager from './ApiManager';
import Endpoints from './Endpoints';

let api = new ApiManager();

const postFeedback = (feedback, message, id) => {
	let request = { feedback, message };
	return api.getAxios().post(Endpoints.feedback(id), request);
};

const update = (obj) => {
	let request = obj;
	return api.getAxios().post(Endpoints.clientsUpdate, request);
};

export default { postFeedback, update };
