import ApiManager from "./ApiManager";
import Endpoints from "./Endpoints";

let api = new ApiManager();

const postRecall = (phone, name, store_uuid, crmID) => {
  let request = { phone, name, store_uuid, CRMClientID: crmID };
  return api.getAxios().post(Endpoints.recall, request);
};

export default { postRecall };
