export default class LocaleStorageManager {
	static USER = 'CLIENT';
	static LEGAL = 'LEGAL';

	constructor() {
		const instance = this.constructor.instance;
		if (instance) {
			return instance;
		}
		this.constructor.instance = this;
	}

	get(key) {
		const value = localStorage.getItem(key);
		return value ? JSON.parse(value) : null;
	}

	set(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	remove(key) {
		localStorage.removeItem(key);
	}
}
