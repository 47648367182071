import React from 'react';
import './BackgroundImage.css';
import PropTypes from 'prop-types';

BackgroundImage.propsType = {
	source: PropTypes.string,
};

function BackgroundImage(props) {
	return <div className="background-wrapper">{props.children}</div>;
}

export default BackgroundImage;
