import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

HeadLabel.propTypes = {
	text: PropTypes.string,
	color: PropTypes.string,
};

export default function HeadLabel(props) {
	const classes = useStyles();
	const matches = useMediaQuery('(min-height:670px)');
	return (
		<Typography
			className={classes.root}
			style={{ fontSize: matches ? 24 : 20, color: props.color ? props.color : '#FFFFFFFF' }}
		>
			{props.text}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontWeight: 100,
		fontFamily: 'IqosBold',
	},
}));
