import ApiManager from './ApiManager';
import Endpoints from './Endpoints';

let api = new ApiManager();

const addToQueue = (client_uuid, store_uuid) => {
	return api.getAxios().post(Endpoints.queues, { client_uuid, store_uuid });
};

const removeFromQueue = (queueID) => {
	return api.getAxios().delete(Endpoints.removeQueue(queueID));
};

const checkIn = (reservationId) => {
	let request = { reservation_uuid: reservationId };
	return api.getAxios().put(Endpoints.checkIn, request);
};

const cancelQueue = (queueID) => {
	return api.getAxios().delete(Endpoints.cancelQueue(queueID));
};

const saveCallID = (queueID, callID) => {
	let request = { call_id: callID };
	console.log('SAVE CALL REQUEST', request);
	return api.getAxios().put(Endpoints.saveCallID(queueID), request);
};

export default { addToQueue, removeFromQueue, checkIn, cancelQueue, saveCallID };
