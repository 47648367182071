import { useReducer } from 'react';
const initialState = {
	dialStartedRequest: false,
	dialFinishedSuccess: false,
	dialFinishedError: false,
	call: null,
	isRingingRequest: false,
};

const makeCallReducer = (state, action) => {
	switch (action.type) {
		case 'DIAL_STARTED_REQUEST': {
			return {
				...state,
				dialStartedRequest: true,
			};
		}
		case 'DIAL_FINISHED_SUCCESS': {
			return {
				...state,
				dialStartedRequest: false,
				call: action.payload,
			};
		}
		case 'DIAL_FINISHED_ERROR': {
			return {
				...state,
				dialStartedRequest: false,
			};
		}
		case 'IS_RINGING_REQUEST': {
			return {
				...state,
				call: action.payload,
				isRingingRequest: true,
			};
		}
		case 'IS_RINGING_FINISHED': {
			return {
				...state,
				isRingingRequest: false,
			};
		}
	}
};

const useMakeCall = ({ params = {}, data = {}, callback = {} }) => {
	const [state, dispatch] = useReducer(makeCallReducer, {
		...initialState,
	});

	const dialStarted = () => {
		dispatch({ type: 'DIAL_STARTED_REQUEST' });
	};

	const dialFinished = (call) => {
		dispatch({ type: 'DIAL_FINISHED_SUCCESS', payload: call });
	};

	const dialFinishedError = (error) => {
		dispatch({ type: 'DIAL_FINISHED_ERROR', payload: error });
	};

	const startRinging = (call) => {
		dispatch({ type: 'IS_RINGING_REQUEST', payload: call });
	};

	return {
		...state,
		dialStarted,
		dialFinished,
		dialFinishedError,
		startRinging,
	};
};

export default useMakeCall;
