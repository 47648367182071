import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import opacity from '../../assets/opacity.png';
import { useAppContext, useUiModalsContext } from '../../redux/context/ContextFactory';
import { Label, StoreLabel, FooterButton } from '../../components';
import moment from 'moment';
import RecallClientModal from '../../screens/RecallClientModal';
import BookingDialog from '../../components/dialogs/BookingDialog';
import GAManager from '../../analytics/GAManager';
let ga = new GAManager();

export default function StoreClosedView() {
	const classes = useStyles();
	const { agentStore, currentStore } = useAppContext();
	const { setOpenBPView, setOpenStoreList } = useUiModalsContext();
	const [openRecallModal, setOpenRecallModal] = useState(false);
	const [openBookingDialog, setOpenBookingDialog] = useState(false);

	useEffect(() => {
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.recallShopClosed,
			true
		);
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.bookShopClosed,
			true
		);
	}, []);

	const timePeriod = () => {
		let now = moment().format('HH:mm');
		let nowMilis = +moment(now, 'HH:mm').format('x');
		console.log('now', nowMilis);
		if (agentStore.timePeriod.length > 1) {
			let firstOpentime = agentStore.timePeriod[0].openTime;
			let secondOpenTime = agentStore.timePeriod[1].openTime;
			let firstMilis = +moment(firstOpentime, 'HH:mm').format('x');
			let secondMilis = +moment(secondOpenTime, 'HH:mm').format('x');
			console.log('first', firstMilis);
			console.log('second', secondMilis);
			if (nowMilis > firstMilis && nowMilis < secondMilis) {
				return secondOpenTime;
			} else {
				return firstOpentime;
			}
		}
		return agentStore ? agentStore.timePeriod[0].openTime : '';
	};

	return (
		<div className={classes.root_wrapper}>
			<div className={classes.header}>
				<StoreLabel name={currentStore.name} address={currentStore.address} icon />
			</div>
			<div className={classes.content}>
				<div className={classes.labels_wrapper}>
					<Label text={'Il negozio è aperto nei seguenti orari:'} fontSize={18} color="#34303D" isBold />
				</div>
				<div className={classes.timesWrapper}>
					{agentStore.timePeriod.map((period) => {
						return (
							<Label
								key={period}
								text={period.openTime + ` - ` + period.closeTime}
								color="#34303D"
								fontSize={18}
								isBold
							/>
						);
					})}
				</div>
				<div className={classes.subtitleWrapper}>
					<Label
						text={`Per parlare con il tuo Shop Assistant puoi richiamare in orario di apertura, inserire il tuo numero di telefono per essere richiamato, oppure prenotare un appuntamento!`}
						fontSize={16}
						color="#34303D"
					/>
				</div>
				<Button
					className={classes.button}
					disabled={false}
					variant="outlined"
					onClick={() => {
						ga.trackEvent(
							GAManager.CATEGORY.shop_streaming,
							GAManager.ACTION.clickCTA,
							GAManager.IDs.recallShopClosed
						);
						setOpenRecallModal(true);
					}}
				>
					Vorrei essere richiamato
				</Button>
				<Button
					className={classes.bookingButton}
					disabled={false}
					variant="outlined"
					onClick={() => {
						ga.trackEvent(
							GAManager.CATEGORY.shop_streaming,
							GAManager.ACTION.clickCTA,
							GAManager.IDs.bookShopClosed,
							true
						);
						setOpenBookingDialog(true);
					}}
				>
					Prenota un appuntamento
				</Button>
				<div style={{ marginTop: 12, paddingRight: 8, paddingLeft: 8 }}>
					<Label
						text={'Il servizio di spedizione è attivo dal lunedì al venerdì'}
						fontSize={14}
						color="#34303D"
						isBold
						isItalic
					/>
				</div>
			</div>

			<div className={classes.footer}>
				<FooterButton label="Cambia negozio" onPress={() => setOpenStoreList(true)} nonWhite />
			</div>

			<RecallClientModal open={openRecallModal} onClose={() => setOpenRecallModal(false)} />
			<BookingDialog
				open={openBookingDialog}
				onClose={() => setOpenBookingDialog(false)}
				onOpenBP={(link) => {
					setOpenBookingDialog(false);
					setOpenBPView(true, link);
				}}
			/>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root_wrapper: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		// paddingLeft: 12,
		// paddingRight: 12,
		backgroundColor: '#FFFDFB',
		height: '100%',
		position: 'absolute',
		width: '100%',
		marginTop: -20,
	},
	header: {
		width: '100%',
		padding: 24,
		maxWidth: 400,
	},
	content: {
		width: '100%',
		backgroundColor: '#00D1D2',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		padding: 24,
		maxWidth: 400,
	},
	labels_wrapper: {
		width: '80%',
	},
	timesWrapper: {
		zIndex: 100,
		width: '80%',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 16,
	},
	subtitleWrapper: {
		paddingLeft: 8,
		paddingRight: 8,
		margin: 16,
	},
	button: {
		marginTop: 20,
		fontFamily: 'Iqos',
		height: 42,
		letterSpacing: 0.5,
		fontSize: 16,
		width: 260,
		borderRadius: 24,
		borderColor: '#34303D',
		borderWidth: 2,
	},
	bookingButton: {
		backgroundColor: '#34303D',
		color: '#ffffff',
		fontFamily: 'Iqos',
		height: 42,
		letterSpacing: 0.5,
		fontSize: 16,
		width: 260,
		borderRadius: 24,
		marginTop: 20,
		'&:hover': {
			backgroundColor: '#34303D',
		},
	},
	footer: {
		height: 50,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#FFFDFB',
		width: '100%',
	},
}));
