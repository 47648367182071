import React, { useMemo } from 'react';
import { sendBirdSelectors, withSendBird } from 'sendbird-uikit';
import FileMessage from './FileMessage';
import UserMessage from './UserMessage';

function CustomizedMessageItem(props) {
	const { message, onDeleteMessage, onUpdateMessage, userId, channel, sdk, sendUserMessage, sendFileMessage } = props;

	const MessageHOC = useMemo(() => {
		if (message.isFileMessage && message.isFileMessage()) {
			return () => (
				<FileMessage
					message={message}
					userId={userId}
					onDeleteMessage={onDeleteMessage}
					channel={channel}
					sdk={sdk}
					sendUserMessage={sendUserMessage}
					sendFileMessage={sendFileMessage}
				/>
			);
		} else if (message.isUserMessage && message.isUserMessage()) {
			return () => (
				<UserMessage
					message={message}
					userId={userId}
					onDeleteMessage={onDeleteMessage}
					onUpdateMessage={onUpdateMessage}
					channel={channel}
					sdk={sdk}
					sendUserMessage={sendUserMessage}
				/>
			);
		}
		return () => <div />;
	}, [message, userId, onDeleteMessage, onUpdateMessage, channel]);

	return (
		<div id={message.messageId} className="customized-message-item">
			<MessageHOC />
			<br />
		</div>
	);
}

export default withSendBird(CustomizedMessageItem);
