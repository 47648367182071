import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label } from '../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import PeopleIcon from '@material-ui/icons/People';
import { Box, Button } from '@material-ui/core';
import Countdown from 'react-countdown';
import { useUiModalsContext, useAppContext } from '../redux/context/ContextFactory';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

function padLeadingZeros(num, size) {
	var s = num + '';
	while (s.length < size) s = '0' + s;
	return s;
}

VideoScreen.propTypes = {
	onClose: PropTypes.func,
	time: PropTypes.number,
	persons: PropTypes.number,
	url: PropTypes.string,
	noHeader: PropTypes.bool,
};

export default function VideoScreen(props) {
	const classes = useStyles();
	const { setOpenVideoScreen, openVideoScreen } = useUiModalsContext();
	const [timerIsReachedZero, setTimerIsReachedZero] = useState(false);
	const [opened, setOpened] = useState(false);
	const timerRef = useRef(null);
	const { agentStore } = useAppContext();

	const handleClose = () => {
		if (!timerIsReachedZero) {
			timerRef.current.pause();
		} else {
			setOpenVideoScreen(false);
			props.onClose(1);
		}
	};

	useEffect(() => {
		if (openVideoScreen) {
			setTimeout(() => {
				setOpened(true);
			}, 1800);
		}
	}, [openVideoScreen]);

	useEffect(() => {
		console.log('VIDEO SCREEN SEC', props.time);
		return () => {};
	}, [props.time]);

	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			return <div />;
		} else {
			return hours > 0 ? (
				<h2 className={classes.timer_over}>Più di un'ora</h2>
			) : (
				<div className={classes.timer_root}>
					<div style={{ alignItems: 'center', paddingRight: 2, paddingLeft: 2 }}>
						<Label text={padLeadingZeros(minutes, 2)} color={'black'} fontSize={16} />
					</div>
					<div style={{ marginBottom: 0 }}>
						<Label text={':'} color={'black'} letterSpacing={1.42} fontSize={14} />
					</div>
					<div style={{ alignItems: 'center', paddingRight: 2, paddingLeft: 2 }}>
						<Label text={padLeadingZeros(seconds, 2)} color={'black'} fontSize={16} />
					</div>
				</div>
			);
		}
	};

	return (
		<Modal
			className={classes.modal}
			open={openVideoScreen}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Slide in={openVideoScreen} timeout={500} direction="up">
				<div className={classes.wrapper}>
					<div className={classes.header}>
						<Button
							startIcon={<KeyboardBackspaceIcon style={{ fill: '#D6A76F' }} />}
							className={classes.button}
							onClick={() => {
								handleClose(false);
							}}
						>
							<Label text="TORNA" color="black" fontSize={14} />
						</Button>
						{timerIsReachedZero ? (
							<div className={classes.waitingLabel}>
								<Label fontSize={14} text={'Ancora qualche momento di attesa…'} color="black" />
							</div>
						) : (
							<Slide in={true} timeout={1400} mountOnEnter unmountOnExit>
								<Box
									boxShadow={2}
									className={classes.timerWrapper}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<PeopleIcon fontSize="medium" />
									<Label fontSize={20} text={props.persons} color="#000" isBold />
									<Label fontSize={20} text="|" color="#000" />
									{props.time && (
										<Countdown
											ref={timerRef}
											daysInHours={true}
											autoStart
											zeroPadTime={2}
											className={classes.timer}
											renderer={renderer}
											date={Date.now() + props.time * 1000}
											onComplete={() => setTimerIsReachedZero(true)}
											onPause={(time) => {
												props.onClose(time.total / 1000);
												setOpenVideoScreen(false);
											}}
										/>
									)}
								</Box>
							</Slide>
						)}
					</div>

					{opened && (
						<div className={classes.emptyView}>
							<iframe src={props.url} height="100%" width="100%" style={{ border: 0 }} />
						</div>
					)}
					{/* <div className={classes.footer}>
						<MainButton label="TORNA ALLA VIDEOCHIAMATA" onPress={() => handleClose()} />
					</div> */}
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {
		// backgroundColor: 'transparent',
		// justifyContent: 'center',
		// overflow: 'scroll',
		display: 'flex',
		// overflowX: 'hidden',
	},
	wrapper: {
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		outline: 'none',
		width: '100%',
	},
	header: {
		backgroundColor: '#ffffff',
		height: 100,
		flexDirection: 'row',
		borderBottom: '2px solid #D3D3D3',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	footer: {
		height: 100,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	timerWrapper: {
		width: '40%',
		height: 48,
		backgroundColor: '#ffffff',
		borderRadius: 25,
		paddingLeft: 28,
		paddingRight: 28,
		marginRight: 16,
		maxWidth: 200,
	},
	iframeView: {
		height: 'calc(100% - 60px)',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
	emptyView: {
		height: 'calc(100% - 100px)',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
	button: {
		backgroundColor: 'white',
		height: 48,
		marginLeft: 16,
	},
	timer: {
		color: '#00000',
		fontFamily: 'Noto Sans',
		fontSize: 20,
	},
	timer_over: {
		color: 'black',
		fontSize: 16,
	},
	timer_root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	waitingLabel: {
		marginRight: 6,
	},
}));
