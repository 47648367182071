import { useReducer } from "react";
import RegisterService from "../../api/RegisterService";
import QueueService from "../../api/QueueService";
import RecallService from "../../api/RecallService";
import StoreService from "../../api/StoreService";
import SendbirdService from "../../api/SendbirdService";
import LocaleStorageManager from "../../storage/LocalStorageManager";
import AppointmentService from "../../api/AppointmentService";
import FeedbackService from "../../api/FeedbackService";

let localStorageManager = new LocaleStorageManager();

const DAY_IN_MS = 1000 * 3600 * 24;

const initialState = {
  user: null,
  userId: null,
  currentStore: null,
  agentStore: null,
  getStoreLoading: null,
  queueIdProgress: false,
  queueID: null,
  queueError: "",
  appointment: null,
  getAppointmentLoading: false,
  getAppointmentError: false,
  agentID: "",
  homeFlag: false,
  feedbackSuccess: false,
  feedbackError: false,
  chatUrl: "",
  getStoreError: false,
  storeNotExist: false,
  clientUpdateError: false,
  clientUpdateSuccess: false,
  appointmentID: null,
  recallSucess: null,
  recallError: false,
  referralQueryParam: null,
  nameQueryParam: null,
  surnameQueryParam: null,
  phoneQueryParam: null,
  emailQueryParam: null,
  crmIdQueryParam: null,
  clientTypeQueryParam: null,
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        user: action.payload,
      };
    }
    case "REGISTER_USER_REQUEST": {
      return {
        ...state,
        isRegistering: true,
      };
    }
    case "REGISTER_USER_SUCCESS": {
      return {
        ...state,
        userId: action.payload,
        isRegistering: false,
      };
    }
    case "REGISTER_USER_ERROR": {
      return {
        ...state,
        isRegistering: false,
      };
    }
    case "GET_STORE_REQUEST": {
      return {
        ...state,
        getStoreLoading: true,
        getStoreError: false,
        storeNotExist: false,
      };
    }
    case "GET_STORE_SUCCESS": {
      return {
        ...state,
        currentStore: action.payload,
        getStoreLoading: false,
        getStoreError: false,
        storeNotExist: false,
      };
    }
    case "GET_STORE_ERROR": {
      return {
        ...state,
        getStoreLoading: false,
        getStoreError: true,
        storeNotExist: false,
      };
    }
    case "STORE_NO_EXIST": {
      return {
        ...state,
        getStoreLoading: false,
        getStoreError: false,
        storeNotExist: true,
      };
    }
    case "GET_AGENT_STORE_REQUEST": {
      return {
        ...state,
        getAgentStoreLoading: true,
      };
    }
    case "GET_AGENT_STORE_SUCCESS": {
      return {
        ...state,
        agentStore: action.payload,
        getAgentStoreLoading: false,
      };
    }
    case "GET_AGENT_STORE_ERROR": {
      return {
        ...state,
        getAgentStoreLoading: false,
      };
    }
    case "PING_SUCCESS": {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case "PING_ERROR": {
      return {
        ...state,
      };
    }
    case "QUEUE_SUCCESS": {
      return {
        ...state,
        queueID: action.payload,
        queueError: "",
        queueIdProgress: false,
      };
    }
    case "QUEUE_ERROR": {
      return {
        ...state,
        queueID: null,
        queueError: action.payload,
        queueIdProgress: false,
      };
    }
    case "QUEUE_REMOVE": {
      return {
        ...state,
        queueID: null,
        queueIdProgress: false,
      };
    }
    case "QUEUE_PROGRESS": {
      return {
        ...state,
        queueIdProgress: true,
      };
    }
    case "APPOINTMENT_SUCCESS": {
      return {
        ...state,
        appointment: action.payload,
        getAppointmentLoading: false,
        getAppointmentError: false,
      };
    }
    case "APPOINTMENT_LOADING": {
      return {
        ...state,
        getAppointmentLoading: true,
        getAppointmentError: false,
      };
    }
    case "APPOINTMENT_ERROR": {
      return {
        ...state,
        getAppointmentLoading: false,
        getAppointmentError: true,
      };
    }
    case "SET_AGENT_ID": {
      return {
        ...state,
        agentID: action.payload,
      };
    }
    case "HOME_FLAG": {
      return {
        ...state,
        homeFlag: action.payload,
      };
    }
    case "FEEDBACK_SUCCESS": {
      return {
        ...state,
        feedbackSuccess: true,
        feedbackError: false,
      };
    }
    case "FEEDBACK_ERROR": {
      return {
        ...state,
        feedbackSuccess: false,
        feedbackError: true,
      };
    }
    case "RECALL_SUCCESS": {
      return {
        ...state,
        recallSuccess: true,
        recallError: false,
      };
    }
    case "RECALL_ERROR": {
      return {
        ...state,
        recallSuccess: false,
        recallError: true,
      };
    }
    case "CHAT": {
      return {
        ...state,
        chatUrl: action.payload,
      };
    }
    case "CLIENT_UPDATE": {
      return {
        ...state,
        clientUpdateSuccess: true,
        clientUpdateError: false,
      };
    }
    case "CLIENT_UPDATE_ERROR": {
      return {
        ...state,
        clientUpdateSuccess: false,
        clientUpdateError: true,
      };
    }
    case "APPOINTMENT": {
      return {
        ...state,
        appointmentID: action.payload,
      };
    }
    case "SET_REFERRAL_QUERY": {
      return {
        ...state,
        referralQueryParam: action.payload,
      };
    }
    case "SET_NAME_QUERY": {
      return {
        ...state,
        nameQueryParam: action.payload,
      };
    }
    case "SET_SURNAME_QUERY": {
      return {
        ...state,
        surnameQueryParam: action.payload,
      };
    }
    case "SET_PHONE_QUERY": {
      return {
        ...state,
        phoneQueryParam: action.payload,
      };
    }
    case "SET_EMAIL_QUERY": {
      return {
        ...state,
        emailQueryParam: action.payload,
      };
    }
    case "SET_CRM_ID_QUERY": {
      return {
        ...state,
        crmIdQueryParam: action.payload,
      };
    }
    case "SET_CLIENT_TYPE_QUERY": {
      return {
        ...state,
        clientTypeQueryParam: action.payload,
      };
    }
  }
};

const useApp = ({ params = {}, data = {}, callback = {} }) => {
  const [state, dispatch] = useReducer(appReducer, {
    ...initialState,
  });

  const setUser = (user) => {
    dispatch({ type: "SET_USER", payload: user });
  };

  const registerUser = (request) => {
    dispatch({ type: "REGISTER_USER_REQUEST" });
    RegisterService.register(request)
      .then((response) => {
        // save in local storage
        localStorageManager.set("CLIENT", response.data.uuid);
        // localStorageManager.set('TOKEN_TIME', new Date().getTime());
        console.log("==== CLIENT  RESPONSE ====", response);
        dispatch({
          type: "REGISTER_USER_SUCCESS",
          payload: response.data.uuid,
        });
      })
      .catch((error) => {
        console.log("ERROR", { error });
        dispatch({ type: "REGISTER_USER_ERROR" });
      });
  };

  const getReservation = (id) => {
    dispatch({ type: "REGISTER_USER_REQUEST" });
    AppointmentService.getReservation(id)
      .then((response) => {
        console.log("==== RESERVATION  RESPONSE ====", response);
        localStorageManager.set("CLIENT", response.data.client_uuid);
        dispatch({
          type: "REGISTER_USER_SUCCESS",
          payload: response.data.client_uuid,
        });
        dispatch({ type: "QUEUE_SUCCESS", payload: response.data.queue_id });
        dispatch({ type: "APPOINTMENT", payload: id });
      })
      .catch((error) => {
        console.log("ERROR GET RESERVATION", { error });
        dispatch({ type: "REGISTER_USER_ERROR" });
      });
  };

  const checkIn = (id) => {
    QueueService.checkIn(id);
  };

  const getStoreById = (id) => {
    dispatch({ type: "GET_STORE_REQUEST" });
    StoreService.getStoreById(id)
      .then((response) => {
        console.log("==== GET STORE BY ID RESPONSE ====", response);
        dispatch({ type: "GET_STORE_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        console.log("ERROR GET STORE", { error });
        dispatch({ type: "GET_STORE_ERROR" });
      });
  };

  const getStoreBP_ByQuery = (slug) => {
    dispatch({ type: "GET_STORE_REQUEST" });
    StoreService.storesByQuery(slug)
      .then((response) => {
        console.log("==== GET STORE BY SLUG RESPONSE ====", response);
        if (response.data["hydra:member"].length > 0) {
          dispatch({
            type: "GET_STORE_SUCCESS",
            payload: response.data["hydra:member"][0],
          });
        } else {
          dispatch({ type: "STORE_NO_EXIST" });
        }
      })
      .catch((error) => {
        console.log("ERROR GET STORE_QUERY", { error });
        dispatch({ type: "GET_STORE_ERROR" });
      });
  };

  const getStoreBySlug = (slug) => {
    dispatch({ type: "GET_AGENT_STORE_REQUEST" });
    StoreService.getAgentStore(slug)
      .then((response) => {
        console.log("==== GET AGENT STORE RESPONSE ====", response);
        dispatch({ type: "GET_AGENT_STORE_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        console.log("ERROR GET STORE", { error });
        dispatch({ type: "GET_AGENT_STORE_ERROR" });
      });
  };

  const ping = (userId, location) => {
    RegisterService.ping(userId, location)
      .then((response) => {
        console.log("==== PING ====", response);
        //dispatch({ type: 'PING_SUCCESS', payload: response.data.client_uuid });
      })
      .catch((error) => {
        console.log({ error });
        dispatch({ type: "PING_ERROR" });
      });
  };
  const addToQueue = (client_uuid, store_uuid) => {
    dispatch({ type: "QUEUE_PROGRESS" });
    QueueService.addToQueue(client_uuid, store_uuid)
      .then((response) => {
        console.log("==== ADD TO QUEUE SUCCESS ====", response);
        localStorage.setItem("QUEUE_ID", response.data.id);
        dispatch({ type: "QUEUE_SUCCESS", payload: response.data.id });
      })
      .catch((error) => {
        console.log(`==== ADD TO QUEUE ERROR ====>`, error);
        dispatch({ type: "QUEUE_ERROR", payload: error.response.data.error });
      });
  };

  const removeFromQueue = () => {
    let queueId = localStorage.getItem("QUEUE_ID");
    console.log("state queue id", queueId);
    if (queueId) {
      QueueService.removeFromQueue(queueId)
        .then((response) => {
          console.log("==== REMOVE FROM QUEUE SUCCESS ====", response);
          //dispatch({ type: 'QUEUE_SUCCESS', payload: null });
        })
        .catch((error) => {
          console.log("==== REMOVE FROM QUEUE ERROR ====", error);
          // dispatch({ type: 'QUEUE_SUCCESS', payload: null });
        });
    }
  };
  const cancelQueue = () => {
    console.log("state queue id before cancel queue ===>", state.queueID);
    if (state.queueID) {
      QueueService.cancelQueue(state.queueID)
        .then((response) => {
          console.log("==== CANCEL QUEUE SUCCESS ====", response);
          //dispatch({ type: 'QUEUE_SUCCESS', payload: null });
        })
        .catch((error) => {
          console.log("==== CANCEL QUEUE ERROR ====", error);
          // dispatch({ type: 'QUEUE_SUCCESS', payload: null });
        });
    }
  };

  const subscribeToSendbird = () => {
    if (state.userId) {
      SendbirdService.subscribe(state.userId);
    }
  };

  const getAppointment = (id) => {
    dispatch({ type: "APPOINTMENT_LOADING" });
    AppointmentService.getAppointment(id)
      .then((response) => {
        console.log("==== APPOINTMENT SUCCESS ===", response);
        let appointment = response.data;
        dispatch({ type: "APPOINTMENT_SUCCESS", payload: appointment });
      })
      .catch((error) => {
        console.log("==== APPOINTMENT ERROR ===", error);
        dispatch({ type: "APPOINTMENT_ERROR" });
      });
  };

  const postFeedback = (feedback, message) => {
    // if (state.queueID) {
    return FeedbackService.postFeedback(feedback, message, state.queueID);
    // }
  };

  const postRecall = (phone, name, store_uuid, CRMclientId) => {
    RecallService.postRecall(phone, name, store_uuid, CRMclientId)
      .then((response) => {
        console.log("==== RECALL SUCCESS ===", response);
        dispatch({ type: "RECALL_SUCCESS", payload: true });
      })
      .catch((error) => {
        console.log("==== RECALL ERROR ===", error);
        dispatch({ type: "RECALL_ERROR", payload: false });
      });
  };

  const clientUpdate = (obj) => {
    FeedbackService.update(obj)
      .then((response) => {
        console.log("==== CLIENT UPDATE SUCCESS ===", response);
        dispatch({ type: "CLIENT_UPDATE" });
      })
      .catch((error) => {
        dispatch({ type: "CLIENT_UPDATE_ERROR" });
        console.log("==== CLIENT UPDATE ERROR ===", error);
      });
  };

  const saveCallID = (callID) => {
    if (state.queueID) {
      QueueService.saveCallID(state.queueID, callID)
        .then((response) => {
          console.log("==== SAVE CALL ID SUCCESS ===", response);
        })
        .catch((error) => {
          console.log("==== SAVE CALL ID ERRROR ===", error);
        });
    }
  };

  const setChatUrl = (channel) => {
    dispatch({ type: "CHAT", payload: channel });
  };

  const setAgentID = (id) => {
    dispatch({ type: "SET_AGENT_ID", payload: id });
  };

  const setHomeFlag = (value) => {
    localStorageManager.set("HOME_FLAG", value);
    dispatch({ type: "HOME_FLAG", payload: value });
  };

  const setReferralQueryParam = (value) => {
    dispatch({ type: "SET_REFERRAL_QUERY", payload: value });
  };

  const setNameQueryParam = (value) => {
    dispatch({ type: "SET_NAME_QUERY", payload: value });
  };

  const setSurnameQueryParam = (value) => {
    dispatch({ type: "SET_SURNAME_QUERY", payload: value });
  };

  const setPhoneQueryParam = (value) => {
    dispatch({ type: "SET_PHONE_QUERY", payload: value });
  };

  const setEmailQueryParam = (value) => {
    dispatch({ type: "SET_EMAIL_QUERY", payload: value });
  };

  const setCRMIdQueryParam = (value) => {
    dispatch({ type: "SET_CRM_ID_QUERY", payload: value });
  };

  const setClientTypeQueryParam = (value) => {
    dispatch({ type: "SET_CLIENT_TYPE_QUERY", payload: value });
  };

  return {
    ...state,
    setUser,
    registerUser,
    getStoreById,
    getStoreBP_ByQuery,
    getStoreBySlug,
    subscribeToSendbird,
    ping,
    addToQueue,
    removeFromQueue,
    getAppointment,
    setAgentID,
    setHomeFlag,
    postFeedback,
    setChatUrl,
    getReservation,
    clientUpdate,
    checkIn,
    postRecall,
    cancelQueue,
    saveCallID,
    setReferralQueryParam,
    setNameQueryParam,
    setSurnameQueryParam,
    setPhoneQueryParam,
    setEmailQueryParam,
    setCRMIdQueryParam,
    setClientTypeQueryParam,
  };
};

export default useApp;
