import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { ReactComponent as LeftArrowWhite } from '../../assets/arrow-left.svg';
import { ReactComponent as LeftArrow } from '../../assets/arrow-left-stale.svg';

FooterButton.propTypes = {
	label: PropTypes.string,
	onPress: PropTypes.func,
	disabled: PropTypes.bool,
	nonWhite: PropTypes.bool,
};

export default function FooterButton(props) {
	const classes = useStyles();

	return (
		<Button
			disabled={props.disabled}
			className={classes.root}
			onClick={() => props.onPress()}
			endIcon={props.nonWhite ? <LeftArrow /> : <LeftArrowWhite />}
			style={{ opacity: props.disabled ? 0 : 1, fontFamily: 'Iqos', color: props.nonWhite ? '#34303D' : 'white' }}
		>
			{props.label}
		</Button>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: 14,
		color: '#FFFFFF',
		fontFamily: 'Iqos',
		backgroundColor: 'transparent',
	},
	iconWhite: {
		fill: '#ffff',
	},
	iconStale: {
		fill: '#34303D',
	},
}));
