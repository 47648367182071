import React, { useEffect, useState, useMemo } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	CardMedia,
	Avatar,
	Button,
	Link,
	Typography,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import 'moment/locale/it'; // without this line it didn't work
import Moment from 'react-moment';

export default function FileMessage(props) {
	const classes = useStyles();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	// props
	const { message, userId, onDeleteMessage, onUpdateMessage, channel, sdk, sendUserMessage, sendFileMessage } = props;
	// console.log('props', props);

	// const uploadFile = (file, filename, callback) => {
	//   console.log('start of upload')
	//   // async magic goes here...
	//   if(file === '') {
	//     console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
	//   }
	//   const uploadTask = storage.ref(`/images/${filename}`).put(file)
	//   //initiates the firebase side uploading
	//   uploadTask.on('state_changed',
	//   (snapShot) => {
	//     //takes a snap shot of the process as it is happening
	//     //console.log(snapShot)
	//   }, (err) => {
	//     //catches the errors
	//     console.log(err)
	//   }, () => {

	//     // Create file metadata to update
	//     var newMetadata = {
	//       firebaseStorageDownloadTokens: null
	//     }

	//     // Update metadata properties
	//     storage.ref('images').child(filename).updateMetadata(newMetadata).then(function(metadata) {
	//       // Updated metadata for 'images/forest.jpg' is returned in the Promise
	//       storage.ref('images').child(filename).getDownloadURL()
	//       .then(fireBaseUrl => {
	//         //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
	//         console.log('Uploaded file '+fireBaseUrl);
	//         const fileUrl = "https://europe-west3-uominidonne-e5039.cloudfunctions.net/api/files/"+filename;
	//         console.log('Converted url '+fileUrl);

	//         callback(fileUrl, filename, file.size, file.type);
	//       })
	//     }).catch(function(error) {
	//       // Uh-oh, an error occurred!
	//     });
	//   })
	// }

	// const blobToFile = function(theBlob, fileName){
	//   //A Blob() is almost a File() - it's just missing the two properties below which we will add
	//   theBlob.lastModifiedDate = new Date();
	//   theBlob.name = fileName;
	//   return theBlob;
	// }

	const sendMessage = (origMessage, destChannel) => {
		console.log('Sending message to channel', destChannel);

		fetch(origMessage.url)
			.then((response) => response.blob())
			.then((blob) => {
				let filename = Math.random().toString(36);
				if (blob.type === 'image/jpeg') {
					filename += '.jpeg';
				} else if (blob.type === 'image/png') {
					filename += '.png';
				}

				const params = new sdk.FileMessageParams();
				params.message = origMessage.message;

				//blob = blobToFile(blob, filename);
				params.file = blob;
				params.data = JSON.stringify({ status: 'forwarded' });
				//params.fileUrl = URL.createObjectURL(blob);
				/*params.fileName = filename;
      params.fileSize = size;
      params.thumbnailSizes = [{maxWidth: 100, maxHeight: 100}, {maxWidth: 200, maxHeight: 200}]; // Add the maximum sizes of thumbnail images (allowed number of thumbnail images: 3).
      params.mimeType = filetype;
      params.customType = origMessage.customType;
      params.data = origMessage.data;
      params.pushNotificationDeliveryOption = 'default';*/

				console.log('File params', params);

				sendFileMessage(destChannel.url, params)
					.then((message) => {
						console.log('Message sent', message);

						// IF SENT
						// For a text message
						const params = new sdk.UserMessageParams();

						params.data = JSON.stringify({ status: 'forwarded' });

						// The MESSAGE_ID below indicates the unique message ID of a UserMessage object to update.
						console.log('Updating message status', origMessage.messageId);
						// destChannel.updateUserMessage(origMessage.messageId, params, function(message, error) {
						//     if (error) {
						//       console.error("Error", error);
						//       return;
						//     } else {
						//       console.log('Message updated', message);
						//     }
						// });
						const emojiKey = 'smile';
						destChannel.addReaction(origMessage, emojiKey, function (reactionEvent, error) {
							if (!error) {
								origMessage.applyReactionEvent(reactionEvent);
							}
						});
					})
					.catch((error) => {
						console.log(error);

						if (error instanceof TypeError) {
							console.log('Message error but ok', message);
							const emojiKey = 'smile';
							destChannel.addReaction(origMessage, emojiKey, function (reactionEvent, error) {
								if (!error) {
									origMessage.applyReactionEvent(reactionEvent);
								}
							});
						}
					});
			});
	};

	const onForwardMessage = (message) => {
		console.log('Message', message);
		console.log('Channel', channel);
		console.log('channel ' + message.sender.nickname + ' - nickname ' + channel.name);

		var channelListQuery = sdk.GroupChannel.createMyGroupChannelListQuery();
		channelListQuery.includeEmpty = true;
		channelListQuery.order = 'latest_last_message'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
		channelListQuery.limit = 1; // The value of pagination limit could be set up to 100.
		channelListQuery.channelNameContainsFilter = message.sender.nickname;
		channelListQuery.nicknameContainsFilter = channel.name;

		if (channelListQuery.hasNext) {
			channelListQuery.next(function (channelList, error) {
				if (error) {
					return;
				}

				console.log('CH', channelList);

				if (channelList) {
					const isForwarded =
						message.reactions !== null &&
						message.reactions.length > 0 &&
						message.reactions[0].key === 'smile';
					if (isForwarded) {
						if (window.confirm('Questo messaggio è stato già inoltrato, vuoi procedere ugualmente?')) {
							console.log('Sending message');
							sendMessage(message, channelList[0]);
						}
					} else {
						console.log('Sending message');
						sendMessage(message, channelList[0]);
					}
				}
			});
		}
	};

	let isForwarded =
		message.reactions !== null && message.reactions.length > 0 && message.reactions[0].key === 'smile';

	if (message.data) {
		let data = message.data;
		data = JSON.parse(data);
		if (data.status && data.status === 'forwarded') {
			isForwarded = true;
		}
	}

	const renderDeleteDialog = () => {
		return (
			<Dialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{''}</DialogTitle>
				<DialogContent style={{ width: 300 }}>
					<DialogContentText id="alert-dialog-description">
						Sei sicuro di voler eliminare questo messaggio?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDeleteDialog(false)}>
						<Typography>No</Typography>
					</Button>
					<Button onClick={() => onDeleteMessage(message)}>
						<Typography>SI</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const isMine = message.sender && message.sender.userId === userId;

	return (
		<div className={`file-message ${message.sender && message.sender.userId === userId ? 'mine' : ''}`}>
			<Card
				elevation={0}
				style={{
					backgroundColor: isMine ? '#414042' : 'white',
					borderRadius: 16,
				}}
			>
				{/* <CardHeader
					// avatar={
					//   message.sender && message.sender.userId === userId ? (
					//     <Avatar alt="Us" src={channel.coverUrl} />
					//   ) : (
					//     <Avatar src={message.sender.profileUrl}></Avatar>
					//   )
					// }
					title={message.sender && message.sender.userId === userId ? channel.name : message.sender.nickname}
					subheader={<Moment fromNow>{message.createdAt}</Moment>}
				/> */}

				<CardMedia
					className={classes.media}
					title="File thumbnail"
					image={message.thumbnails[0] || message.url}
				/>
				{/* <Link href={message.url} style={{ color: '#d6a76f' }}>
					<Typography style={{ color: isMine ? '#ffffff' : 'black', marginTop: 10, marginBottom: 10 }}>
						Download
					</Typography>
				</Link> */}

				{isMine ? (
					<CardActions>
						<Button
							className={classes.button}
							size="small"
							variant="outlined"
							elevation={0}
							onClick={() => setOpenDeleteDialog(true)}
						>
							Elimina
						</Button>
						<Button
							className={classes.button}
							size="small"
							variant="outlined"
							elevation={0}
							href={message.url}
						>
							Download
						</Button>
					</CardActions>
				) : (
					<CardActions>
						<Button
							className={classes.downloadButton}
							size="small"
							variant="outlined"
							elevation={0}
							href={message.url}
						>
							Download
						</Button>
					</CardActions>
				)}
				{/* {message.sender && message.sender.userId !== userId && (
					<CardActions>
						<Button size="small" variant="outlined" elevation={0} onClick={() => onForwardMessage(message)}>
							Inoltra
						</Button>
						{isForwarded && <CheckIcon style={{ color: '#D02C2A' }} />}
					</CardActions>
				)} */}
			</Card>
			{renderDeleteDialog()}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	media: {
		height: '100%',
		width: '100%',
		paddingTop: '56.25%',
	},
	button: {
		color: 'white',
		marginLeft: 10,
		marginBottom: 16,
		borderWidth: '1px',
		borderColor: 'white',
		fontWeight: 'bold',
	},
	downloadButton: {
		color: 'black',
		marginLeft: 10,
		marginBottom: 16,
		borderWidth: '1px',
		borderColor: 'black',
		fontWeight: 'bold',
	},
}));
