import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label, LogoHeader } from '../components';
import Slide from '@material-ui/core/Slide';
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useUiModalsContext, useAppContext } from '../redux/context/ContextFactory';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalStorageManager from '../storage/LocalStorageManager';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from '../assets/ss_logo.svg';
import GAManager from '../analytics/GAManager';
let ga = new GAManager();

class LocalizedUtils extends MomentUtils {
	format(date, formatString) {
		return date.format(formatString === 'MMM' || formatString === 'MMMM' ? 'MMMM' : 'YYYY');
	}
	getYearRange(start, end) {
		const startDate = this.moment(end).startOf('year');
		const endDate = this.moment(start).endOf('year');
		const years = [];
		let current = startDate;
		while (current.isAfter(endDate)) {
			years.push(current);
			current = current.clone().subtract(1, 'year');
		}
		return years;
	}
}

let localStorageManager = new LocalStorageManager();

function useQuery() {
	return new URLSearchParams(window.location.search);
}

AgeGateScreen.propTypes = {};

export default function AgeGateScreen(props) {
	const classes = useStyles();
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [isLegal, setIsLegal] = useState(null);
	const [validMonth, setValidMonth] = useState();
	const [validYear, setValidYear] = useState();
	const history = useHistory();
	const query = useQuery();
	const matches = useMediaQuery('(min-width:600px)');

	useEffect(() => {
		ga.trackEvent(
			GAManager.CATEGORY.age_verification,
			GAManager.ACTION.display,
			GAManager.IDs.ageVerification,
			true
		);
		let agegate = query.get('age_verified');
		let appointmentID = query.get('rid');
		console.log('agegate', agegate);
		if (parseInt(agegate) == 1) {
			localStorageManager.set(LocalStorageManager.LEGAL, moment().unix());
			if (appointmentID) {
				history.replace(window.location.pathname + '?rid=' + appointmentID);
			} else {
				history.replace(window.location.pathname);
			}
			props.onSuccess();
		}
	}, []);

	useEffect(() => {
		if (isLegal) {
			localStorageManager.set(LocalStorageManager.LEGAL, moment().unix());
			let appointmentID = query.get('rid');
			if (appointmentID) {
				history.replace(window.location.pathname + '?rid=' + appointmentID);
			} else {
				history.replace(window.location.pathname);
			}
			props.onSuccess();
		}
	}, [isLegal]);

	const handleMonthChange = (date) => {
		console.log('MONTH', date);
		document.activeElement.blur();
		setSelectedMonth(date);
	};
	const handleYearChange = (date) => {
		console.log('YEAR', date);
		setSelectedYear(date);
	};

	const apply = () => {
		var date = moment.utc();
		if (selectedYear && selectedMonth) {
			date.set('year', selectedYear.format('YYYY'));
			date.set('month', selectedMonth.format('MMMM'));
			const age = moment().diff(date, 'years');
			const isVerified = age >= 18;
			if (isVerified) {
				ga.trackEvent(
					GAManager.CATEGORY.age_verification,
					GAManager.ACTION.confirmedAge,
					GAManager.IDs.ageConfirmed
				);
			} else {
				ga.trackEvent(
					GAManager.CATEGORY.age_verification,
					GAManager.ACTION.notConfirmedAge,
					GAManager.IDs.notConfirmedAge
				);
			}
			setIsLegal(isVerified);
		} else {
			if (selectedYear === null) {
				setValidYear(false);
			}
			if (selectedMonth === null) {
				setValidMonth(false);
			}
		}
	};

	const introLabel = () => {
		if (isLegal === false) {
			return `Questo sito è destinato solo a maggiorenni fumatori o utilizzatori di altri prodotti contenenti nicotina`;
		}
		return `Inserisci mese e anno di nascita per confermare di essere un maggiorenne fumatore o utilizzatore di altri prodotti contenenti nicotina.`;
	};

	return (
		<MuiPickersUtilsProvider utils={LocalizedUtils} libInstance={moment}>
			<div className={classes.root}>
				<div className={classes.wrapper} style={{ justifyContent: !matches ? null : 'center' }}>
					{/* <LogoHeader isGray /> */}
					<img src={logo} style={{ marginBottom: 16, marginTop: !matches ? 40 : null }} />
					<div>
						<Label text={introLabel()} isBold fontSize={14} color="#404040" />
					</div>
					<div className={classes.pickersView} style={{ display: isLegal === false ? 'none' : null }}>
						<DatePicker
							style={{ display: isLegal === false ? 'none' : null }}
							views={['month']}
							autoOk
							error={validMonth === false}
							disableToolbar
							variant="inline"
							label="Mese"
							value={selectedMonth}
							format="MMMM"
							onFocus={() => {
								setIsLegal(null);
							}}
							onChange={(date) => handleMonthChange(moment(date))}
							onSelect={(e) => {
								e.target.blur();
								setValidMonth(true);
							}}
							inputVariant="outlined"
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutlineForm,
									input: classes.notchedOutlineForm,
								},
								endAdornment: <ExpandMoreIcon style={{ fill: '#404040' }} />,
							}}
							InputLabelProps={{
								style: { color: '#404040', fontFamily: 'Iqos' },
							}}
							PopoverProps={{
								anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
								transformOrigin: { horizontal: -2 },
							}}
						/>
						<div style={{ width: 6 }} />
						<DatePicker
							style={{ display: isLegal === false ? 'none' : null }}
							views={['year']}
							label="Anno"
							autoOk
							variant="inline"
							error={validYear === false}
							disableFuture
							disableToolbar
							maxDate={moment()}
							format="YYYY"
							value={selectedYear}
							onSelect={(e) => {
								e.target.blur();
								setValidYear(true);
							}}
							onFocus={() => {
								setIsLegal(null);
							}}
							onChange={(date) => handleYearChange(moment(date))}
							inputVariant="outlined"
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutlineForm,
									input: classes.notchedOutlineForm,
								},
								endAdornment: <ExpandMoreIcon style={{ fill: '#404040' }} />,
							}}
							InputLabelProps={{
								style: { color: '#404040', fontFamily: 'Iqos' },
							}}
							PopoverProps={{
								anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
								transformOrigin: { horizontal: -2 },
							}}
						/>
					</div>
					<Button
						style={{ display: isLegal === false ? 'none' : null }}
						fullWidth
						disabled={props.disabled}
						className={classes.confirmButton}
						variant="contained"
						onClick={() => apply()}
					>
						CONFERMA
					</Button>
					<div className={classes.descriptionView}>
						<Typography
							className={classes.titleDescription}
							style={{ textAlign: !matches ? 'left' : 'center' }}
						>
							We care
						</Typography>
						<p className={classes.description} style={{ textAlign: !matches ? 'left' : 'center' }}>
							Questo sito contiene informazioni sui nostri prodotti da utilizzare con tabacco e abbiamo
							bisogno della tua età per accertarci che tu sia un maggiorenne in Italia che continuerebbe a
							fumare o a usare prodotti contenenti nicotina. I nostri prodotti da usare con appositi stick
							di tabacco non sono un'alternativa allo smettere e non sono concepiti come strumenti di
							supporto alla cessazione. Non sono privi di rischi. Gli stick di tabacco contengono
							nicotina, sostanza che provoca dipendenza. Uso riservato a soli adulti. Per maggiori
							informazioni visita la sezione Cosa Sapere di questo sito.
						</p>
					</div>
				</div>
			</div>
		</MuiPickersUtilsProvider>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		height: '100%',
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		outline: 'none',
		width: '90%',
		maxWidth: 400,
		backgroundColor: 'white',
		position: 'relative',
	},
	titleWrapper: {
		// marginTop: 100,
	},
	pickersView: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		height: 100,
		marginBottom: 6,
	},
	notchedOutlineForm: {
		borderWidth: '1px',
		fontFamily: 'Iqos',
		borderColor: '#404040',
		color: '#404040',
		'&::placeholder': {
			color: '#404040',
		},
		textTransform: 'capitalize',
	},
	descriptionView: {
		marginTop: 20,
	},
	description: {
		fontSize: 12,
		color: '#404040',
		fontFamily: 'Iqos',
	},
	titleDescription: {
		fontSize: 12,
		color: '#404040',
		fontFamily: 'IqosBold',
	},
	errorLabelView: {
		height: 20,
		marginTop: 6,
	},
	confirmButton: {
		width: 150,
		height: 44,
		fontFamily: 'Iqos',
		height: 42,
		fontWeight: 'bold',
		letterSpacing: 0.5,
		backgroundColor: '#34303d',
		borderRadius: 24,
		color: '#fffdfb',
		lineHeight: 1.5,
	},
}));
