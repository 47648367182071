import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

Label.propTypes = {
	text: PropTypes.string,
	fontSize: PropTypes.number,
	isBold: PropTypes.bool,
	color: PropTypes.string,
	isHidden: PropTypes.bool,
	letterSpacing: PropTypes.number,
	isItalic: PropTypes.bool,
	align: PropTypes.bool,
};

export default function Label(props) {
	const classes = useStyles();
	return (
		<Typography
			style={{
				fontSize: props.fontSize,
				fontFamily: props.isBold ? 'IqosBold' : 'Iqos',
				fontStyle: props.isItalic ? 'italic' : 'normal',
				color: props.color,
				display: props.isHidden ? 'none' : 'block',
				letterSpacing: props.letterSpacing ? props.letterSpacing : 0,
				textAlign: props.align ? 'left' : '',
			}}
			className={classes.root}
		>
			{props.text}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Iqos',
		textAlign: 'center',
	},
}));
