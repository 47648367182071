import Lottie from 'lottie-react';
import phoneRinging from './phone_ringing.json';
import lottieFeedback from './star.json';
import phoneBackground from './phone_anim_back.json';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';

const FeedbackAnimation = () => {
	return (
		<Lottie
			style={{ backgroundColor: 'transparent', width: 160, height: 160, position: 'relative' }}
			animationData={lottieFeedback}
			loop={3}
		></Lottie>
	);
};

export default FeedbackAnimation;
