import React, { useEffect, useRef } from "react";
import { PRODUCTION } from "../api/config";
import LocalStorageManager from "../storage/LocalStorageManager";

let localStorageManager = new LocalStorageManager();
const BP_PROD_BASE_URL =
  "https://iqos-widget-prod.d3inyt16c4mcj0.amplifyapp.com";
const BP_DEV_BASE_URL = "https://iqos-widget.d3inyt16c4mcj0.amplifyapp.com";

const useWindowUnloadEffect = (handler, callOnCleanup) => {
  const cb = useRef();

  cb.current = handler;

  useEffect(() => {
    const handler = () => cb.current();

    window.addEventListener("beforeunload", handler);

    return () => {
      if (callOnCleanup) handler();

      window.removeEventListener("beforeunload", handler);
    };
  }, [cb]);
};

const bpVideoLink = (
  storeSlug,
  referralQuery,
  nameQuery,
  surnameQuery,
  phoneQuery,
  emailQuery,
  crmIdQuery,
  clientTypeQuery
) => {
  let baseUrl = PRODUCTION
    ? `${BP_PROD_BASE_URL}/shop/${storeSlug}?sv=videocall`
    : `${BP_DEV_BASE_URL}/shop/${storeSlug}?sv=videocall`;
  if (referralQuery) {
    baseUrl = baseUrl + "&ref=" + referralQuery;
  }
  if (nameQuery) {
    baseUrl = baseUrl + "&bcf=" + nameQuery;
  }
  if (surnameQuery) {
    baseUrl = baseUrl + "&bcl=" + surnameQuery;
  }
  if (phoneQuery) {
    baseUrl = baseUrl + "&bcp=" + phoneQuery;
  }
  if (emailQuery) {
    baseUrl = baseUrl + "&bce=" + emailQuery;
  }
  if (crmIdQuery) {
    baseUrl = baseUrl + "&bcid=" + crmIdQuery;
  }
  if (clientTypeQuery) {
    baseUrl = baseUrl + "&bct=" + clientTypeQuery;
  }
  return baseUrl;
};

const bpAcquistoLink = (
  storeSlug,
  referralQuery,
  nameQuery,
  surnameQuery,
  phoneQuery,
  emailQuery,
  crmIdQuery,
  clientTypeQuery
) => {
  let baseUrl = PRODUCTION
    ? `${BP_PROD_BASE_URL}/shop/${storeSlug}?sv=acquisto`
    : `${BP_DEV_BASE_URL}/shop/${storeSlug}?sv=acquisto`;
  if (referralQuery) {
    baseUrl = baseUrl + "&bref=" + referralQuery;
  }
  if (nameQuery) {
    baseUrl = baseUrl + "&bcf=" + nameQuery;
  }
  if (surnameQuery) {
    baseUrl = baseUrl + "&bcl=" + surnameQuery;
  }
  if (phoneQuery) {
    baseUrl = baseUrl + "&bcp=" + phoneQuery;
  }
  if (emailQuery) {
    baseUrl = baseUrl + "&bce=" + emailQuery;
  }
  if (crmIdQuery) {
    baseUrl = baseUrl + "&bcid=" + crmIdQuery;
  }
  if (clientTypeQuery) {
    baseUrl = baseUrl + "&bct=" + clientTypeQuery;
  }
  return baseUrl;
};

const bpEditLink = (id) => {
  if (PRODUCTION) {
    return `https://iqos-widget-prod.d3inyt16c4mcj0.amplifyapp.com/appointments/${id}`;
  }
  return `https://iqos-widget.d3inyt16c4mcj0.amplifyapp.com/appointments/${id}`;
};

const onlineConfiguratorLink = () => {
  return `https://online-configuratore.iqositalia.it/configurator?iframe=1&product=DK001005.01`;
};

const blogLink = () => {
  return `https://it.iqos.com/it/news`;
};

const manifestHref = (slug) => {
  if (PRODUCTION) {
    return `https://services-vs.iqositalia.it/manifest/${slug}/site.webmanifest`;
  }
  return `https://services-stag-vs.iqositalia.it/manifest/${slug}/site.webmanifest`;
};

const pubnubConfig = () => {
  if (PRODUCTION) {
    return {
      publishKey: "pub-c-3704882a-4cc8-422e-87c9-dac420a0618c",
      subscribeKey: "sub-c-e3024924-2ceb-11e3-89a9-02ee2ddab7fe",
    };
  }
  return {
    publishKey: "pub-c-95c31a00-074c-49f1-bbb5-8785aa34e2c7",
    subscribeKey: "sub-c-0e6b7018-2cec-11e3-89a9-02ee2ddab7fe",
  };
};

export {
  useWindowUnloadEffect,
  bpAcquistoLink,
  bpEditLink,
  bpVideoLink,
  onlineConfiguratorLink,
  manifestHref,
  pubnubConfig,
  blogLink,
};
