import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label, LabelHeader, MainButton } from '../components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import PeopleIcon from '@material-ui/icons/People';
import { Box, IconButton } from '@material-ui/core';
import Countdown from 'react-countdown';
import { useUiModalsContext, useAppContext } from '../redux/context/ContextFactory';
import { SendBirdProvider, ChannelList, ChannelSettings, Channel, App } from 'sendbird-uikit';
import SendBird from 'sendbird';
import 'sendbird-uikit/dist/index.css';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import './ChatScreen.css';
import 'moment/locale/it';
import CustomizeMessageItem from './chat/CustomizeMessageItem';
import GAManager from '../analytics/GAManager';
moment.locale('it');

let ga = new GAManager();
const APP_ID_TEST_PROJECT = '1A2F3370-1790-4A3D-A152-6ED0E6CA528C';

var sb = new SendBird({ appId: APP_ID_TEST_PROJECT });

const myColorSet = {
	'--sendbird-dark-primary-100': '#e6caa9',
	'--sendbird-dark-primary-200': '#34303D',
	'--sendbird-dark-primary-300': '#deb98c',
	'--sendbird-dark-primary-400': '#dab07d',
	'--sendbird-dark-primary-500': '#FFFDFB',
	'--sendbird-dark-background-600': '#FFFDFB',
	'--sendbird-dark-background-500': '#ecedf1',
	'--sendbird-add-reaction-button-border-hover': '#d6a76f',
	'--sendbird-selected-reaction-button-border-hover': '#d6a76f',
	'--sendbird-message-input-border-active': '#00D1D2',
	'--sendbird-light-primary-400': '#ffffff',
	'--sendbird-light-primary-500': '#ffffff',
	'--sendbird-dark-ondark-01': '#34303D',
};
const stringSet = {
	CHANNEL__MESSAGE_INPUT__PLACE_HOLDER: 'Inserisci il messaggio',
	TYPING_INDICATOR__IS_TYPING: 'sta scrivendo...',
	MESSAGE_STATUS__SENDING_FAILED: `Impossibile inviare il messaggio`,
	MESSAGE_EDITED: '(modificato)',
	TRYING_TO_CONNECT: 'Tentativo di connessione...',
	MESSAGE_STATUS__TRY_AGAIN: 'Riprova',
	MODAL__DELETE_MESSAGE__TITLE: 'Vuoi eliminare questo messaggio?',
	BUTTON__CANCEL: 'Annulla',
	BUTTON__DELETE: 'Elimina',
	BUTTON__SAVE: 'Salva',
	BUTTON__CREATE: 'Crea',
	BUTTON__CLOSE: 'Chiudi',
};

ChatScreen.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	store: PropTypes.object,
	onMessageReceived: PropTypes.func,
};

// ga.trackEvent(
// 	GAManager.CATEGORY.shop_streaming,
// 	GAManager.ACTION.clickCTA,
// 	GAManager.IDs.sendMessage,
// 	true
// );

export default function ChatScreen(props) {
	const classes = useStyles();
	const { userId, agentStore, agentID, chatUrl } = useAppContext();
	const [groupChannelUrl, setGroupChannelUrl] = useState('');
	const [innerHeight, setInnerHeight] = useState(window.innerHeight);
	var channelHandler = new sb.ChannelHandler();

	var textField = document.getElementsByClassName('sendbird-message-input--textarea');

	useEffect(() => {
		if (props.open) {
			ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.impressionCTA, GAManager.IDs.chat, true);
			// if (textField[0]) {
			// 	textField[0].addEventListener('ochange', function () {
			// 		console.log('mare field', textField);
			// 		var buttons = document.getElementsByClassName('sendbird-iconbutton sendbird-message-input--send');
			// 		if (buttons[0]) {
			// 			buttons[0].addEventListener('click', function () {
			// 				clickSend();
			// 			});
			// 		}
			// 	});
			// }
		}
	}, [props.open]);

	const clickSend = () => {
		ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.clickCTA, GAManager.IDs.sendMessage, true);
	};

	useEffect(() => {
		window.addEventListener('resize', function () {
			console.log('inner height', window.innerHeight);
			setInnerHeight(window.innerHeight);
		});
		setGroupChannelUrl(chatUrl);
		return () => {};
	}, [chatUrl]);

	channelHandler.onMessageReceived = function (channel, message) {
		console.log('message', message, 'channel', channel);
		if (!props.open) {
			console.log('UNREAD MESSAGES', channel.unreadMessageCount);
			props.onMessageReceived();
		} else {
			channel.markAsRead();
		}
	};
	sb.addChannelHandler('HANDLE_MESSAGE', channelHandler);

	const handleClose = () => {
		ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.clickCTA, GAManager.IDs.close_chat);
		props.onClose();
	};

	const chatHeader = () => {
		return (
			<Box className={classes.header} display="flex" alignItems="center" justifyContent="space-between">
				<Label text={props.store ? props.store.name : ''} color="#34303D" isBold fontSize={16} />
				<IconButton className={classes.icon} onClick={() => handleClose()}>
					<CloseIcon style={{ fill: '#34303D' }} />
				</IconButton>
			</Box>
		);
	};

	return (
		<SendBirdProvider
			appId={APP_ID_TEST_PROJECT}
			userId={userId}
			theme="dark"
			colorSet={myColorSet}
			stringSet={stringSet}
		>
			<Modal
				className={classes.modal}
				open={props.open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				keepMounted
			>
				<div className={classes.wrapper} style={{ height: innerHeight }}>
					<div className={classes.headerWrapper}>{chatHeader()}</div>
					{groupChannelUrl != '' && props.open && (
						<Channel
							backgroundColor={'red'}
							renderChatHeader={() => <div></div>}
							channelUrl={groupChannelUrl}
							onBeforeSendUserMessage={(text) => {
								clickSend();
								const params = new sb.UserMessageParams();
								params.message = text;
								params.data = null;
								return params;
							}}
							renderChatItem={({ message, onDeleteMessage, onUpdateMessage }) => (
								<CustomizeMessageItem
									message={message}
									onDeleteMessage={onDeleteMessage}
									onUpdateMessage={onUpdateMessage}
									userId={userId}
									channel={groupChannelUrl}
								/>
							)}
						/>
					)}
				</div>
			</Modal>
		</SendBirdProvider>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {
		backgroundColor: 'transparent',
		outline: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	wrapper: {
		height: '100%',
		width: '100%',
		outline: 'none',
		position: 'relative',
		paddingBottom: 60,
	},
	headerWrapper: {
		position: '-webkit-sticky',
		position: 'sticky',
		left: 0,
		right: 0,
		top: 0,
		zIndex: 100,
	},
	channelView: {
		backgroundColor: 'transparent',
		margin: 20,
	},
	header: {
		height: 50,
		width: '100%',
		paddingLeft: 20,
		backgroundColor: '#FFFDFB',
	},
	icon: {
		marginRight: 20,
	},
}));
