import React from 'react';
import './ShadeBackground.css';

function ShadeBackground(props) {
	return (
		<div className="shade-container-wrapper">
			<div className="content">{props.children}</div>
		</div>
	);
}

export default ShadeBackground;
