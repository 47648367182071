import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useUiModalsContext, useAppContext } from '../../redux/context/ContextFactory';

export default function ActionDialog(props) {
	const handleClose = () => {
		props.onCancel();
	};

	const handleOk = () => {
		props.onConfirm();
	};

	const title = () => {
		if (props.activeCall) {
			return 'Sei sicuro di voler interrompere la videochiamata?';
		} else {
			return 'Sei sicuro di voler uscire?';
		}
	};

	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{'IQOS'}</DialogTitle>
			<DialogContent>
				<DialogContentText style={{ fontFamily: 'Iqos' }} id="alert-dialog-description">
					{title()}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} style={{ color: '#fffff', fontFamily: 'Iqos' }}>
					No
				</Button>
				<Button style={{ color: '#fffff', fontFamily: 'Iqos' }} onClick={handleOk}>
					Si
				</Button>
			</DialogActions>
		</Dialog>
	);
}
