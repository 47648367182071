import { ContextFactory, CONTEXTS } from '../../redux/context/ContextFactory';
import useUiModals from '../../redux/hooks/useUiModals';
import StoreListScreen from '../../screens/stores/StoreListScreen';
import { TimerSnack } from '../';
import AlertDialog from '../dialogs/AlertDialog';
import ExitScreen from '../../screens/ExitScreen';
import BPView from '../../screens/BPView';

const AppModals = ({ children }) => {
	const { ContextProvider } = ContextFactory(CONTEXTS.UI_MODALS);
	const uiModalsHookObj = useUiModals({});
	const contextData = {
		...uiModalsHookObj,
	};
	return (
		<ContextProvider data={contextData}>
			{children}
			<StoreListScreen />
			<TimerSnack />
			<AlertDialog />
			<ExitScreen />
			<BPView />
		</ContextProvider>
	);
};

export default AppModals;
