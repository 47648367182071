export default {
	register: 'clients',
	ping: 'clients/ping',
	queues: 'queues',
	storeBySlug: (slug) => `stores/${slug}`,
	getStores: 'stores?all=true&pagination=false&serviceName=Videocall',
	storeById: (id) => `stores/${id}`,
	storesByQuery: (slug) => `stores?all=true&pagination=false&slug=${slug}`,
	removeQueue: (id) => `queues/${id}`,
	appointmentById: (id) => `appointments/${id}`,
	feedback: (id) => `queues/${id}/feedback`,
	reservationById: (id) => `queues/reservation/${id}`,
	clientsUpdate: 'clients/update',
	checkIn: 'queues/reservation/check-in',
	recall: '/recalls',
	cancelQueue: (id) => `queues/${id}/notagentupdate`,
	saveCallID: (queueID) => `/queues/${queueID}`,
};
