import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label } from '../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import PeopleIcon from '@material-ui/icons/People';
import { Box, Button, IconButton } from '@material-ui/core';
import Countdown from 'react-countdown';
import { useUiModalsContext, useAppContext } from '../redux/context/ContextFactory';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

BPView.propTypes = {
	onClose: PropTypes.func,
};

export default function BPView(props) {
	const classes = useStyles();
	const { openBPView, setOpenBPView } = useUiModalsContext();
	const [opened, setOpened] = useState(false);
	const { agentStore } = useAppContext();
	const iqosIframe = useRef(null);

	useEffect(() => {
		if (openBPView) {
			setTimeout(() => {
				setOpened(true);
			}, 1200);
		}
	}, [openBPView]);

	const addIframeListener = () => {
		console.log('mare iframe', iqosIframe);
		// iqosIframe.addEventListener('navigateToConfigurator', function () {
		// 	console.log('onMessageReceivedFromIframe');
		// });
		window.addEventListener('navigateToConfigurator', function () {
			console.log('onMessageReceivedFromIframe');
		});
	};

	// const onMessageReceivedFromIframe = (event) => {
	// 	console.log('onMessageReceivedFromIframe', event);
	// };

	const handleClose = () => {
		setOpened(false);
		setOpenBPView(false, '');
	};

	// console.log('bp link', openBPView.link);

	return (
		<Modal
			className={classes.modal}
			open={openBPView.open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Slide in={openBPView.open} direction="up" timeout={500} mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					{openBPView.noHeader != true && (
						<div className={classes.header}>
							<IconButton className={classes.backButton} onClick={() => handleClose()}>
								<KeyboardBackspaceIcon style={{ fill: '#34303D', marginRight: 4 }} />
								<Label fontSize={18} text="Torna indietro" color="#34303D" />
							</IconButton>
						</div>
					)}
					{opened && (
						<div
							className={classes.iframeView}
							style={{ height: openBPView.noHeader === true ? '100%' : 'calc(100% - 60px)' }}
						>
							<iframe
								ref={iqosIframe}
								id="iqos-iframe"
								src={openBPView.link}
								height="100%"
								width="100%"
								style={{ outline: 'none', border: 0 }}
								onLoad={(e) => addIframeListener()}
							/>
						</div>
					)}
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {
		// justifyContent: 'center',
		// overflow: 'scroll',
		// display: 'flex',
		// overflowX: 'hidden',
	},
	wrapper: {
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		outline: 'none',
		width: '100%',
	},
	header: {
		backgroundColor: '#ffffff',
		height: 60,
		flexDirection: 'row',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: '2px solid #D3D3D3',
	},
	iframeView: {
		// height: 'calc(100% - 60px)',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
	button: {
		backgroundColor: 'white',
		height: 48,
		marginLeft: 16,
	},
}));
