import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/Star';

export default function RatingView(props) {
	return (
		<div>
			<Box
				style={{ width: '100%', height: '100%', marginBottom: 20 }}
				display="flex"
				alignItems="flex-start"
				justifyContent="center"
			>
				<Rating
					name="customized-empty"
					defaultValue={props.value}
					onChange={(event, newValue) => {
						props.onChangeValue(newValue);
					}}
					precision={0.5}
					size="large"
					icon={<StarIcon style={{ width: 38, height: 38, fill: '#D6A76F' }} />}
					emptyIcon={<StarBorderIcon fontSize="inherit" style={{ width: 38, height: 38, fill: '#D6A76F' }} />}
				/>
			</Box>
		</div>
	);
}
