import SendBirdCall, { SoundType } from "sendbird-calls";
import { useEffect, useState } from "react";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Prompt,
  useHistory,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { MuiThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import "./App.css";
import {
  HomeScreen,
  ConnectScreen,
  AgeGateScreen,
  StoresScreen,
} from "./screens";
import { AppModals } from "./components";
import { theme } from "./theme/AppTheme";
import DialingSound from "./assets/sound/Dialing.mp3";
import RingingSound from "./assets/sound/Ringing.mp3";
import ReconnectingSound from "./assets/sound/Reconnecting.mp3";
import ReconnectedSound from "./assets/sound/Reconnected.mp3";
import useApp from "./redux/hooks/useApp";
import { ContextFactory, CONTEXTS } from "./redux/context/ContextFactory";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";
import { isMobile } from "react-device-detect";
import LocalStorageManager from "./storage/LocalStorageManager";
import { SettingsInputAntennaTwoTone } from "@material-ui/icons";
import { useWindowUnloadEffect } from "./utils/helpers";
import moment from "moment";
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import { pubnubConfig } from "./utils/helpers";

let localStorageManager = new LocalStorageManager();

const APP_ID = "1A2F3370-1790-4A3D-A152-6ED0E6CA528C";
const random_string = Math.random().toString(36).slice(-8);

const pubnub = new PubNub({
  publishKey: pubnubConfig().publishKey,
  subscribeKey: pubnubConfig().subscribeKey,
  uuid: "shop_stream_app" + random_string,
});

function useQuery() {
  return new URLSearchParams(window.location.search);
}

const RedirectPage = () => {
  useEffect(() => {
    setTimeout(function () {
      window.location.href = "https://it.iqos.com";
    }, 3000);
  }, []);
  return (
    <p style={{ backgroundColor: "white" }}>
      Reindirizzamento a https://it.iqos.com ...
    </p>
  );
};

const hasLegalExpired = () => {
  let storageTimestamp = moment.unix(
    localStorageManager.get(LocalStorageManager.LEGAL)
  );
  var currentTimestamp = moment();
  let diff = currentTimestamp.diff(storageTimestamp, "days");
  return diff > 7;
};

const App = () => {
  const { ContextProvider } = ContextFactory(CONTEXTS.APP);
  const [isLegal, setIsLegal] = useState(!hasLegalExpired());
  const history = useHistory();
  const appHookObj = useApp({});
  const query = useQuery();
  const contextData = {
    ...appHookObj,
  };

  const {
    registerUser,
    subscribeToSendbird,
    userId,
    getStoreBP_ByQuery,
    getStoreBySlug,
    getAppointment,
    homeFlag,
    getReservation,
    removeFromQueue,
    setReferralQueryParam,
    setNameQueryParam,
    setSurnameQueryParam,
    setPhoneQueryParam,
    setEmailQueryParam,
    setCRMIdQueryParam,
    setClientTypeQueryParam,
  } = appHookObj;

  useEffect((_) => {
    SendBirdCall.init(APP_ID);
    SendBirdCall.addDirectCallSound(SoundType.DIALING, DialingSound);
    SendBirdCall.addDirectCallSound(SoundType.RINGING, RingingSound);
    SendBirdCall.addDirectCallSound(SoundType.RECONNECTING, ReconnectingSound);
    SendBirdCall.addDirectCallSound(SoundType.RECONNECTED, ReconnectedSound);
  }, []);

  useEffect(() => {
    console.log(isLegal);
    if (isLegal) {
      proceed();
    }
  }, [isLegal]);

  useEffect(() => {
    subscribeToSendbird();
  }, [userId]);

  const proceed = () => {
    let appointmentID = query.get("rid");

    // this queries need to be append to the iframe links in helpers.js only for bpVideoLink and bpAcquistoLink
    let referral = query.get("bref");
    let name = query.get("bcf");
    let surname = query.get("bcl");
    let phone = query.get("bcp");
    let email = query.get("bce");
    let crmClientId = query.get("bcid");
    let clientType = query.get("bct");

    let bcfe = query.get("bcfe");
    let bcle = query.get("bcle");
    let bcpe = query.get("bcpe");
    let bcee = query.get("bcee");

    var base64Name = bcfe ? Buffer.from(bcfe, "base64").toString() : "";
    var base64Surname = bcle ? Buffer.from(bcle, "base64").toString() : "";
    var base64Phone = bcpe ? Buffer.from(bcpe, "base64").toString() : "";
    var base64Email = bcee ? Buffer.from(bcee, "base64").toString() : "";

    setReferralQueryParam(referral);
    setNameQueryParam(name);
    setSurnameQueryParam(surname);
    setPhoneQueryParam(phone);
    setEmailQueryParam(email);
    setCRMIdQueryParam(crmClientId);
    setClientTypeQueryParam(clientType);

    if (base64Email != "") {
      setEmailQueryParam(base64Email);
    }
    if (base64Name != "") {
      setNameQueryParam(base64Name);
    }
    if (base64Surname != "") {
      setSurnameQueryParam(base64Surname);
    }
    if (base64Phone != "") {
      setPhoneQueryParam(base64Phone);
    }

    getStoreBP_ByQuery(window.location.pathname.split("/")[2]);
    getStoreBySlug(window.location.pathname.split("/")[2]);
    if (appointmentID) {
      console.log("appointment id in url", appointmentID);
      getAppointment(appointmentID);
      getReservation(appointmentID);
    } else {
      let request = {
        CRMClientType: clientType ? clientType.toUpperCase() : clientType,
        CRMClientID: crmClientId,
        name: base64Name != "" ? base64Name : name,
        surname: base64Surname != "" ? base64Surname : surname,
        email: base64Email != "" ? base64Email : email,
        phone: base64Phone != "" ? base64Phone : phone,
        referral,
      };
      registerUser(request);
    }
  };

  return (
    <PubNubProvider client={pubnub}>
      <ContextProvider data={contextData}>
        <MuiThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <ReactPWAInstallProvider>
                <AppModals>
                  <div className={"background"}></div>
                  <div className={isMobile ? "App" : "App-desktop"}>
                    {isLegal ? (
                      <Router>
                        <Switch>
                          <Redirect
                            from="/:url*(/+)"
                            to={window.location.pathname.slice(0, -1)}
                          />
                          <Route exact path="/" component={StoresScreen} />
                          <Route path="/s/:slug/connect">
                            {homeFlag ? (
                              <ConnectScreen></ConnectScreen>
                            ) : (
                              <Redirect
                                to={
                                  "/s/" + window.location.pathname.split("/")[2]
                                }
                              ></Redirect>
                            )}
                          </Route>
                          <Route path="/s/:slug" component={HomeScreen} />
                          <Route path="*">
                            {/* <div style={{ backgroundColor: 'white' }}>404 Not Found</div> */}
                          </Route>
                        </Switch>
                      </Router>
                    ) : (
                      <Router>
                        <Route exact path="/">
                          <AgeGateScreen onSuccess={() => setIsLegal(true)} />
                        </Route>
                        <Route path="/s/:slug">
                          <AgeGateScreen onSuccess={() => setIsLegal(true)} />
                        </Route>
                        <Route path="*">
                          {/* <div style={{ backgroundColor: 'white' }}>404 Not Found</div> */}
                        </Route>
                      </Router>
                    )}
                  </div>
                </AppModals>
              </ReactPWAInstallProvider>
            </SnackbarProvider>
          </I18nextProvider>
        </MuiThemeProvider>
      </ContextProvider>
    </PubNubProvider>
  );
};

export default App;
