import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

SubHeadlabel.propTypes = {
	text: PropTypes.string,
	color: PropTypes.string,
};

export default function SubHeadlabel(props) {
	const classes = useStyles();
	const matches = useMediaQuery('(min-height:670px)');

	return (
		<Typography
			className={classes.root}
			style={{ fontSize: matches ? 18 : 14, color: props.color ? props.color : '#FFFFFFFF' }}
		>
			{props.text}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: 14,
		color: '#FFFFFFFF',
		fontFamily: 'Iqos',
		paddingLeft: 10,
		paddingRight: 10,
	},
}));
