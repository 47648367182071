import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CallButton, LabelHeader, CountdownTimer, Label, RecallButton } from '../components';
import { useUiModalsContext, useAppContext } from '../redux/context/ContextFactory';
import { Box, Fade, Button } from '@material-ui/core';
import { VideoScreen } from '../screens';
import RecallClientModal from '../screens/RecallClientModal';
import footerImg from '../assets/blog-bannerBG.png';
import WarningDialog from '../components/dialogs/WarningDialog';
import { onlineConfiguratorLink, blogLink } from '../utils/helpers';
import BookingDialog from '../components/dialogs/BookingDialog';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditEventButton from '../components/buttons/EditEventButton';
import GAManager from '../analytics/GAManager';
import useMediaQuery from '@material-ui/core/useMediaQuery';

let ga = new GAManager();

OnHoldScreen.propTypes = {
	message: PropTypes.object,
	onEndCall: PropTypes.func,
};

export default function OnHoldScreen(props) {
	const classes = useStyles();
	const { removeFromQueue, agentStore, appointmentID, appointment } = useAppContext();
	const { openVideoScreen, setOpenVideoScreen, openTimerSnack, setOpenBPView } = useUiModalsContext();
	const [timerIsReachedZero, setTimerIsReachedZero] = useState(false);
	const [tempTimerValue, setTempTimerValue] = useState(null);
	const [videoScreenUrl, setVideoScrenUrl] = useState(onlineConfiguratorLink());
	const [openRecallModal, setOpenRecallModal] = useState(false);
	const [openBookingDialog, setOpenBookingDialog] = useState(false);
	const timerRef = useRef(null);
	const [warningDialog, setWarningDialog] = useState({ open: false, type: 'recall' });
	const styles = 'body { height: unset !important }';
	const matches = useMediaQuery('(min-height:670px)');

	useEffect(() => {
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.recallOnHold,
			true
		);
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.endCallOnHold,
			true
		);
		ga.trackEvent(
			GAManager.CATEGORY.shop_streaming,
			GAManager.ACTION.impressionCTA,
			GAManager.IDs.bookOnHold,
			true
		);
		// ga.trackEvent(
		// 	GAManager.CATEGORY.shop_streaming,
		// 	GAManager.ACTION.impressionCTA,
		// 	GAManager.IDs.personalizeOnHold,
		// 	true
		// );
	}, []);

	useEffect(() => {
		console.log('on hold', props.message);
		setTempTimerValue(props.message.waitingTime);
		return () => {};
	}, [props.message]);

	useEffect(() => {
		if (openVideoScreen) {
			timerRef.current.pause();
		}
	}, [openVideoScreen]);

	const getTime = () => {
		if (tempTimerValue === null && !timerIsReachedZero) {
			return props.message.waitingTime;
		}
		if (tempTimerValue !== null && !timerIsReachedZero) {
			return tempTimerValue;
		}
	};

	const cancelCall = () => {
		ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.clickCTA, GAManager.IDs.endCallOnHold);
		removeFromQueue();
		props.onEndCall();
	};

	const onCloseVideo = (time) => {
		console.log('received time', time);
		setTempTimerValue(time);
		setTimeout(() => timerRef.current.start(), 300);
	};

	const onCloseRecallModal = (time) => {
		setOpenRecallModal(false);
		// setTempTimerValue(time);
		// setTimeout(() => timerRef.current.start(), 300);
	};

	const personLabel = (queues) => {
		if (appointment && appointmentID) {
			let appointmentTime = moment(appointment.startTime).format('HH:mm');
			return (
				<div>
					<Label fontSize={18} text={'Il tuo appuntamento è alle '} color="#34303D" isBold />
					<Label fontSize={18} text={appointmentTime} color="#34303D" />
				</div>
			);
		}
		if (queues === 0 && props.message.onlineAgents > 0) {
			return <Label fontSize={18} text={'Ancora qualche momento di attesa…'} color="#34303D" isBold />;
		}
		if (queues === 1) {
			return <Label fontSize={18} text={`C’è una persona in coda`} color="#34303D" isBold />;
		}
		if (queues > 1) {
			return <Label fontSize={18} text={`Ci sono ${queues} persone in coda`} color="#34303D" isBold />;
		}
		return <Label fontSize={18} text={`Non ci sonno persone in coda`} color="#34303D" isBold />;
	};

	const handleOpenModal = (type) => {
		removeFromQueue();
		// setWarningDialog({ type: '', open: false });
		if (type === 'recall') {
			setOpenRecallModal(true);
		}
		if (type === 'book') {
			// setOpenBpView(true);
			props.onEndCall();
		}
	};

	return (
		<div className={`hold-screen ${classes.screen_wrapper}`}>
			<style>{styles}</style>
			<div className={classes.content_wrapper}>
				<LabelHeader isWhite />
				<Fade in={true} timeout={800}>
					<div className={classes.contentBox}>
						<Box
							className={classes.personsLabelBox}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							{timerIsReachedZero ? (
								<div style={{ height: 60 }}>
									<Label fontSize={15} text={'Ancora qualche momento di attesa…'} color="#34303D" />
								</div>
							) : (
								personLabel(props.message.queues)
							)}
						</Box>

						<div className={classes.countdownTimerView}>
							{props.message && props.message.waitingTime > 0 ? (
								<Label
									fontSize={15}
									text={'Tempo stimato di attesa: '}
									color="#34303D"
									isHidden={timerIsReachedZero}
									letterSpacing={1.62}
								/>
							) : (
								<div style={{ height: 100 }} />
							)}

							{props.message && props.message.queues > 0 && appointmentID === null && (
								<CountdownTimer
									ref={timerRef}
									fontLabelSize={14}
									fontTimerSize={32}
									waitingTime={getTime()}
									onComplete={() => setTimerIsReachedZero(true)}
									onPaused={(time) => {
										setTempTimerValue(time.total / 1000);
									}}
								/>
							)}
							{props.message && appointmentID && (
								<CountdownTimer
									ref={timerRef}
									fontLabelSize={14}
									fontTimerSize={32}
									waitingTime={getTime()}
									onComplete={() => setTimerIsReachedZero(true)}
									onPaused={(time) => {
										setTempTimerValue(time.total / 1000);
									}}
								/>
							)}
						</div>
						<Label text="L’attesa è lunga?" fontSize={18} color="#34303D" isBold />
						<div style={{ maxWidth: '80%', marginTop: 8 }}>
							<Label
								text="Lasciaci il tuo numero di telefono e ti richiameremo noi entro un’ora, oppure prenota un appuntamento!"
								fontSize={16}
								color="#34303D"
							/>
							<Label
								text={'Il servizio di spedizione è attivo dal lunedì al venerdì'}
								fontSize={14}
								color="#34303D"
								isBold
								isItalic
							/>
						</div>
						<Button
							disabled={openTimerSnack}
							className={classes.button}
							style={{ fontSize: matches ? 16 : 14 }}
							variant="outlined"
							onClick={() => {
								ga.trackEvent(
									GAManager.CATEGORY.shop_streaming,
									GAManager.ACTION.clickCTA,
									GAManager.IDs.recallOnHold
								);
								setWarningDialog({ type: 'recall', open: true });
							}}
						>
							Vorrei essere richiamato
						</Button>
						<Button
							disabled={openTimerSnack}
							className={classes.bookingButton}
							style={{ fontSize: matches ? 16 : 14 }}
							onClick={() => {
								ga.trackEvent(
									GAManager.CATEGORY.shop_streaming,
									GAManager.ACTION.clickCTA,
									GAManager.IDs.bookOnHold
								);
								if (timerRef) {
									timerRef.current.pause();
								}
								setOpenBookingDialog(true);
							}}
						>
							Prenota un appuntamento
						</Button>

						<CallButton
							isLoading={openTimerSnack}
							status={'call'}
							darkText
							onHang={() => {
								ga.trackEvent(
									GAManager.CATEGORY.shop_streaming,
									GAManager.ACTION.clickCTA,
									GAManager.IDs.endCallOnHold
								);
								cancelCall();
							}}
						/>
					</div>
				</Fade>

				{/* <div className={classes.footerWrapper}>
					<img src={footerImg} className={classes.footerImage} />
					<div className={classes.personalizeContent}>
						<Label align text="SCOPRI IL BLOG IQOS" fontSize={22} color="black" isBold />
						<Label
							text="Aggiornamenti e news sul mondo IQOS e molto altro."
							fontSize={12}
							color="gray"
							align
						/>
					</div>
					<div style={{ position: 'absolute', left: 0, bottom: 0, right: 0 }}>
						<Button
							disabled={props.disabled}
							className={classes.personalizeButton}
							variant="contained"
							onClick={() => {
								// ga.trackEvent(
								// 	GAManager.CATEGORY.shop_streaming,
								// 	GAManager.ACTION.clickCTA,
								// 	GAManager.IDs.personalizeOnHold
								// );
								setVideoScrenUrl(blogLink());
								setOpenVideoScreen(true);
							}}
						>
							VISITA IL BLOG
						</Button>
					</div>
				</div> */}
			</div>

			<VideoScreen
				onClose={(time) => onCloseVideo(time)}
				time={tempTimerValue}
				persons={props.message.queues}
				url={videoScreenUrl}
			/>
			<RecallClientModal
				open={openRecallModal}
				onClose={(time) => onCloseRecallModal(time)}
				time={tempTimerValue}
				persons={props.message.queues}
				noHeader
			/>
			<WarningDialog
				type={warningDialog.type}
				open={warningDialog.open}
				onClose={(type) => handleOpenModal(type)}
				onCancel={() => {
					setTimeout(() => timerRef.current.start(), 300);
					setWarningDialog({ type: '', open: false });
				}}
			/>
			<BookingDialog
				open={openBookingDialog}
				onClose={() => {
					setTimeout(() => timerRef.current.start(), 300);
					setOpenBookingDialog(false);
				}}
				onOpenBP={(link) => {
					removeFromQueue();
					setOpenBookingDialog(false);
					setOpenBPView(true, link, true);
				}}
			/>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	screen_wrapper: {
		position: 'relative',
		width: '100vw',
		// minheight: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	content_wrapper: {
		//justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		paddingLeft: 12,
		paddingRight: 12,
	},
	bookingButton: {
		width: 290,
		fontFamily: 'IqosBold',
		height: 42,
		letterSpacing: 1.92,
	},
	button_wrapper: {
		marginTop: 26,
	},
	store_label: {
		marginBottom: 20,
	},
	contentBox: {
		// width: '94%',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		maxWidth: 400,
		paddingBottom: 16,
		borderRadius: 4,
		marginBottom: 20,
	},
	timerLabel: {
		fontSize: 18,
		color: '#ffffff',
		zIndex: 2000,
	},
	button: {
		fontFamily: 'Iqos',
		height: 48,
		letterSpacing: 0.5,
		width: 260,
		borderRadius: 24,
		borderColor: '#34303D',
		borderWidth: 2,
		marginTop: 32,
	},
	bookingButton: {
		backgroundColor: '#34303D',
		color: '#ffffff',
		fontFamily: 'Iqos',
		height: 42,
		letterSpacing: 0.5,
		width: 260,
		borderRadius: 24,
		marginTop: 16,
		'&:hover': {
			backgroundColor: '#34303D',
		},
	},
	infoLabel: {
		fontSize: 14,
		textAlign: 'center',
		color: '#ffffff',
		marginBottom: 18,
		paddingLeft: 20,
		paddingRight: 20,
	},
	footerWrapper: {
		backgroundColor: 'white',
		display: 'flex',
		position: 'relative',
		marginTop: 16,
		maxWidth: 400,
		// paddingTop: 16,
		// paddingBottom: 16,
		borderRadius: 4,
		marginBottom: 12,
		width: '100%',
	},
	personalizeContent: {
		position: 'absolute',
		left: 16,
		top: 16,
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
	},
	footerImage: {
		objectFit: 'cover',
		width: '100%',
		height: '100%',
	},
	countdownTimerView: {
		marginTop: 20,
		zIndex: 100,
	},
	personsLabelBox: {
		width: '80%',
		marginTop: 38,
		borderBottomColor: '#454545',
	},
	personalizeButton: {
		width: 290,
		fontFamily: 'Noto Sans',
		height: 42,
		fontWeight: 'bold',
		letterSpacing: 1.92,
		color: 'black',
		backgroundColor: 'white',
		marginBottom: 20,
	},
}));
