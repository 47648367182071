import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { StoreLabelSmall, Loader } from '../../components';
import { ListItemIcon, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Button from '@material-ui/core/Button';
import useStores from '../../redux/hooks/useStores';
import { ContextFactory, CONTEXTS, useAppContext } from '../../redux/context/ContextFactory';
import GAManager from '../../analytics/GAManager';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

let ga = new GAManager();

export default function StoresList() {
	const classes = useStyles();
	const { ContextProvider } = ContextFactory(CONTEXTS.STORES);
	const appHookObj = useStores();
	const contextData = {
		...appHookObj,
	};
	const { stores, getStores, getStoresLoading } = appHookObj;
	const { agentStore } = useAppContext();

	useEffect(() => {
		// document.getElementById('ot-sdk-btn').style.display = 'none';
		getStores();
	}, []);

	const onPressStore = (slug) => {
		if (agentStore && agentStore.isOpened) {
			ga.trackEvent(
				GAManager.CATEGORY.shop_streaming,
				GAManager.ACTION.clickCTA,
				GAManager.IDs.changeStorePrecall
			);
		} else {
			ga.trackEvent(
				GAManager.CATEGORY.shop_streaming,
				GAManager.ACTION.clickCTA,
				GAManager.IDs.changeStoreShopClosed
			);
		}
		window.location.href = `/s/${slug}`;
	};

	const renderRow = (item, index) => {
		const { name, address, slug } = item;
		return (
			<div key={`${index}-div`}>
				<Button
					style={{ backgroundColor: index % 2 == 0 ? '#F6F4F0' : '#FFFDFB' }}
					fullWidth
					onClick={() => console.log('click')}
					key={`${index}-button`}
				>
					<ListItem className={classes.item} key={`${index}-item`} onClick={() => onPressStore(slug)}>
						<StoreLabelSmall name={name} address={address} key={`${index}-label`} />
						<ListItemIcon key={`${index}-icon`}>
							<ArrowForwardIosIcon
								key={`${index}-flat-icon`}
								fontSize="small"
								style={{ fill: '#34303D', marginLeft: 50 }}
							/>
						</ListItemIcon>
					</ListItem>
				</Button>
				{/* <Divider light classes={{ root: classes.divider }} /> */}
			</div>
		);
	};

	if (!stores || (stores.length === 0 && !getStoresLoading)) {
		return (
			<div className={classes.root}>
				<Typography className={classes.name}>{'Nessun negozio trovato!'}</Typography>
			</div>
		);
	}

	return (
		<ContextProvider contextData={contextData}>
			<div className={classes.root}>
				{getStoresLoading ? <Loader /> : <List>{stores.map((item, index) => renderRow(item, index))}</List>}
			</div>
		</ContextProvider>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 10,
		width: '100%',
		height: '100%',
		overflow: 'auto',
		backgroundColor: '#FFFDFB',
		borderRadius: 0,
	},
	divider: {
		background: '#DCDCDC',
		opacity: 0.4,
	},
	item: {
		paddingLeft: 20,
		justifyContent: 'space-between',
	},
	name: {
		fontSize: 18,
		marginTop: 100,
		color: '#34303D',
		fontWeight: 700,
		fontFamily: 'Iqos',
		textAlign: 'center',
	},
}));
