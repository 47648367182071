import React, { useState, useEffect } from 'react';
import { Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import opacity from '../../assets/opacity.png';
import { Label } from '../../components';
import BookingDialog from '../../components/dialogs/BookingDialog';
import { useAppContext, useUiModalsContext } from '../../redux/context/ContextFactory';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GAManager from '../../analytics/GAManager';
moment.locale('it');

let ga = new GAManager();

export default function AppointmentDialog(props) {
	const classes = useStyles();

	const { agentStore, appointment, checkIn } = useAppContext();
	const { setOpenBPView } = useUiModalsContext();
	const [openBookingDialog, setOpenBookingDialog] = useState(false);
	const matches = useMediaQuery('(min-height:670px)');

	const handleOnCancel = () => {
		window.location.href = `/s/${agentStore.slug}`;
	};
	const openBP = () => {
		//window.location.href = `https://iqos-widget.d3inyt16c4mcj0.amplifyapp.com/shop/${agentStore.slug}?sv=videocall`;
		setOpenBookingDialog(true);
	};

	const appointmentExpired = () => {
		let startTime = moment(appointment.startTime);
		let now = moment(new Date());
		let duration = moment.duration(now.diff(startTime));
		console.log('appontment duration', duration.asMinutes());
		return duration.asMinutes() > 30;
	};

	const renderTitleLabel = () => {
		let appointmentDate = moment(appointment.startTime).format('dddd, D MMMM');
		let appointmentTime = moment(appointment.startTime).format('HH:mm');
		if (appointmentExpired()) {
			return `Il tuo appuntamento era previsto per ${appointmentDate} alle ore ${appointmentTime}`;
		} else {
			return `${appointmentDate} alle ${appointmentTime}`;
		}
	};

	const handlePressEntra = () => {
		props.onPress();
	};

	const appointmentLink = () => {
		//need to create link with appointment id ????
		return `https://iqos-widget.d3inyt16c4mcj0.amplifyapp.com/shop/${agentStore.slug}?sv=videocall`;
	};

	const renderButtonsView = () => {
		if (appointmentExpired()) {
			return (
				<div style={{ width: '100%' }}>
					<Button
						fullWidth
						className={classes.button}
						style={{ fontSize: matches ? 14 : 12 }}
						variant="contained"
						onClick={() => openBP()}
					>
						PRENOTA UN APPUNTAMENTO
					</Button>
					<Button
						fullWidth
						className={classes.cancelButton}
						variant="outlined"
						onClick={() => handleOnCancel()}
					>
						NO, GRAZIE
					</Button>
				</div>
			);
		} else {
			return (
				<div style={{ width: '100%' }}>
					<Button fullWidth className={classes.button} variant="contained" onClick={() => handlePressEntra()}>
						ENTRA
					</Button>
				</div>
			);
		}
	};

	return (
		<div className={classes.root_wrapper}>
			<div className={classes.shade_wrapper}>
				<img className={classes.opacity_img} src={opacity}></img>
				<div className={classes.labels_wrapper}>
					{!appointmentExpired() && (
						<div>
							<Label text={'Il tuo appuntamento '} fontSize={18} color="#ffffff" isBold />
							<div style={{ height: 10 }} />
						</div>
					)}
					<Label text={renderTitleLabel()} fontSize={18} color="#ffffff" isBold />
					{/* <div style={{ height: 8 }} />
					<Label text={'You can edit appointment here'} fontSize={16} color="#ffffff" />
					<Link onClick={() => console.log('click edit')}>click here</Link> */}
				</div>
			</div>
			{renderButtonsView()}
			<BookingDialog
				open={openBookingDialog}
				onClose={() => setOpenBookingDialog(false)}
				onOpenBP={(link) => {
					//close dialog and open iframe
					setOpenBookingDialog(false);
					setOpenBPView(true, link);
				}}
			/>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root_wrapper: {
		backgroundColor: 'transparent',
		marginTop: 8,
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: 300,
		width: '90%',
	},
	shade_wrapper: {
		width: '100%',
		height: 125,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		flexDirection: 'column',
		paddingTop: 20,
		paddingBottom: 20,
		paddingRight: 10,
		paddingLeft: 10,
	},
	labels_wrapper: {
		zIndex: 100,
		padding: 10,
	},
	opacity_img: {
		position: 'absolute',
		width: '100%',
		top: 0,
		height: '100%',
		objectFit: 'cover',
		borderRadius: 4,
		maxWidth: 300,
	},
	button: {
		marginTop: 20,
		fontFamily: 'IqosBold',
		height: 42,
		letterSpacing: 1.92,
	},
	cancelButton: {
		marginTop: 10,
		borderWidth: '1px',
		borderColor: 'white',
		color: 'white',
		fontSize: 12,
		fontFamily: 'Iqos',
		height: 36,
	},
}));
