import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label, LabelHeader, MainButton } from '../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useUiModalsContext } from '../redux/context/ContextFactory';
import { TextField, Box, Button } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

ExitScreen.propTypes = {};

export default function ExitScreen(props) {
	const classes = useStyles();
	const { setOpenExitScreen, openExitScreen } = useUiModalsContext();

	const handleClose = () => {
		setOpenExitScreen(false);
	};
	return (
		<Modal
			className={classes.modal}
			open={openExitScreen}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Slide in={openExitScreen} timeout={500} direction="up" mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<LabelHeader />
					<Label
						isBold
						fontSize={16}
						color={'#ffffff'}
						text={'Ci dispiace che tu abbia abbandonato la videochiamata.'}
					/>
					<Box className={classes.formBox}>
						<Label
							isBold
							fontSize={14}
							color={'#ffffff'}
							text={'Lasciaci i tuoi dati così potremo contattarti in caso di novità o aggiornamenti.'}
						/>
						<TextField
							className={classes.field}
							fullWidth
							label="Nome"
							variant="outlined"
							color="primary"
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutline,
									input: classes.notchedOutline,
								},
							}}
							InputLabelProps={{
								style: { color: '#fff' },
							}}
						/>
						<TextField
							className={classes.field}
							fullWidth
							label="Congome"
							variant="outlined"
							color="primary"
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutline,
									input: classes.notchedOutline,
								},
							}}
							InputLabelProps={{
								style: { color: '#fff' },
							}}
						/>
						<TextField
							className={classes.field}
							fullWidth
							label="Email"
							variant="outlined"
							color="primary"
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutline,
									input: classes.notchedOutline,
								},
							}}
							InputLabelProps={{
								style: { color: '#fff' },
							}}
						/>
						<TextField
							className={classes.field}
							fullWidth
							label="Telefono"
							variant="outlined"
							color="primary"
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutline,
									input: classes.notchedOutline,
								},
							}}
							InputLabelProps={{
								style: { color: '#fff' },
							}}
						/>
						<div className={classes.buttonWrapper}>
							<Button
								fullWidth
								disabled={props.disabled}
								className={classes.button}
								variant="contained"
								onClick={() => {
									setOpenExitScreen(false);
								}}
							>
								INVIA
							</Button>
						</div>
					</Box>
					<div className={classes.footer}>
						<Box className={classes.bookingBox}>
							<EventAvailableIcon style={{ fill: 'white' }} fontSize="large" />
							<Label fontSize={18} text="Vuoi prenotare un appuntamento?" color="#ffffff" />
							<Button
								fullWidth
								disabled={props.disabled}
								className={classes.button}
								variant="contained"
								onClick={() => {
									setOpenExitScreen(false);
								}}
							>
								PRENOTA ORA
							</Button>
						</Box>
					</div>
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: 'white',
		color: 'white',
		'&::placeholder': {
			color: 'white',
		},
	},
	wrapper: {
		backgroundColor: '#202020',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},
	field: {
		marginTop: 16,
		color: 'white',
	},
	button: {
		backgroundColor: 'white',
		height: 42,
	},
	formBox: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: '#414042',
		padding: 20,
		marginTop: 20,
	},
	buttonWrapper: {
		marginTop: 16,
		width: '100%',
	},
	footer: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		paddingTop: 16,
		width: '100%',
	},
	bookingBox: {
		backgroundColor: '#fffff',
		height: 160,
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 20,
		paddingRight: 20,
	},
}));
