import { useReducer } from 'react';
import Endpoints from '../../api/Endpoints';
import StoreService from '../../api/StoreService';

const initialState = {
	stores: [],
	getStoresLoading: false,
	getStoresSuccess: false,
	getStoresError: false,
};

const makeStoresReducer = (state, action) => {
	switch (action.type) {
		case 'GET_STORES_LOADING': {
			return {
				...state,
				getStoresLoading: true,
				getStoresSuccess: false,
				getStoresError: false,
			};
		}
		case 'GET_STORES_ERRROR': {
			return {
				...state,
				getStoresLoading: false,
				getStoresSuccess: false,
				getStoresError: true,
			};
		}
		case 'GET_STORES_SUCCESS': {
			const stores = action.payload;
			return {
				...state,
				getStoresLoading: false,
				getStoresSuccess: true,
				getStoresError: false,
				stores,
			};
		}
	}
};

const useStores = () => {
	const [state, dispatch] = useReducer(makeStoresReducer, {
		...initialState,
	});

	const getStores = () => {
		dispatch({ type: 'GET_STORES_LOADING' });
		StoreService.getStores()
			.then((response) => {
				dispatch({ type: 'GET_STORES_SUCCESS', payload: response.data['hydra:member'] });
			})
			.catch((error) => {
				dispatch({ type: 'GET_STORES_ERROR', payload: error });
			});
	};

	return {
		...state,
		getStores,
	};
};

export default useStores;
