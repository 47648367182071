import { useReducer } from 'react';
const initialState = {
	openStoreList: false,
	openVideoScreen: false,
	openTimerSnack: false,
	openAlertDialog: false,
	openExitScreen: false,
	openBPView: { open: false, link: '' },
};

const makeUireducer = (state, action) => {
	switch (action.type) {
		case 'OPEN_STORE_LIST': {
			return {
				...state,
				openStoreList: action.payload,
			};
		}
		case 'OPEN_VIDEO_SCREEN': {
			return {
				...state,
				openVideoScreen: action.payload,
			};
		}
		case 'OPEN_TIMER_SNACK': {
			return {
				...state,
				openTimerSnack: action.payload,
			};
		}
		case 'OPEN_ALERT_DIALOG': {
			return {
				...state,
				openAlertDialog: action.payload,
			};
		}
		case 'OPEN_EXIT_SCREEN': {
			return {
				...state,
				openExitScreen: action.payload,
			};
		}
		case 'OPEN_BP_VIEW': {
			return {
				...state,
				openBPView: action.payload,
			};
		}
	}
};

const useUiModals = () => {
	const [state, dispatch] = useReducer(makeUireducer, {
		...initialState,
	});

	const setOpenStoreList = (open) => {
		dispatch({ type: 'OPEN_STORE_LIST', payload: open });
	};

	const setOpenVideoScreen = (open) => {
		dispatch({ type: 'OPEN_VIDEO_SCREEN', payload: open });
	};

	const setOpenTimerSnack = (open) => {
		dispatch({ type: 'OPEN_TIMER_SNACK', payload: open });
	};

	const setOpenAlertDialog = (open, type) => {
		dispatch({ type: 'OPEN_ALERT_DIALOG', payload: { open, type } });
	};

	const setOpenExitScreen = (open) => {
		dispatch({ type: 'OPEN_EXIT_SCREEN', payload: open });
	};
	const setOpenBPView = (open, link, noHeader) => {
		console.log('use link', link);
		dispatch({ type: 'OPEN_BP_VIEW', payload: { open, link, noHeader } });
	};
	return {
		...state,
		setOpenStoreList,
		setOpenTimerSnack,
		setOpenVideoScreen,
		setOpenAlertDialog,
		setOpenExitScreen,
		setOpenBPView,
	};
};

export default useUiModals;
