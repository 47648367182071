import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function WarningDialog(props) {
	const handleCancel = () => {
		props.onCancel();
	};
	const handleClose = () => {
		props.onClose(props.type);
	};

	const renderContent = () => {
		if (props.type === 'recall') {
			return 'Chiudendo perderai il tuo turno. Sicuro di voler interrompere la chiamata?';
		}
		if (props.type === 'book') {
			return 'Chiudendo perderai il tuo turno. Sicuro di voler interrompere la chiamata?';
		}
		return '';
	};

	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{/* <DialogTitle id="alert-dialog-title">{'IQOS'}</DialogTitle> */}
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{renderContent()}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button style={{ color: '#fffff' }} onClick={handleCancel}>
					Annulla
				</Button>
				<Button style={{ color: '#fffff' }} onClick={handleClose}>
					Conferma
				</Button>
			</DialogActions>
		</Dialog>
	);
}
