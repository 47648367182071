import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label, BackgroundImage } from '../components';
import Slide from '@material-ui/core/Slide';
import { Button, Modal } from '@material-ui/core';
import { useUiModalsContext } from '../redux/context/ContextFactory';
import opacity from '../assets/opacity.png';
import Backdrop from '@material-ui/core/Backdrop';
import bg from '../assets/home-bg-desktop.png';

//screen if store not exist or is unvisible for the moment

export default function NoStoreScreen(props) {
	const classes = useStyles();
	const { setOpenStoreList } = useUiModalsContext();

	return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Slide in={props.open} direction="up" timeout={500} mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<div className={classes.shade_wrapper}>
						<div className={classes.labels_wrapper}>
							<Label text={'Il negozio non esiste'} fontSize={18} color="#404040" isBold />
						</div>
					</div>
					<Button
						className={classes.button}
						fullWidth
						disabled={false}
						variant="contained"
						onClick={() => {
							setOpenStoreList(true);
						}}
					>
						CAMBIA NEGOZIO
					</Button>
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	wrapper: {
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		outline: 'none',
		justifyContent: 'center',
	},
	button: {
		marginTop: 20,
		fontSize: 12,
		width: 150,
		height: 44,
		fontFamily: 'Iqos',
		height: 42,
		fontWeight: 'bold',
		letterSpacing: 0.5,
		backgroundColor: '#34303d',
		borderRadius: 24,
		color: '#fffdfb',
		lineHeight: 1.5,
	},
	shade_wrapper: {
		width: 280,
		height: 125,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		flexDirection: 'column',
		paddingTop: 20,
		paddingBottom: 20,
	},
	labels_wrapper: {
		zIndex: 100,
	},
	opacity_img: {
		position: 'absolute',
		width: '100%',
		top: 0,
		height: '100%',
		objectFit: 'cover',
		borderRadius: 4,
	},
}));
