import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BackgroundImage, ShadeBackground, LogoHeader, Label } from '../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useUiModalsContext } from '../redux/context/ContextFactory';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StoresList from './stores/StoresList';
import { IconButton } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

StoresScreen.propTypes = {
	onSelectStore: PropTypes.func,
};

export default function StoresScreen() {
	const classes = useStyles();

	//
	// use effect
	//

	return (
		<div className={classes.root}>
			<Label fontSize={22} text="Inizia la tua videochiamata scegliendo il negozio:" isBold color="#34303D" />
			<StoresList />
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		// width: '100%',
		// height: '100%',
		// position: 'relative',
		backgroundColor: '#FFFDFB',
		paddingTop: 40,
		// overflow: 'scroll',
	},
	listHeader: {
		width: '100%',
		justifyContent: 'flex-end',
		alignItems: 'center',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#FFFDFB',
	},
	labelWrapper: {
		width: '100%',
		paddingTop: 20,
		backgroundColor: '#FFFDFB',
		height: 100,
	},
	listWrapper: {
		width: '100%',
	},
}));
