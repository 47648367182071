import ApiManager from './ApiManager';
import Endpoints from './Endpoints';

let api = new ApiManager();

const getStores = () => {
	return api.getBpAxios().get(Endpoints.getStores);
};

const getStoreById = (id) => {
	return api.getBpAxios().get(Endpoints.getStoreyId(id));
};

const storesByQuery = (slug) => {
	return api.getBpAxios().get(Endpoints.storesByQuery(slug));
};

const getAgentStore = (slug) => {
	return api.getAxios().get(Endpoints.storeBySlug(slug));
};

export default { getStores, storesByQuery, getStoreById, getAgentStore };
