import SendBirdCall from 'sendbird-calls';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMakeCall from '../redux/hooks/useMakeCall';
import { SBButton, SBChatButton, Label, LabelHeader, CallButton, HeadLabel, SubHeadlabel } from '../components';
import { ContextFactory, CONTEXTS, useAppContext, useUiModalsContext } from '../redux/context/ContextFactory';
import { Box, Fade, Grow } from '@material-ui/core';
import ChatScreen from './ChatScreen';
import opacity from '../assets/opacity.png';
import bg from '../assets/home-bg.png';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PropTypes from 'prop-types';
import SendBird from 'sendbird';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneAnimated from '../components/lottie/PhoneAnimated';
import { RemoveFromQueue } from '@material-ui/icons';
import { useHistory, Prompt } from 'react-router-dom';
import GAManager from '../analytics/GAManager';

let ga = new GAManager();

const APP_ID_TEST_PROJECT = '1A2F3370-1790-4A3D-A152-6ED0E6CA528C';

var sb = new SendBird({ appId: APP_ID_TEST_PROJECT });

DialScreen.propTypes = {
	onEndCall: PropTypes.func,
	onInteruptCall: PropTypes.func,
	onConnectionLost: PropTypes.func,
	onInitCall: PropTypes.func,
};

export default function DialScreen(props) {
	const { ContextProvider } = ContextFactory(CONTEXTS.MAKE_CALL);
	const [openChat, setOpenChat] = useState(false);
	const [callConnected, setCallConnected] = useState(false);
	const callHookObj = useMakeCall({});
	const [directCallObj, setDirectCall] = useState();
	const [counter, setCounter] = useState(0);
	const [innerHeight, setInnerHeight] = useState(window.innerHeight);

	const { openTimerSnack, setOpenTimerSnack } = useUiModalsContext();
	const contextData = {
		...callHookObj,
	};
	const [callObjInit, setCallObjectInit] = useState(false);

	var pingListener;
	const classes = useStyles();

	const [localMuted, setLocalMuted] = useState(false);
	const [localVideo, setLocalVideo] = useState(true);

	const { dialStarted, dialFinished, dialFinishedError, call } = callHookObj;

	const { setUser, userId, currentStore, agentID, chatUrl, removeFromQueue, queueID, saveCallID } = useAppContext();

	useEffect((_) => {
		ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.impressionCTA, GAManager.IDs.endCall, true);
		ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.impressionCTA, GAManager.IDs.chat, true);
		window.addEventListener('resize', function () {
			console.log('inner height', window.innerHeight);
			setInnerHeight(window.innerHeight);
		});
		authenticateToSendBird(userId);
		return () => {};
	}, []);

	useEffect(() => {
		if (!openChat) {
			sb.getTotalUnreadMessageCount(function (count, error) {
				if (error) {
					console.log('error sb unread effect', error);
				} else {
					console.log('sb unread effect', count);
					setCounter(count);
				}
			});
		}
	}, [openChat]);

	useEffect(() => {
		if (directCallObj) {
			saveCallID(directCallObj.callId);
			setCallObjectInit(true);
		}
	}, [directCallObj]);

	const dial = () => {
		console.log('AGENT ID ====> ', agentID);
		console.log('CHAT URL ====> ', chatUrl);
		try {
			dialStarted();
			const call = SendBirdCall.dial(
				{
					userId: agentID,
					isVideoCall: true,
					callOption: {
						localMediaView: document.getElementById('local_video_element_id'),
						remoteMediaView: document.getElementById('remote_video_element_id'),
						audioEnabled: true,
						videoEnabled: true,
					},
					sendBirdChatOptions: {
						channelUrl: chatUrl,
					},
				},
				(call, error) => {
					if (error) {
						console.log('call error ===>', error);
						dialFinishedError(error);
						return;
					}
					dialFinished(call);
					setDirectCall(call);
					props.onInitCall(call);
				}
			);
			addCallListeners(call);
		} catch (e) {
			console.log('catch make a call error', e);
		}
	};

	const authenticateToSendBird = async (userId) => {
		const sendBirdUser = await SendBirdCall.authenticate({ userId });
		await SendBirdCall.connectWebSocket();
		setTimeout(() => dial(), 3000);
		setUser(sendBirdUser);
	};

	const addCallListeners = (call) => {
		call.onInteruptCall = () => {
			console.log('on interupt ======> ');
			props.onEndCall();
		};
		call.onEstablished = (callObj) => {
			document.getElementById('remote_video_element_id').muted = true;
			setOpenTimerSnack(true);
			call.muteMicrophone();
			call.stopVideo();
			setLocalVideo(false);
			setLocalMuted(true);
			setTimeout(() => setCallConnected(true), 3000);
			setTimeout(() => {
				document.getElementById('remote_video_element_id').muted = false;
				call.unmuteMicrophone();
				setLocalMuted(false);
			}, 5000);
			console.log('on established ======> ');
		};

		call.onConnected = () => {
			console.log('on connected ======> ');
		};

		call.onEnded = (endedCall) => {
			console.log('CALL ENDED ==> ');
			console.log('end result', endedCall.endResult);
			switch (endedCall.endResult) {
				case 'completed':
					props.onEndCall();
					break;
				case 'connection_lost':
					props.onConnectionLost();
					break;
				default:
					props.onInteruptCall();
					break;
			}
		};

		call.onReconnected = () => {
			//   this.drawCurrentTime();
		};

		call.onReconnecting = () => {
			console.log('Connecting ==> ');
			//   this.drawReconnectingText();
		};

		call.onRemoteAudioSettingsChanged = (call) => {
			//   this.onRemoteAudioMuted(call.isRemoteAudioEnabled);
		};

		call.onRemoteVideoSettingsChanged = (call) => {
			//   this.onRemoteVideoMuted(call.isRemoteVideoEnabled);
		};
	};

	const onMutePressed = () => {
		if (!localMuted) {
			setLocalMuted(true);
			call.muteMicrophone();
		} else {
			setLocalMuted(false);
			call.unmuteMicrophone();
		}
	};

	const onVideoPressed = () => {
		if (!localVideo) {
			setLocalVideo(true);
			call.startVideo();
		} else {
			setLocalVideo(false);
			call.stopVideo();
		}
	};

	const onMessageReceived = () => {
		sb.getTotalUnreadMessageCount(function (count, error) {
			if (error) {
			} else {
				setCounter(count);
			}
		});
	};

	const renderConnectionView = () => {
		return (
			<div className={classes.callViewWrapper} style={{ display: !callConnected ? 'block' : 'none' }}>
				<LabelHeader isWhite />
				<div className={classes.content_wrapper}>
					<div className={classes.shade_wrapper}>
						<div className={classes.labelContainer}>
							<div className={classes.store_label}>
								<HeadLabel color="#34303D" text="Sto chiamando..." />
							</div>
							<div className={classes.messageWrapper}>
								<SubHeadlabel
									color="#34303D"
									text="Non mettere giù il browser o il telefono altrimenti cadrà la chiamata."
								/>
							</div>
						</div>

						<div className={classes.messageWrapper}>
							<PhoneAnimated />
						</div>
						{callObjInit ? (
							<CallButton isLoading={openTimerSnack} status={'call'} onHang={() => call.end()} />
						) : (
							<div style={{ height: 181 }} />
						)}
					</div>
				</div>
			</div>
		);
	};

	const handleCloseChat = () => {
		setCounter(0);
		setOpenChat(false);
	};

	return (
		<ContextProvider data={contextData}>
			{renderConnectionView()}
			<Fade in={callConnected} timeout={800}>
				<div className={classes.wrapper} style={{ display: callConnected ? 'block' : 'none' }}>
					<video
						className={classes.localVideoBox}
						id="local_video_element_id"
						autoPlay
						muted
						playsInline
						disableRemotePlayback
						webkit-playsinline="true"
					/>
					<div className={classes.videoContainer} style={{ height: innerHeight }}>
						<video
							className={classes.videoBox}
							id="remote_video_element_id"
							autoPlay
							playsInline
							webkit-playsinline="true"
							disableRemotePlayback
						/>
					</div>
					<Box
						className={classes.buttonsWrapper}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<SBChatButton
							counter={counter}
							onPress={() => {
								ga.trackEvent(
									GAManager.CATEGORY.shop_streaming,
									GAManager.ACTION.clickCTA,
									GAManager.IDs.chat
								);
								setOpenChat(true);
							}}
						/>
						<SBButton type="voice" disabled={localMuted} onPress={() => onMutePressed()} />
						<SBButton type="video" disabled={!localVideo} onPress={() => onVideoPressed()} />
						<SBButton
							type="endCall"
							onPress={() => {
								// ga.trackEvent(
								// 	GAManager.CATEGORY.shop_streaming,
								// 	GAManager.ACTION.clickCTA,
								// 	GAManager.IDs.endCall
								// );
								call.end();
							}}
						/>
					</Box>
				</div>
			</Fade>

			<ChatScreen
				store={currentStore}
				open={openChat}
				onClose={() => handleCloseChat()}
				onMessageReceived={() => onMessageReceived()}
			/>

			{/* <Prompt message="Sei sicuro di voler interrompere la videochiamata?" /> */}
		</ContextProvider>
	);
}

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: '100%',
		height: '100%',
		backgroundColor: 'black',
		alignItems: 'center',
		overflowX: 'hidden',
	},
	callViewWrapper: {
		width: '100vw',
		height: '100%',
		position: 'relative',
	},
	messageWrapper: {
		// marginBottom: 50,
		justifyContent: 'center',
	},
	labelContainer: {
		backgroundColor: '#F6F4F0',
		borderRadius: 4,
		paddingBottom: 20,
		paddingTop: 20,
		maxWidth: 400,
		margin: 16,
	},
	messageWrapper: {
		paddingLeft: 20,
		paddingRight: 20,
	},
	content_wrapper: {
		// justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	store_label: {
		zIndex: 101,
	},
	opacity_img: {
		position: 'absolute',
		width: '100%',
		top: 0,
		height: '100%',
		objectFit: 'cover',
		borderRadius: 4,
	},
	shade_wrapper: {
		width: '94%',
		height: '60%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		flexDirection: 'column',
		paddingTop: 20,
		paddingBottom: 20,
		marginTop: 32,
		maxWidth: 400,
		backgroundColor: 'transparent',
	},
	buttonsWrapper: {
		width: '90%',
		marginBottom: '10px',
		position: 'absolute',
		bottom: 0,
		left: 20,
		right: 20,
		maxWidth: 300,
		margin: 'auto',
		textAlign: 'center',
	},
	videoContainer: {
		// position: 'absolute',
		// left: 0,
		// top: 0,
		width: '100vw',
		height: '100%',
		textAlign: 'center',
		backgroundColor: 'black',
		zIndex: 0,
	},
	videoBox: {
		width: '100%',
		height: '100%',
	},
	localVideoBox: {
		width: '100px',
		height: '100px',
		position: 'absolute',
		top: 0,
		left: 10,
		zIndex: 1000,
	},
	labelWrapper: {
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		position: 'relative',
		alignItems: 'center',
		display: 'flex',
	},
}));
