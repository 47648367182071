import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Container, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import opacity from '../../assets/opacity.png';
import Label from '../labels/Label';
import CallEndIcon from '@material-ui/icons/CallEnd';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Loader from '../loader/Loader';
import StoreClosedView from '../dialogs/StoreClosedView';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';

RecallButton.propTypes = {
	onPress: PropTypes.func,
	isLoading: PropTypes.bool,
};

const defaultProps = {
	m: 'auto',
	border: 2,
	style: { width: '5rem', height: '5rem' },
};

export default function RecallButton(props) {
	const classes = useStyles();

	const renderContent = () => {
		return (
			<Button onClick={() => props.onPress()}>
				<Box
					className={classes.root}
					borderRadius="50%"
					{...defaultProps}
					borderColor={'transparent'}
					justifyContent="center"
					alignItems="center"
					display="flex"
				>
					<PhoneCallbackIcon fontSize="large" style={{ fill: '#D6A76F' }} />
				</Box>
			</Button>
		);
	};

	const labelText = () => {
		return 'CHIEDI DI ESSERE RICHIAMATO';
	};

	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justify="center"
			className={classes.callButtonWrapper}
		>
			{props.isLoading ? <Loader /> : renderContent()}
			{!props.isLoading && (
				<div className={classes.label}>
					<Label text={labelText()} fontSize={12} color="#ffffff" isBold letterSpacing={1.92} />
				</div>
			)}
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	callButtonWrapper: {
		display: 'inline-block',
		width: 169,
		height: 181,
		zIndex: 1000,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	root: {
		backgroundColor: '#ffffff',
	},
	label: {
		marginTop: 4,
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: 140,
	},
	hourGlassView: {
		position: 'absolute',
		top: 22,
		right: 22,
	},
}));
