import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Container, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import opacity from '../../assets/opacity.png';
import Label from '../labels/Label';
import CallEndIcon from '@material-ui/icons/CallEnd';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Loader from '../loader/Loader';
import callIcon from '../../assets/call.svg';
import callEndicon from '../../assets/call-end.svg';
import callWhiteIcon from '../../assets/call-white.svg';

import GAManager from '../../analytics/GAManager';
let ga = new GAManager();

CallButton.propTypes = {
	onPress: PropTypes.func,
	status: PropTypes.oneOf(['ready', 'call']),
	onHang: PropTypes.func, // tmp func for fake flow
	isLoading: PropTypes.bool,
	noText: PropTypes.bool,
	white: PropTypes.bool,
	darkText: PropTypes.bool,
};

const defaultProps = {
	m: 'auto',
	border: 2,
	style: { width: '5rem', height: '5rem' },
};

export default function CallButton(props) {
	const classes = useStyles();

	const renderNonActiveStatus = () => {
		return (
			<Button onClick={() => props.onPress()}>
				<Box
					className={classes.root}
					borderRadius="50%"
					{...defaultProps}
					borderColor={'transparent'}
					justifyContent="center"
					alignItems="center"
					display="flex"
				>
					{props.white ? (
						<img src={callWhiteIcon} style={{ width: '5rem', height: '5rem' }} />
					) : (
						<img src={callIcon} style={{ width: '5rem', height: '5rem' }} />
					)}
				</Box>
			</Button>
		);
	};

	const pressEndCall = () => {
		ga.trackEvent(GAManager.CATEGORY.shop_streaming, GAManager.ACTION.clickCTA, GAManager.IDs.endCall);
		props.onHang();
	};

	const renderCallingStatus = () => {
		return (
			<Button onClick={() => pressEndCall()}>
				<Box
					className={classes.root_call}
					borderRadius="50%"
					{...defaultProps}
					justifyContent="center"
					borderColor={'transparent'}
					alignItems="center"
					display="flex"
				>
					<img src={callEndicon} style={{ width: '5rem', height: '5rem' }} />
				</Box>
			</Button>
		);
	};

	const renderBusyStatus = () => {
		return (
			<Box
				className={classes.root_busy}
				borderRadius="50%"
				{...defaultProps}
				borderColor={'white'}
				justifyContent="center"
				alignItems="center"
				display="flex"
			>
				<PhoneIcon fontSize="large" style={{ fill: '#ffffff', marginRight: 8, marginTop: 6 }} />
				<div className={classes.hourGlassView}>
					<HourglassEmptyIcon fontSize="small" style={{ fill: '#ffffff' }} />
				</div>
			</Box>
		);
	};

	const renderContent = () => {
		switch (props.status) {
			case 'ready':
				return renderNonActiveStatus();
			case 'call':
				return renderCallingStatus();
			default:
				return;
		}
	};

	const labelText = () => {
		switch (props.status) {
			case 'ready':
				return 'Chiama ora';
			case 'call':
				return 'Termina chiamata';
			case 'busy':
				return ' ';
			default:
				return ' ';
		}
	};

	const renderButton = () => {};

	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justify="center"
			className={classes.callButtonWrapper}
		>
			{props.isLoading ? <Loader /> : renderContent()}
			{!props.isLoading && (
				<div className={classes.label} style={{ opacity: props.noText ? 0 : 1 }}>
					<Label text={labelText()} fontSize={16} color={props.darkText ? '#34303D' : '#ffffff'} isBold />
				</div>
			)}
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	callButtonWrapper: {
		display: 'inline-block',
		width: 169,
		height: 181,
		zIndex: 1000,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	root: {
		backgroundColor: 'transparent',
	},
	root_call: {
		backgroundColor: 'transprent',
	},
	root_busy: {
		backgroundColor: '#540607',
		position: 'relative',
	},
	label: {
		marginTop: 4,
		justifyContent: 'center',
		alignItems: 'center',
	},
	hourGlassView: {
		position: 'absolute',
		top: 22,
		right: 22,
	},
}));
