import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BackgroundImage, ShadeBackground, LogoHeader, Label } from '../../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useUiModalsContext } from '../../redux/context/ContextFactory';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StoresList from './StoresList';
import { IconButton } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

StoreListScreen.propTypes = {
	onSelectStore: PropTypes.func,
};

export default function StoreListScreen() {
	const classes = useStyles();
	const { setOpenStoreList, openStoreList } = useUiModalsContext();

	//
	// use effect
	//

	const handleClose = () => {
		setOpenStoreList(false);
	};

	return (
		<Modal
			className={classes.modal}
			open={openStoreList}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Slide in={openStoreList} timeout={300} direction="left" mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<div>
						<IconButton className={classes.backButton} onClick={() => handleClose()}>
							<KeyboardBackspaceIcon style={{ fill: '#34303D', marginRight: 4 }} />
							<Label fontSize={18} text="Torna indietro" color="#34303D" />
						</IconButton>
					</div>
					<div className={classes.listHeader}>
						<div className={classes.labelWrapper}>
							<Label
								fontSize={22}
								text="Inizia la tua videochiamata scegliendo il negozio:"
								isBold
								color="#34303D"
							/>
						</div>
					</div>
					<div className={classes.listWrapper}>
						<StoresList />
					</div>
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {},
	wrapper: {
		width: '100%',
		height: '100%',
		position: 'relative',
		backgroundColor: '#FFFDFB',
		outline: 'none',
		overflow: 'scroll',
	},
	listHeader: {
		width: '100%',
		justifyContent: 'flex-end',
		alignItems: 'center',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#FFFDFB',
	},
	labelWrapper: {
		marginTop: 10,
		width: '90%',
	},
	listWrapper: {
		width: '100%',
	},
}));
