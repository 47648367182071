import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Label from '../labels/Label';
import Box from '@material-ui/core/Box';

ProgressTimerLabel.propTypes = {
	value: PropTypes.number.isRequired,
};

function ProgressTimerLabel(props) {
	const classes = useStyles();
	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress style={{ color: '#00D1D2' }} size={80} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Label text={props.value.toString()} fontSize={40} color="#00D1D2" isBold />
			</Box>
		</Box>
	);
}

ProgressTimer.propTypes = {
	onEndCount: PropTypes.func,
};

export default function ProgressTimer(props) {
	const [progress, setProgress] = useState(3);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress == 0 ? 0 : prevProgress - 1));
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		if (progress === 0) {
			props.onEndCount();
		}
	}, [progress]);

	return <ProgressTimerLabel value={progress} />;
}

const useStyles = makeStyles((theme) => ({}));
