import ReactGA from 'react-ga';
ReactGA.initialize('UA-69424936-28', { debug: true });

class GAManager {
	constructor() {
		const instance = this.constructor.instance;
		if (instance) {
			return instance;
		}

		this.constructor.instance = this;
	}

	setPage(location) {
		ReactGA.set({ anonymizeIp: true });
		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
	}
	pageView() {
		ReactGA.set({ anonymizeIp: true });
		ReactGA.pageview(window.location.pathname);
	}

	static CATEGORY = {
		age_verification: 'Age Verification',
		shop_streaming: 'Shop Streaming',
		form: 'Form',
	};

	static ACTION = {
		display: 'Display',
		confirmedAge: 'Confirmed Age',
		notConfirmedAge: 'Not Confirmed Age',
		clickCTA: 'Click on CTA',
		impressionCTA: 'Impression CTA',
		clickStar: `Click on "Star" `,
		submitStarsOnly: 'Click on Submit only stars',
		submitCommentOnly: 'Click on Submit only comment',
		submitStarsComment: 'Click on Submit stars and comment',
	};

	static IDs = {
		ageVerification: 'age_verification',
		ageConfirmed: 'age_confirmed',
		notConfirmedAge: 'age_not_confirmed',
		callNowPress: 'call_now_press',
		endCall: 'end_call',
		chat: 'chat_during_call',
		close_chat: 'close_chat',
		sendMessage: 'send_message',
		addToHome: 'add_to_home',
		endCallOnHold: 'end_call_on_hold',
		recallOnHold: 'request_recall_on_hold',
		changeStorePrecall: 'change_store_precall',
		recallShopClosed: 'request_recall_shop_closed',
		bookShopClosed: 'book_appointment_shop_closed',
		changeStoreShopClosed: 'change_store_shop_closed',
		recallSaNotAvailable: 'request_recall_sa_not_available',
		bookSaNotAvailable: 'book_appointment_sa_not_available',
		bookEndCall: 'booking_appointment_end_call',
		bookOnHold: 'booking_appointment_on_hold',
		personalizeOnHold: 'personalize_now_on_hold',
		messageCall: 'send_message_during_call',
		bookRecall: 'booking_appointment_recall',
		personalizeSaNotAvailable: 'personalize_now_sa_not_available',
	};

	trackEvent(category, action, label, nonInteraction) {
		ReactGA.set({ anonymizeIp: true });
		ReactGA.event({
			category,
			action,
			label,
			nonInteraction,
		});
	}
}

export default GAManager;
