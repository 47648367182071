import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import logo from '../../assets/logo_IQOS.svg';
import { Box, Fade, IconButton, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useAppContext } from '../../redux/context/ContextFactory';
import { Label } from '..';
import { manifestHref } from '../../utils/helpers';
import pinLogo from '../../assets/icon-pin logo.svg';

export default function LabelHeader(props) {
	const classes = useStyles();
	const { currentStore } = useAppContext();

	useEffect(() => {
		document.title = currentStore ? 'IQOS ' + currentStore.name : '';
		if (currentStore) {
			console.log('manifest href', manifestHref(currentStore.slug));
			document.querySelector('#ss-manifest').setAttribute('href', manifestHref(currentStore.slug));
		}
	}, [currentStore]);

	return (
		<div className={classes.root} style={{ paddingTop: props.top && props.top }}>
			{currentStore && (
				<Fade in={true} timeout={300}>
					<Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row">
						<Typography className={classes.label} style={{ color: props.isWhite ? '#ffffff' : '#34303D' }}>
							<img src={pinLogo} className={classes.pin} />

							{currentStore ? currentStore.name : ''}
						</Typography>
					</Box>
				</Fade>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 50,
		paddingTop: 20,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	label: {
		fontSize: 18,
		fontWeight: 'bold',
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		maxWidth: 400,
		fontFamily: 'IqosBold',
	},
	pin: {
		height: 30,
		width: 20,
		marginBottom: 2,
	},
}));
