import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import logo from '../../assets/logo_IQOS.svg';
import { IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import grayLogo from '../../assets/logo_gray.svg';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

LogoHeader.propTypes = {
	withBack: PropTypes.bool,
	onPressBack: PropTypes.func,
	isGray: PropTypes.bool,
};

export default function LogoHeader(props) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			{props.withBack && (
				<IconButton className={classes.backButton} onClick={() => props.onPressBack()}>
					<KeyboardBackspaceIcon style={{ fill: '#34303D' }} />
				</IconButton>
			)}
			<img src={props.isGray ? grayLogo : logo} />
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: 50,
		paddingTop: 20,
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	backButton: {
		position: 'absolute',
		left: 10,
	},
}));
