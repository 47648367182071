import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Label from '../labels/Label';
import Countdown, { zeroPad } from 'react-countdown';
import { useAppContext } from '../../redux/context/ContextFactory';

function padLeadingZeros(num, size) {
	var s = num + '';
	while (s.length < size) s = '0' + s;
	return s;
}

const CountdownTimer = forwardRef((props, ref) => {
	const classes = useStyles();

	const Completionist = () => <span></span>;

	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			return <div />;
		} else {
			return hours > 0 ? (
				<h2 className={classes.timer_over}>Più di un'ora</h2>
			) : (
				<div className={classes.timer_root}>
					<div style={{ alignItems: 'center', paddingRight: 2, paddingLeft: 2 }}>
						<Label
							text={padLeadingZeros(minutes, 2)}
							color={'#34303D'}
							letterSpacing={1.42}
							fontSize={32}
						/>
					</div>
					<div style={{ marginBottom: 6 }}>
						<Label text={':'} color={'#34303D'} letterSpacing={1.42} fontSize={32} />
					</div>
					<div style={{ alignItems: 'center', paddingRight: 2, paddingLeft: 2 }}>
						<Label
							text={padLeadingZeros(seconds, 2)}
							color={'#34303D'}
							letterSpacing={1.42}
							fontSize={32}
						/>
					</div>
				</div>
			);
		}
	};

	//Oltre 1 ora di attesa

	return (
		<div className={classes.timer_root}>
			<div className={classes.timerBox}>
				{props.waitingTime && (
					<Countdown
						ref={ref}
						autoStart
						className={classes.timer_view}
						zeroPadTime={2}
						daysInHours={true}
						renderer={renderer}
						date={Date.now() + props.waitingTime * 1000}
						onComplete={() => props.onComplete()}
						onPause={(time) => props.onPaused(time)}
					>
						<Completionist />
					</Countdown>
				)}
			</div>
			{/* {props.waitingTime && (
				<div className={classes.legend}>
					<Label text={'ORE'} color={'white'} letterSpacing={1.42} fontSize={9} />
					<Label text={'MIN'} color={'white'} letterSpacing={1.42} fontSize={9} />
					<Label text={'SEC'} color={'white'} letterSpacing={1.42} fontSize={9} />
				</div>
			)} */}
		</div>
	);
});

export default CountdownTimer;

const useStyles = makeStyles((theme) => ({
	timer_root: {
		display: 'flex',
		width: 264,
		height: 68,
		alignItems: 'center',
		// flexDirection: 'column',
		zIndex: 1001,
		justifyContent: 'center',
		marginBottom: 6,
	},
	legend: {
		alignItems: 'center',
		flexDirection: 'row',
		width: 120,
		display: 'flex',
		justifyContent: 'space-between',
	},
	timer_view: {
		color: 'white',
		fontFamily: 'Iqos',
		fontSize: 32,
		letterSpacing: 2,
	},
	timer_over: {
		color: 'black',
	},
	timerBox: {
		backgroundColor: 'transparent',
	},
}));

CountdownTimer.propTypes = {
	waitingTime: PropTypes.any,
	onComplete: PropTypes.func,
	onPaused: PropTypes.func,
};
