import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

StoreLabelSmall.propTypes = {
	name: PropTypes.string,
	address: PropTypes.string,
};

export default function StoreLabelSmall(props) {
	const classes = useStyles();

	return (
		<Box>
			<Typography className={classes.name}>{props.name}</Typography>
			<Typography className={classes.address}>{props.address}</Typography>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	name: {
		fontSize: 16,
		color: '#34303D',
		fontWeight: 900,
		fontFamily: 'IqosBold',
		textAlign: 'left',
	},
	address: {
		fontSize: 13,
		color: '#34303D',
		fontFamily: 'Iqos',
		textAlign: 'left',
	},
}));
