import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CallEndIcon from '@material-ui/icons/CallEnd';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import { IconButton, Typography } from '@material-ui/core';

SBButton.propTypes = {
	onPress: PropTypes.func,
	disabled: PropTypes.bool,
};

export default function SBButton(props) {
	const classes = useStyles();

	return (
		<IconButton
			className={classes.rootIcon}
			style={{ backgroundColor: 'white', position: 'relative' }}
			onClick={() => props.onPress()}
		>
			<ChatBubbleOutlineOutlinedIcon style={{ fill: 'gray' }} />
			{props.counter !== 0 && (
				<div className={classes.counter}>
					<Typography className={classes.countLabel}>{props.counter}</Typography>
				</div>
			)}
		</IconButton>
	);
}

const useStyles = makeStyles((theme) => ({
	rootIcon: {
		width: 50,
		height: 50,
	},
	counter: {
		backgroundColor: 'red',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		width: 16,
		height: 16,
		borderRadius: 8,
		position: 'absolute',
		top: 8,
		right: 8,
	},
	countLabel: {
		color: 'white',
		fontSize: 10,
		textAlign: 'center',
		marginLeft: 1,
		marginBottom: 1,
	},
}));
