import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

MainButton.propTypes = {
	label: PropTypes.string,
	onPress: PropTypes.func,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
};

export default function MainButton(props) {
	const classes = useStyles();

	return (
		<Button
			style={{ width: props.fullWidth ? '100%' : null }}
			disabled={props.disabled}
			className={classes.root}
			variant="contained"
			onClick={() => props.onPress()}
		>
			{props.label}
		</Button>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: 12,
		fontWeight: 'bold',
		fontFamily: 'Noto Sans',
		height: 36,
		backgroundColor: '#FFFFFF',
		paddingLeft: 20,
		paddingRight: 20,
	},
}));
