import axios, { AxiosInstance, AxiosStatic } from 'axios';
import ApiManager from './ApiManager';
let api = new ApiManager();

const subscribe = (id) => {
	let body = {
		user_id: id,
		profile_url: '',
		issue_access_token: false,
		issue_session_token: false,
		nickname: 'Anonimo',
	};
	api.getSenbirdAxios()
		.post('/users', body)
		.then((response) => {
			console.log('=== SENDBIRD RESPONSE===', response);
		})
		.catch((error) => {
			console.log('=== SENDBIRD AUTH ERROR===', error);
		});
};

const getSendbirdUser = (id) => {
	return api.getSenbirdAxios().get('/users/' + id);
};

export default { subscribe, getSendbirdUser };
// sendbird api token => 269d3be4451973ce60710bb6acb23b8f3044b1ae
