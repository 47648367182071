import axios, { AxiosInstance, AxiosStatic } from 'axios';
import env from './config';

class ApiManager {
	constructor() {
		const instance = this.constructor.instance;
		if (instance) {
			return instance;
		}

		this.constructor.instance = this;
	}

	getAxios() {
		return axios.create({
			baseURL: env().baseURL,
			timeout: 50000,
		});
	}

	getBpAxios() {
		return axios.create({
			baseURL: env().bpBaseURL,
			timeout: 50000,
		});
	}
	getSenbirdAxios() {
		return axios.create({
			baseURL: env().sbBaseURL,
			timeout: 10000,
			headers: {
				'Content-Type': 'application/json',
				'Api-Token': '269d3be4451973ce60710bb6acb23b8f3044b1ae',
			},
		});
	}
}

export default ApiManager;
