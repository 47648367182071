import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label, LabelHeader, MainButton } from '../components';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Box, Button } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CalendarIcon from '../assets/ic_calendar_ss.svg';
import { useAppContext, useUiModalsContext } from '../redux/context/ContextFactory';
import RecallClientModal from '../screens/RecallClientModal';
import BookingDialog from '../components/dialogs/BookingDialog';
import CallButton from '../components/buttons/CallButton';
import GAManager from '../analytics/GAManager';

let ga = new GAManager();

CallErrorModal.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	onPressCall: PropTypes.func,
};

export default function CallErrorModal(props) {
	const classes = useStyles();
	const { agentStore, removeFromQueue, cancelQueue, registerUser } = useAppContext();
	const { setOpenBPView } = useUiModalsContext();
	const [openBookingDialog, setOpenBookingDialog] = useState(false);
	const [openRecallModal, setOpenRecallModal] = useState(false);

	useEffect(() => {
		if (props.open) {
			// ga.trackEvent(
			// 	GAManager.CATEGORY.shop_streaming,
			// 	GAManager.ACTION.impressionCTA,
			// 	GAManager.IDs.recallSaNotAvailable,
			// 	true
			// );
			// ga.trackEvent(
			// 	GAManager.CATEGORY.shop_streaming,
			// 	GAManager.ACTION.impressionCTA,
			// 	GAManager.IDs.bookSaNotAvailable,
			// 	true
			// );
			removeFromQueue();
		}
	}, []);

	const renderMessage = () => {
		return `Ci scusiamo per l'inconveniente, purtroppo si è verificato un problema di rete.`;
	};

	const handlePressCall = () => {
		props.onPressCall();
	};

	return (
		<Modal
			className={classes.modal}
			open={props.open}
			onClose={() => props.onClose()}
			closeAfterTransition
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Slide in={props.open} timeout={500} direction="up" mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<div style={{ marginTop: 10 }}>
						<LabelHeader />
					</div>

					<div className={classes.content}>
						<Box className={classes.callBox}>
							{/* <div style={{ width: '80%' }}>
								<Label fontSize={16} text="Vuoi collegarti di nuovo?" color="#ffffff" />
							</div> */}
							<div className={classes.titleWrapper}>
								<Label isBold fontSize={18} color={'#34303D'} text={renderMessage()} />
							</div>

							<CallButton status={'ready'} onPress={() => handlePressCall()} white />
						</Box>
						<Box className={classes.bookingBox}>
							<div style={{ width: '80%' }}>
								<Label
									fontSize={16}
									text="Puoi inserire il tuo numero di telefono e ti richiameremo noi, oppure puoi prenotare un appuntamento."
									color="#34303D"
								/>
							</div>

							<Button
								fullWidth
								disabled={props.disabled}
								className={classes.button}
								variant="outlined"
								onClick={() => {
									ga.trackEvent(
										GAManager.CATEGORY.shop_streaming,
										GAManager.ACTION.clickCTA,
										GAManager.IDs.recallSaNotAvailable
									);
									setOpenRecallModal(true);
								}}
							>
								Vorrei essere richiamato
							</Button>
							<Button
								fullWidth
								disabled={props.disabled}
								className={classes.bookingButton}
								variant="outlined"
								onClick={() => {
									ga.trackEvent(
										GAManager.CATEGORY.shop_streaming,
										GAManager.ACTION.clickCTA,
										GAManager.IDs.bookSaNotAvailable
									);
									setOpenBookingDialog(true);
								}}
							>
								Prenota un appuntamento
							</Button>
							<div style={{ marginTop: 12, paddingRight: 8, paddingLeft: 8 }}>
								<Label
									text={'Il servizio di spedizione è attivo dal lunedì al venerdì'}
									fontSize={14}
									color="#34303D"
									isBold
									isItalic
								/>
							</div>
						</Box>
					</div>
					<RecallClientModal open={openRecallModal} onClose={() => setOpenRecallModal(false)} />
					<BookingDialog
						open={openBookingDialog}
						onClose={() => setOpenBookingDialog(false)}
						onOpenBP={(link) => {
							//close dialog and open iframe
							setOpenBookingDialog(false);
							setOpenBPView(true, link);
						}}
					/>
				</div>
			</Slide>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {
		width: '100%',
		height: '100%',
		outline: 'none',
	},
	wrapper: {
		backgroundColor: '#FFFDFB',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		outline: 'none',
		overflow: 'auto',
	},
	button: {
		fontFamily: 'Iqos',
		height: 48,
		letterSpacing: 0.5,
		width: 260,
		borderRadius: 24,
		borderColor: '#34303D',
		borderWidth: 2,
		marginTop: 20,
	},
	titleWrapper: {
		// marginTop: 40,
		maxWidth: 400,
		width: '80%',
	},
	buttonWrapper: {
		marginTop: 16,
		width: '100%',
	},
	content: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 16,
		maxWidth: 400,
		paddingBottom: 20,
	},
	callBox: {
		backgroundColor: '#00D1D2',
		// height: 200,
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 16,
	},
	bookingBox: {
		marginTop: 16,
		// height: 200,
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 4,
		paddingTop: 16,
		paddingBottom: 22,
	},
	bookingButton: {
		backgroundColor: '#34303D',
		color: '#ffffff',
		fontFamily: 'Iqos',
		height: 42,
		letterSpacing: 0.5,
		width: 260,
		borderRadius: 24,
		marginTop: 16,
		'&:hover': {
			backgroundColor: '#34303D',
		},
	},
}));
