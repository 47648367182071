import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import pinLogo from '../../assets/icon-pin logo.svg';

StoreLabel.propTypes = {
	name: PropTypes.string,
	address: PropTypes.string,
	icon: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default function StoreLabel(props) {
	const classes = useStyles();

	return (
		<Box
			style={{ opacity: props.disabled ? 0.4 : 1, paddingLeft: 20 }}
			display="flex"
			flexDirection="row"
			alignItems="center"
		>
			{props.icon && <img src={pinLogo} />}
			<Box display="flex" flexDirection="column" alignItems="flex-start">
				<Typography className={classes.name}>{props.name}</Typography>
				<Typography className={classes.address}>{props.address}</Typography>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	name: {
		fontSize: 22,
		color: '#34303D',
		fontWeight: 900,
		fontFamily: 'IqosBold',
		textAlign: 'left',
	},
	address: {
		fontSize: 14,
		color: '#34303D',
		fontFamily: 'Iqos',
		textAlign: 'left',
	},
}));
